type DateArr = [string, string, string];

export const calculateAge = (dateString: string) => {
  const [day, month, year] = dateString.split('.') as DateArr;
  const birthDate = new Date(`${year}-${month}-${day}`);
  const today = new Date();

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
};
