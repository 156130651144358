import { ValidatorFn } from '@angular/forms';
import { PhoneNumberUtil } from 'google-libphonenumber';

import { MIN_LOCATION_LENGTH } from '../constants';

export const checkPasswordMatch: ValidatorFn = (control) => {
  const password = control.get('password');
  const passwordConfirmation = control.get('password_confirmation');

  if (password?.value === '') {
    return { passNotMatch: true };
  }

  return password?.value === passwordConfirmation?.value
    ? null
    : { passNotMatch: true };
};

export const checkLocationLength: ValidatorFn = (control) => {
  const location = control.value as string;

  if (location.length && location.length < MIN_LOCATION_LENGTH) {
    return { invalidLength: true };
  }

  return null;
};

export const checkPhoneNumber: ValidatorFn = (control) => {
  const phoneNumberUtil = PhoneNumberUtil.getInstance();
  const error = { invalidPhoneNumber: true };

  if (!control.value || !control.value.phoneNumber) {
    return error;
  }

  try {
    const number = phoneNumberUtil.parse(
      control.value.phoneNumber,
      control.value.countryIsoCode,
    );

    if (!number) {
      return error;
    }

    const isValid = phoneNumberUtil.isValidNumberForRegion(
      number,
      control.value.countryIsoCode,
    );

    if (!isValid) {
      return error;
    }
  } catch (e) {
    return error;
  }

  return null;
};
