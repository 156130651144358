import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';

import { AuthService } from '../services';
import { SYSTEM_ROUTES } from '../../data/constants';

export const selectLoginProjectGuard: CanActivateFn = () => {
  const router = inject(Router);
  const authService = inject(AuthService);

  const hasUserCredentials = !!authService.getUserCredentials();

  if (!hasUserCredentials) {
    router.navigate([SYSTEM_ROUTES.login]);

    return false;
  }

  return true;
};
