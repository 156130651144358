import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { first, map } from 'rxjs';

import { MaintenanceService } from '../services';
import { SYSTEM_ROUTES } from '../../data/constants';

export const checkMaintenanceStatusGuard: CanActivateFn = () => {
  const router = inject(Router);
  const maintenanceService = inject(MaintenanceService);

  const maintenanceStatus = maintenanceService.getMaintenanceStatus();

  if (maintenanceStatus !== null) {
    return true;
  }

  return maintenanceService.checkMaintenanceStatus().pipe(
    first(),
    map(({ data }) => {
      if (data) {
        router.navigate([SYSTEM_ROUTES.maintenance]);
      }

      return true;
    }),
  );
};
