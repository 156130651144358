import { environment } from '../../../environments/environment';

export const iconLink = {
  spinner: `${environment.CLOUDFRONT}/loading_anim.svg`,
  checkGreen: `${environment.CLOUDFRONT}/check-green.svg`,
  checkLightGreen: `${environment.CLOUDFRONT}/check-light-green.svg`,
  msdClassicLogo: `${environment.CLOUDFRONT}/MSD_classic.svg`,
  msdGayLogo: `${environment.CLOUDFRONT}/MSD_gay.svg`,
  msdMommyLogo: `${environment.CLOUDFRONT}/MSD_mommy.svg`,
  msdGayMommyLogo: `${environment.CLOUDFRONT}/MSD_gaymommy.svg`,
  shortLogo: `${environment.CLOUDFRONT}/Logo-short.svg`,
  plus: `${environment.CLOUDFRONT}/plus.svg`,
  badRequest: `${environment.CLOUDFRONT}/Icon material-sentiment-dissatisfied.svg`,
  arrowBack: `${environment.CLOUDFRONT}/Icon ionic-ios-arrow-back.svg`,
  arrowDown: `${environment.CLOUDFRONT}/Icon ionic-ios-arrow-down.svg`,
  facebook: `${environment.CLOUDFRONT}/Icon awesome-facebook.svg`,
  email: `${environment.CLOUDFRONT}/Icon simple-email.svg`,
  warning: `${environment.CLOUDFRONT}/Icon ionic-ios-warning.svg`,
  sms: `${environment.CLOUDFRONT}/sms-check.svg`,
  shield: `${environment.CLOUDFRONT}/sms-check-round.svg`,
  checkWhite: `${environment.CLOUDFRONT}/check-white.svg`,
  uncheckWhite: `${environment.CLOUDFRONT}/uncheck-white.svg`,
  loginMsdClassic: `${environment.CLOUDFRONT}/login-msd-classic.svg`,
  loginMsdGay: `${environment.CLOUDFRONT}/login-msd-gay.svg`,
  loginMsdMamas: `${environment.CLOUDFRONT}/login-msd-mamas.svg`,
  passwordEye: `${environment.CLOUDFRONT}/password-eye.svg`,
  selectionListArrow: `${environment.CLOUDFRONT}/selection-list-arrow.svg`,
} as const;
