import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, finalize, tap } from 'rxjs';

import { AuthService, DataStorageService } from '../../../core/services';
import { environment } from '../../../../environments/environment';
import { RegisterDataService } from './register-data.service';
import { BaseResponse } from '../../../data/interfaces';

interface AddPictureResponse {
  error: false;
  img: string;
  thumb: string;
}

interface DefaultImages {
  M_0: string;
  F_0: string;
  M_1: string;
  F_1: string;
}

@Injectable({
  providedIn: 'root',
})
export class RegisterProfileImageService {
  private readonly userImageBaseUrl =
    'https://d1fqc5d4qeif6z.cloudfront.net/sdcontent/uploads/userphoto/thumb';

  // Key - Gender_MsdProjectId
  private readonly defaultImageUrls = {
    M_0: `${environment.CLOUDFRONT}/profile_daddy.svg`,
    F_0: `${environment.CLOUDFRONT}/profile_babe.svg`,
    M_1: `${environment.CLOUDFRONT}/default-icon-male.svg`,
    F_1: `${environment.CLOUDFRONT}/default-icon-female.svg`,
  };

  private originalImageBase64 = '';

  private imageHash = '';

  private imageError$ = new BehaviorSubject(false);

  private croppedImage$ = new BehaviorSubject<string>('');

  private isLoading$ = new BehaviorSubject(false);

  imageError = this.imageError$.asObservable();

  croppedImage = this.croppedImage$.asObservable();

  isLoading = this.isLoading$.asObservable();

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private dataStorageService: DataStorageService,
    private registerDataService: RegisterDataService,
  ) {}

  setLoading(value: boolean) {
    this.isLoading$.next(value);
  }

  setError(value: boolean) {
    this.imageError$.next(value);
  }

  addPicture() {
    const authData = this.authService.getAuthInfoForRequest();
    const requestPayload = {
      image: this.croppedImage$.value,
      originalHash: this.imageHash,
      ...authData,
    };

    return this.http
      .post<AddPictureResponse | BaseResponse<string>>(
        `${environment.REG_API_URL}/profileAddPictureCanvas`,
        requestPayload,
      )
      .pipe(
        tap((response) => {
          if (response.error || !('img' in response)) {
            return;
          }

          const arr = response.img.split('/');
          const fileName = arr[arr.length - 1];

          if (fileName) {
            this.registerDataService.updateUserProfileProp(
              'avatarPicture',
              fileName,
            );
          }
        }),
        finalize(() => {
          this.isLoading$.next(false);
        }),
      );
  }

  saveOriginalImageBase64(data: string) {
    this.imageError$.next(false);
    this.croppedImage$.next('');
    this.imageHash = '';

    this.originalImageBase64 = data;
  }

  saveCroppedImage(data: string | null | undefined) {
    if (!data || data.length <= 5) {
      this.imageError$.next(true);

      return;
    }

    this.croppedImage$.next(data);
  }

  getSavedOriginalImageBase64() {
    return this.originalImageBase64;
  }

  getCroppedImage() {
    return this.croppedImage$.value;
  }

  getUserProfileImageUrl() {
    const { avatarPicture } =
      this.registerDataService.getSavedUserProfileData();

    if (!avatarPicture) {
      return '';
    }

    return `${this.userImageBaseUrl}/${avatarPicture}`;
  }

  hasSavedData() {
    const hasCroppedImage = this.croppedImage$.value;
    const hasImageHash = this.imageHash;

    return hasCroppedImage || hasImageHash;
  }

  clearData() {
    this.originalImageBase64 = '';
    this.imageHash = '';
    this.imageError$.next(false);
    this.croppedImage$.next('');
  }

  getBase64(event: Event): Promise<string> {
    const { files } = event.target as HTMLInputElement;

    return new Promise((resolve, reject) => {
      if (!files || !files.length) {
        return reject();
      }

      const file = files[0];
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onerror = reject;
      reader.onload = async () => {
        const { result } = reader;

        if (!result) {
          return reject();
        }

        return resolve(result as string);
      };
    });
  }

  getDefaultImageUrl() {
    const { msdProjectId } = this.dataStorageService.getDataStorage();
    const { usergender } = this.registerDataService.getSavedUserProfileData();
    const key = `${usergender}_${msdProjectId}`;
    const imageUrl = this.defaultImageUrls[key as keyof DefaultImages];

    if (imageUrl) {
      return imageUrl;
    }

    if (msdProjectId === 1) {
      return this.defaultImageUrls.M_1;
    }

    return this.defaultImageUrls.M_0;
  }

  async saveOriginalImageHash(data: string) {
    const msgUint8 = new TextEncoder().encode(data); // encode as (utf-8) Uint8Array
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array

    this.imageHash = hashArray
      .map((b) => b.toString(16).padStart(2, '0'))
      .join(''); // convert bytes to hex string
  }
}
