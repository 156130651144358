import { MSD_KEYS, MSD_GAY_KEYS } from '../lang';
import {
  ClassicMsdProject,
  GenderEnum,
  MsdProject,
  PartnerRoles,
} from '../interfaces';
import { environment } from '../../../environments/environment';
import { iconLink } from '../constants';

const msdMommyPageLink = environment.production
  ? 'https://www.mysugarmommy.com'
  : 'https://wwwdev.mysugarmommy.com';
const msdGayMommyPageLink = environment.production
  ? 'https://www.mygaysugarmommy.com'
  : 'https://wwwdev.mygaysugarmommy.com';

export const MSD_PROJECT: MsdProject = {
  classic: {
    id: 0,
    domains: ['register.mysugardaddy.com', 'devregister.mysugardaddy.com'],
    label: 'MSD classic',
    logo: iconLink.msdClassicLogo,
    landingPageUrl: 'https://www.mysugardaddy.eu',
    translationKeys: MSD_KEYS,
    params: {
      hasFBAuth: true,
    },
  },
  gay: {
    id: 1,
    domains: [
      'register.mygaysugardaddy.com',
      'devregister.mygaysugardaddy.com',
    ],
    label: 'MSD gay',
    logo: iconLink.msdGayLogo,
    landingPageUrl: 'https://www.mygaysugardaddy.com',
    translationKeys: MSD_GAY_KEYS,
    params: {
      hasFBAuth: false,
    },
  },
  mom: {
    id: 2,
    domains: [],
    label: 'MSD mommy',
    logo: iconLink.msdMommyLogo,
    landingPageUrl: '',
    translationKeys: MSD_GAY_KEYS, // TODO Need translations
    params: {
      hasFBAuth: false,
    },
    tempPageLink: msdMommyPageLink,
  },
  gayMom: {
    id: 3,
    domains: [],
    label: 'MSD gay mommy',
    logo: iconLink.msdGayMommyLogo,
    landingPageUrl: '',
    translationKeys: MSD_GAY_KEYS, // TODO Need translations
    params: {
      hasFBAuth: false,
    },
    tempPageLink: msdGayMommyPageLink,
  },
};

export const DEFAULT_MSD_PROJECT: ClassicMsdProject = MSD_PROJECT.classic;

export const genderAndRoleToProjectId: Record<string, number> = {
  // Male
  [`${GenderEnum.male}_${PartnerRoles.sugarBabe}`]: MSD_PROJECT.classic.id,
  [`${GenderEnum.male}_${PartnerRoles.sugarMom}`]: MSD_PROJECT.mom.id,
  [`${GenderEnum.male}_${PartnerRoles.sugarDaddy}`]: MSD_PROJECT.gay.id,
  [`${GenderEnum.male}_${PartnerRoles.sugarBoy}`]: MSD_PROJECT.gay.id,

  // Female
  [`${GenderEnum.female}_${PartnerRoles.sugarBabe}`]: MSD_PROJECT.gayMom.id,
  [`${GenderEnum.female}_${PartnerRoles.sugarMom}`]: MSD_PROJECT.gayMom.id,
  [`${GenderEnum.female}_${PartnerRoles.sugarDaddy}`]: MSD_PROJECT.classic.id,
  [`${GenderEnum.female}_${PartnerRoles.sugarBoy}`]: MSD_PROJECT.mom.id,
} as const;
