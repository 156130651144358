import { Subscription as SubRxjs } from 'rxjs';

import { RegisterStep } from './register';

type BaseResponseError = 0 | 1;

export enum ApiModules {
  session = 'session',
  addPseudonym = 'addPseudonym',
  getUserLatLong = 'getUserLatLong',
  getUserProfile = 'getUserProfile',
  isMaintenance = 'isMaintenance',
  isRegCheck = 'isRegCheck',
  userLogin = 'userLogin',
  userLoginRemember = 'userLoginRemember',
  forgotPassword = 'forgotPassword',
  forgotPasswordVerifyToken = 'forgotPasswordVerifyToken',
  registrationCreateReg = 'registrationCreateReg',
  registrationSaveStep = 'registrationSaveStep',
  addFP = 'addFP',
}

export interface BaseResponse<T> {
  error: boolean | BaseResponseError;
  errortxt: string;
  module: ApiModules | string;
  data: T;
}

export type ApiResponse<T> = BaseResponse<T | string> | void;

export type Subscriptions = SubRxjs[];

export interface LoginPossibleProjects {
  possibleProjects: number[];
}

export type LoginError = '';
export type LoginResponse =
  | LoginRegisterNotFinished
  | LoginRegisterFinished
  | LoginPossibleProjects
  | LoginError;

export interface LoginCredentials {
  email: string;
  password: string;
  remember?: boolean;
}

export interface UserCredentials extends LoginCredentials {
  remember: boolean;
  possibleProjects: number[];
}

export interface LoginRegisterNotFinished {
  register: boolean;
  regstep: RegisterStep;
  regsubstep: string;
  auth: string;
}

export interface LoginRegisterFinished {
  login: boolean;
  link: string;
  rememberauth?: string;
}
