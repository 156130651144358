import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { RegisterDataService } from '../services';
import { AuthService, DataStorageService } from '../../../core/services';
import {
  ApiModules,
  BaseResponse,
  Langs,
  ParsedCheckUsernameResponse,
} from '../../../data/interfaces';

interface CheckUsername {
  error: boolean;
  valid?: boolean;
  message?: string;
  suggestions?: string[];
}

type SaveUsername = BaseResponse<[] | string>;

@Injectable({
  providedIn: 'root',
})
export class RegisterUsernameService {
  private cache = new Map<string, ParsedCheckUsernameResponse>();

  constructor(
    private http: HttpClient,
    private registerDataService: RegisterDataService,
    private dataStorageService: DataStorageService,
    private authService: AuthService,
  ) {}

  check(username: string, lang: Langs) {
    const { msdProjectId } = this.dataStorageService.getDataStorage();
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { usercity, birth_date } =
      this.registerDataService.getSavedUserProfileData();

    const body = {
      pseudonym: username,
      city: usercity.substring(0, 2).toUpperCase(),
      birthdate: birth_date,
      lang,
      project: msdProjectId,
    };

    return this.http
      .post<CheckUsername>(
        `${environment.HOME_API_URL}/checkusernamesuggestions`,
        body,
      )
      .pipe(
        map(({ valid, message, suggestions }) => {
          const result: ParsedCheckUsernameResponse = {
            isValid: valid || false,
            errorMessage: message || '',
            suggestions: suggestions || [],
          };

          this.cache.set(`${username}_${lang}`, result);

          return result;
        }),
      );
  }

  save(username: string) {
    const authData = this.authService.getAuthInfoForRequest();
    const requestPayload = {
      pseudonym: username,
      ...authData,
    };

    return this.http
      .post<SaveUsername>(
        `${environment.REG_API_URL}/addPseudonym`,
        requestPayload,
      )
      .pipe(
        tap(({ error, module }) => {
          if (!error && module === ApiModules.addPseudonym) {
            this.registerDataService.updateUserProfileProp(
              'userpseudonym',
              username,
            );
          }
        }),
      );
  }

  setCache(key: string, value: ParsedCheckUsernameResponse) {
    this.cache.set(key, value);
  }

  getCachedValue(key: string) {
    return this.cache.get(key);
  }

  hasUsername(username: string) {
    const { userpseudonym } =
      this.registerDataService.getSavedUserProfileData();

    if (!userpseudonym) {
      return false;
    }

    return username.toLowerCase() === userpseudonym.toLowerCase();
  }

  clearCache() {
    this.cache.clear();
  }
}
