import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, finalize, tap } from 'rxjs';
import { Router } from '@angular/router';

import { msdProjectHelper, unsubscribe } from '../../data/helpers';
import { AuthService, DataStorageService, LoaderService } from '../services';
import {
  BaseResponse,
  RegisterDetailsData,
  RegisterStep,
  Subscriptions,
} from '../../data/interfaces';
import { environment } from '../../../environments/environment';
import { SYSTEM_ROUTES } from '../../data/constants';

interface FbLoginResponse {
  login: boolean;
  blocked: boolean;
  link?: string;
  regauth?: string;
  email?: string;
}

interface FbData {
  token: string;
  isBlocked: boolean;
  email: string;
}

interface Register {
  link: string;
}

interface FbInitData {
  appId: string;
  autoLogAppEvents: boolean;
  xfbml: boolean;
  version: string;
}

interface FbLoginParams {
  scope: string;
  return_scopes: boolean;
}

declare let FB: {
  init: (data: FbInitData) => void;
  login: (cb: (data: Response) => void, params: FbLoginParams) => void;
};

type FbStatus = 'connected' | 'not_authorized' | 'unknown';

interface Response {
  authResponse: {
    accessToken: string;
    expiresIn: string;
    reauthorize_required_in: string;
    signedRequest: string;
    userID: string;
  };
  status: FbStatus;
}

@Injectable({
  providedIn: 'root',
})
export class FbAuthService implements OnDestroy {
  private readonly APP_KEY = '174997585265666';

  private subscriptions$: Subscriptions = [];

  private userBlockStatus$ = new BehaviorSubject(false);

  private fbAccToken = '';

  private userEmail = '';

  userBlockStatus = this.userBlockStatus$.asObservable();

  constructor(
    private http: HttpClient,
    private dataStorage: DataStorageService,
    private loaderService: LoaderService,
    private authService: AuthService,
    private router: Router,
  ) {}

  ngOnDestroy() {
    unsubscribe(this.subscriptions$);
  }

  authFacebook(): Promise<FbLoginResponse | null> {
    this.loaderService.showLoader();

    localStorage.removeItem('');

    return new Promise((resolve) => {
      FB.init({
        appId: this.APP_KEY,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v17.0',
      });

      FB.login(
        ({ authResponse, status }: Response) => {
          if (
            status !== 'connected' ||
            !authResponse ||
            authResponse.accessToken === undefined
          ) {
            this.loaderService.hideLoader();

            return resolve(null);
          }

          this.fbAccToken = authResponse.accessToken;

          const { msdProjectId, loginsite } = this.dataStorage.getDataStorage();
          const credentials = {
            accessToken: authResponse.accessToken,
            project: msdProjectId,
            loginsite,
          };

          const sub$ = this.http
            .post<BaseResponse<FbLoginResponse>>(
              `${environment.HOME_API_URL}/userLoginFbReg`,
              credentials,
            )
            .pipe(
              finalize(() => {
                this.loaderService.hideLoader();
              }),
            )
            .subscribe({
              next: ({ data, error }) => {
                if (!error) {
                  this.handleResponse(data);

                  return resolve(data);
                }

                resolve(null);
              },
              error: () => {
                resolve(null);
              },
            });

          this.subscriptions$.push(sub$);
        },
        { scope: 'email,public_profile', return_scopes: true },
      );
    });
  }

  register(detailsData: RegisterDetailsData, username: string) {
    const requestPayload: RegisterDetailsData = {
      ...detailsData,
      accessToken: this.fbAccToken,
      pseudonym: username,
    };

    requestPayload.domain = 'www.mysugardaddy.eu';
    delete requestPayload.password;

    return this.http
      .post<BaseResponse<Register>>(
        `${environment.HOME_API_URL}/registrationCreateRegFB`,
        requestPayload,
      )
      .pipe(
        tap(({ error, data }) => {
          if (!error) {
            this.authService.redirectToCommunity(data.link);
          }
        }),
      );
  }

  isSupportedFBAuth() {
    const projectId = this.dataStorage.getDataStorage().msdProjectId;

    if (projectId === null) {
      return false;
    }

    const {
      params: { hasFBAuth },
    } = msdProjectHelper.getById(projectId);

    return hasFBAuth;
  }

  resetBlockedStatus() {
    this.userBlockStatus$.next(false);
  }

  clearData() {
    this.fbAccToken = '';
    this.resetBlockedStatus();
  }

  getData(): FbData {
    return {
      token: this.fbAccToken,
      isBlocked: this.userBlockStatus$.value,
      email: this.userEmail,
    };
  }

  private handleResponse({
    link,
    blocked,
    regauth,
    login,
    email,
  }: FbLoginResponse) {
    if (link) {
      this.authService.handleAuthResponse({
        login,
        link,
      });

      return;
    }

    if (regauth) {
      this.authService.handleAuthResponse({
        register: true,
        auth: regauth,
        regstep: RegisterStep.username,
        regsubstep: '1',
      });

      return;
    }

    if (!login && blocked) {
      this.userBlockStatus$.next(blocked);

      return;
    }

    if (!login && !blocked && email) {
      if (this.router.url !== `/${SYSTEM_ROUTES.registration}`) {
        this.router.navigate([SYSTEM_ROUTES.index]);
      }

      this.userEmail = email;

      return;
    }
  }
}
