import { Injectable, OnDestroy } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { finalize } from 'rxjs';

import {
  COMPLETE_REGISTER_STEPS,
  COMPLETE_REGISTER_STEPS_ONLY_MALE,
  DEFAULT_STEP_ORDER,
  MAIL_SUB_STEPS,
  SMS_SUB_STEPS,
  SYSTEM_ROUTES,
} from '../../../data/constants';
import {
  ApiResponse,
  ProgressBarData,
  RegisterStep,
  Subscriptions,
  UserProfileGender,
} from '../../../data/interfaces';
import { AuthService, LoaderService } from '../../../core/services';
import { environment } from '../../../../environments/environment';
import { unsubscribe } from '../../../data/helpers';
import { RegisterDataService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class RegisterNavigationService implements OnDestroy {
  private readonly firstStep = 1;

  private stepOrder: RegisterStep[] = [];

  private currentStep = this.firstStep;

  private subscriptions$: Subscriptions = [];

  readonly regSubStep = 1; // TODO It has to be fixed sometime

  constructor(
    private router: Router,
    private http: HttpClient,
    private authService: AuthService,
    private loaderService: LoaderService,
    private registerDataService: RegisterDataService,
  ) {
    this.listenLoginRegisterStep();
  }

  ngOnDestroy() {
    unsubscribe(this.subscriptions$);
  }

  goToStep(step: RegisterStep, isEnableApiStepUpdate = true, queryParams = {}) {
    step = step || RegisterStep.username;

    this.updateStepOrder(step);

    const stepIdx = this.stepOrder.indexOf(step);

    if (stepIdx < 0) {
      this.resetSteps();
      this.navigateToStepPage();

      return;
    }

    this.currentStep = stepIdx + 1;
    this.navigateToStepPage(step, isEnableApiStepUpdate, queryParams);
  }

  initStepOrder() {
    this.stepOrder = [...DEFAULT_STEP_ORDER];
  }

  goToFirstStep() {
    this.resetSteps();
    this.navigateToStepPage();
  }

  goToNextStep() {
    const newStep = this.currentStep + 1;

    if (newStep > this.stepOrder.length) {
      this.resetSteps();
    } else {
      this.currentStep = newStep;
    }

    this.navigateToStepPage();
  }

  goToPreviousStep() {
    const newStep = this.currentStep - 1;

    if (newStep < this.firstStep) {
      return;
    }

    this.currentStep -= 1;
    this.navigateToStepPage();
  }

  getCurrentStep() {
    return this.stepOrder[this.currentStep - 1];
  }

  updateApiRegisterStep(step: RegisterStep) {
    this.loaderService.showLoader();

    const authData = this.authService.getAuthInfoForRequest();
    const requestPayload = {
      regstep: step,
      regsubstep: this.regSubStep,
      ...authData,
    };

    return this.http.post<ApiResponse<[]>>(
      `${environment.REG_API_URL}/registrationSaveStep`,
      requestPayload,
    );
  }

  updateStepOrder(step: RegisterStep) {
    if (SMS_SUB_STEPS.includes(step)) {
      this.stepOrder = [...DEFAULT_STEP_ORDER, ...SMS_SUB_STEPS];

      return;
    }

    if (MAIL_SUB_STEPS.includes(step)) {
      this.stepOrder = [...DEFAULT_STEP_ORDER, ...MAIL_SUB_STEPS];

      return;
    }

    if (COMPLETE_REGISTER_STEPS.includes(step)) {
      const { usergender } = this.registerDataService.getSavedUserProfileData();

      if (usergender === UserProfileGender.female) {
        const filteredSteps = this.removeMaleSteps();

        this.stepOrder = [...DEFAULT_STEP_ORDER, ...filteredSteps];
      } else {
        this.stepOrder = [...DEFAULT_STEP_ORDER, ...COMPLETE_REGISTER_STEPS];
      }
    }
  }

  resetSteps() {
    this.currentStep = this.firstStep;
    this.initStepOrder();
  }

  getProgressBarData(): ProgressBarData {
    const { usergender } = this.registerDataService.getSavedUserProfileData();
    const registerStep = this.getCurrentStep();
    const result = {
      currentFillLevel: 0,
      totalLevels: 0,
    };

    if (usergender === UserProfileGender.female) {
      const filteredSteps = this.removeMaleSteps();

      result.currentFillLevel = filteredSteps.indexOf(registerStep);
      result.totalLevels = filteredSteps.length;
    } else {
      result.currentFillLevel = COMPLETE_REGISTER_STEPS.indexOf(registerStep);
      result.totalLevels = COMPLETE_REGISTER_STEPS.length;
    }

    result.currentFillLevel += 1;

    return result;
  }

  private removeMaleSteps() {
    return COMPLETE_REGISTER_STEPS.filter(
      (s) => !COMPLETE_REGISTER_STEPS_ONLY_MALE.includes(s),
    );
  }

  private listenLoginRegisterStep() {
    const sub$ = this.authService.loginToRegisterStep
      .pipe(
        finalize(() => {
          this.loaderService.hideLoader();
        }),
      )
      .subscribe((registerStep) => {
        if (registerStep === null) {
          return;
        }

        this.goToStep(registerStep);
      });

    this.subscriptions$.push(sub$);
  }

  private navigateToStepPage(
    newStep?: RegisterStep,
    isEnableApiStepUpdate?: boolean,
    queryParams?: Record<string, string>,
  ) {
    const params: NavigationExtras = {
      queryParams: null,
      queryParamsHandling: null,
    };

    if (queryParams) {
      params.queryParams = { ...queryParams };
      params.queryParamsHandling = 'merge';
    }

    if (newStep && !isEnableApiStepUpdate) {
      this.registerDataService.updateUserProfileProp('regstep', newStep);
      this.router.navigate(
        [`${SYSTEM_ROUTES.registration}/${newStep}`],
        params,
      );

      this.loaderService.hideLoader();

      return;
    }

    const step = newStep || this.getCurrentStep();
    const sub$ = this.updateApiRegisterStep(step).subscribe({
      next: (response) => {
        if (!response) {
          return;
        }

        if (Array.isArray(response.data)) {
          this.registerDataService.updateUserProfileProp('regstep', step);
          this.router.navigate([`${SYSTEM_ROUTES.registration}/${step}`], {
            queryParamsHandling: 'preserve',
          });
        }
      },
    });

    this.subscriptions$.push(sub$);
  }
}
