import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

import {
  Langs,
  LangTitles,
  LangListItem,
  TranslationKeys,
} from '../../data/interfaces';
import { DataStorageService } from './data-storage.service';
import { DEFAULT_LANG, LANG_PROPERTY_NAME } from '../../data/constants';
import { DEFAULT_MSD_PROJECT } from '../../data/config';
import { hasObjectProperty, msdProjectHelper } from '../../data/helpers';

@Injectable({
  providedIn: 'root',
})
export class LangService {
  private keys: TranslationKeys = DEFAULT_MSD_PROJECT.translationKeys;

  private hasLangParam = false;

  private lang$ = new BehaviorSubject(DEFAULT_LANG);

  lang = this.lang$.asObservable();

  constructor(
    private dataStorageService: DataStorageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  setDefaultLangData(langParam?: string) {
    langParam = langParam || '';

    const lang = this.getCorrectLang(langParam);

    this.setDefaultTranslationKeys();
    this.setLang(lang);
  }

  setLang(lang: Langs) {
    this.lang$.next(lang);
    this.document.documentElement.lang = lang;
    localStorage.setItem(LANG_PROPERTY_NAME, lang);
  }

  getCurrentLang() {
    return this.lang$.value;
  }

  getCurrentTranslationKeys() {
    return this.keys;
  }

  getSupportedLangsWithTitles() {
    const supportedLanguages = Object.keys(this.keys);
    const langTitles = Object.entries(LangTitles);

    return langTitles.reduce((acc, lang) => {
      const code = lang[0] as Langs;

      if (supportedLanguages.includes(code)) {
        const title = lang[1];

        acc.push({ code, title });

        return acc;
      }

      return acc;
    }, [] as LangListItem[]);
  }

  setDefaultTranslationKeys() {
    this.keys = DEFAULT_MSD_PROJECT.translationKeys;
  }

  updateTranslationData() {
    const { msdProjectId } = this.dataStorageService.getDataStorage();

    if (msdProjectId === null || msdProjectId === DEFAULT_MSD_PROJECT.id) {
      this.setDefaultTranslationKeys();
    } else {
      const project = msdProjectHelper.getById(msdProjectId);

      this.keys = project.translationKeys;
    }

    const currentLang = this.getCurrentLang();
    const currentKeys = this.getCurrentTranslationKeys();

    if (hasObjectProperty(currentKeys, currentLang)) {
      return;
    }

    this.setLang(DEFAULT_LANG);
  }

  private getCorrectLang(langParam: string): Langs {
    if (langParam) {
      this.hasLangParam = true;

      return this.isValidLangParam(langParam as Langs)
        ? (langParam as Langs)
        : this.getCurrentLang();
    }

    return (
      (localStorage.getItem(LANG_PROPERTY_NAME) as Langs) ||
      this.getCurrentLang()
    );
  }

  private isValidLangParam(lang: Langs) {
    return Object.keys(this.keys).includes(lang);
  }

  updateLangParam(lang: Langs) {
    if (!this.hasLangParam) {
      return;
    }

    const queryParams = { lang };

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: 'merge',
    });
  }
}
