import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterLink, RouterOutlet } from '@angular/router';

import { TranslatePipe } from './pipes';
import {
  ButtonComponent,
  SeparatorWordComponent,
  LangListComponent,
  FormValidationErrorComponent,
  LoaderComponent,
  ModalComponent,
  BackArrowHeaderComponent,
  UserProfilePreviewComponent,
  RegistrationCompletionButtonsComponent,
  StatusCircleIconComponent,
  InputTelComponent,
  ProgressBarComponent,
  EnterCommunityButtonComponent,
  ServerSyncListComponent,
  PostVerifyRegisterStepComponent,
  RadioButtonComponent,
  MsdProjectsListComponent,
  BlockedUsersMessageComponent,
} from './components';

@NgModule({
  declarations: [
    ButtonComponent,
    SeparatorWordComponent,
    TranslatePipe,
    LangListComponent,
    FormValidationErrorComponent,
    LoaderComponent,
    ModalComponent,
    BackArrowHeaderComponent,
    UserProfilePreviewComponent,
    RegistrationCompletionButtonsComponent,
    StatusCircleIconComponent,
    InputTelComponent,
    ProgressBarComponent,
    EnterCommunityButtonComponent,
    ServerSyncListComponent,
    PostVerifyRegisterStepComponent,
    RadioButtonComponent,
    MsdProjectsListComponent,
    BlockedUsersMessageComponent,
  ],
  imports: [CommonModule, ReactiveFormsModule, RouterOutlet, RouterLink],
  exports: [
    ButtonComponent,
    SeparatorWordComponent,
    TranslatePipe,
    LangListComponent,
    FormValidationErrorComponent,
    LoaderComponent,
    ModalComponent,
    BackArrowHeaderComponent,
    UserProfilePreviewComponent,
    RegistrationCompletionButtonsComponent,
    StatusCircleIconComponent,
    InputTelComponent,
    ProgressBarComponent,
    EnterCommunityButtonComponent,
    ServerSyncListComponent,
    PostVerifyRegisterStepComponent,
    RadioButtonComponent,
    MsdProjectsListComponent,
    BlockedUsersMessageComponent,
  ],
})
export class SharedModule {}
