export default {
  aincome_cont_button: 'İlerle',
  aincome_header: 'Profilin',
  aincome_header_info: 'Yıllık gelirin ne kadar?',
  app_steps: 'Adım {{from}} / {{to}}',
  body_height_cont_button: 'İlerle',
  body_height_header: 'Profilin',
  body_height_header_info: 'Boyun ne kadar?',
  body_height_km_100: '148 CM',
  body_height_km_101: '149 CM',
  body_height_km_102: '150 CM',
  body_height_km_103: '151 CM',
  body_height_km_104: '152 CM',
  body_height_km_105: '153 CM',
  body_height_km_106: '154 CM',
  body_height_km_107: '155 CM',
  body_height_km_108: '156 CM',
  body_height_km_109: '157 CM',
  body_height_km_110: '158 CM',
  body_height_km_111: '159 CM',
  body_height_km_112: '160 CM',
  body_height_km_113: '161 CM',
  body_height_km_114: '161 CM',
  body_height_km_115: '163 CM',
  body_height_km_116: '164 CM',
  body_height_km_117: '165 CM',
  body_height_km_118: '166 CM',
  body_height_km_119: '167 CM',
  body_height_km_120: '168 CM',
  body_height_km_121: '169 CM',
  body_height_km_122: '170 CM',
  body_height_km_123: '171 CM',
  body_height_km_124: '172 CM',
  body_height_km_125: '173 CM',
  body_height_km_126: '174CM',
  body_height_km_127: '175 CM',
  body_height_km_128: '176 CM',
  body_height_km_129: '177 CM',
  body_height_km_130: '178 CM',
  body_height_km_131: '179 CM',
  body_height_km_132: '180 CM',
  body_height_km_133: '181 CM',
  body_height_km_134: '182 CM',
  body_height_km_135: '183 CM',
  body_height_km_136: '184 CM',
  body_height_km_137: '185 CM',
  body_height_km_138: '186 CM',
  body_height_km_139: '187 CM',
  body_height_km_140: '188 CM',
  body_height_km_141: '189 CM',
  body_height_km_142: '190 CM',
  body_height_km_143: '191 CM',
  body_height_km_144: '192 CM',
  body_height_km_145: '193 CM',
  body_height_km_146: '194 CM',
  body_height_km_147: '195 CM',
  body_height_km_148: '196 CM',
  body_height_km_149: '197 CM',
  body_height_km_150: '198 CM',
  body_height_km_151: '199 CM',
  body_height_km_152: '200 CM',
  body_height_km_153: '201 CM',
  body_height_km_154: '202 CM',
  body_height_km_155: '203 CM',
  body_height_km_156: '204 CM',
  body_height_km_157: '205 CM',
  body_height_km_158: '206 CM',
  body_height_km_159: '207 CM',
  body_height_km_160: '208 CM',
  body_height_km_161: '209 CM',
  body_height_km_162: '210 CM',
  body_height_km_163: '> 210 CM',
  body_height_km_91: '< 140 CM',
  body_height_km_92: '140 CM',
  body_height_km_93: '141 CM',
  body_height_km_94: '142 CM',
  body_height_km_95: '143 CM',
  body_height_km_96: '144 CM',
  body_height_km_97: '145 CM',
  body_height_km_98: '146 CM',
  body_height_km_99: '147 CM',
  body_height_mi_101: '4\' 11"',
  body_height_mi_104: '5\' 0"',
  body_height_mi_106: '5\' 1"',
  body_height_mi_109: '5\' 2"',
  body_height_mi_112: '5\' 3"',
  body_height_mi_114: '5\' 4"',
  body_height_mi_117: '5\' 5"',
  body_height_mi_119: '5\' 6"',
  body_height_mi_122: '5\' 7"',
  body_height_mi_124: '5\' 8"',
  body_height_mi_127: '5\' 9"',
  body_height_mi_129: '5\' 10"',
  body_height_mi_132: '5\' 11"',
  body_height_mi_134: '6\' 0"',
  body_height_mi_137: '6\' 1"',
  body_height_mi_139: '6\' 2"',
  body_height_mi_142: '6\' 3"',
  body_height_mi_145: '6\' 4"',
  body_height_mi_147: '6\' 5"',
  body_height_mi_150: '6\' 6"',
  body_height_mi_152: '6\' 7"',
  body_height_mi_155: '6\' 8"',
  body_height_mi_157: '6\' 9"',
  body_height_mi_160: '6\' 10"',
  body_height_mi_162: '6\' 11"',
  body_height_mi_163: '> 6\' 11"',
  body_height_mi_91: '< 4\' 8"',
  body_height_mi_94: '4\' 8"',
  body_height_mi_96: '4\' 9"',
  body_height_mi_99: '4\' 10"',
  body_type_cont_button: 'İlerle',
  body_type_header: 'Profilin',
  body_type_header_info: 'Vücut tipin nasıl?',
  char_body: 'Vücut tipi',
  char_children: 'Çocuklar',
  char_ethnicity: 'Etniklik',
  char_eye_color: 'Göz rengi',
  char_hair_color: 'Saç rengi',
  char_header: 'Profilin',
  char_height: 'Boy',
  char_my_height: 'Boyum',
  char_my_height_bigger: 'veya daha uzun',
  char_my_height_smaller: 'veya daha kısa',
  char_smoker: 'Sigara içen',
  children_cont_button: 'İlerle',
  children_header: 'profilin',
  children_header_info: 'Çocukların var mı?',
  contactwish_answer_more_q: 'Güzel! Lütfen bir soru daha yanıtla',
  contactwish_answer_q_ph: 'Senin cevabın',
  contactwish_cwish: 'Buluşma tercihleri',
  contactwish_flirttext: 'Flört mesajı',
  contactwish_header: 'Temas tercihleri',
  contactwish_header_info: 'Kişiliğini benzersiz bir flört mesajıyla göster',
  contactwish_input_ph: 'Senin flört mesajın',
  contactwish_more_about_you: 'Kendinden daha fazla bahset',
  contactwish_questions: 'Sorular',
  contactwish_save_btn: 'Kaydet',
  contactwish_selec_ph: 'Bir soru seç',
  contactwish_what_you_search: 'Burada tam olarak aradığın nedir?',
  cwish_cont_button: 'İlerle',
  cwish_header: 'Profilin',
  cwish_header_info: 'Burada tam olarak aradığın nedir?',
  db_1: 'Yok',
  db_10: 'Konser',
  db_11: 'Tiyatro',
  db_12: 'Alışveris',
  db_13: 'Müzik',
  db_14: 'Sanat',
  db_15: 'Dışarıda takılmak',
  db_16: 'Çizim',
  db_164: 'atletik',
  db_165: 'zayıf',
  db_166: 'kaslı',
  db_167: 'bir kaç kilo fazla',
  db_168: 'daha sonra söylerim',
  db_169: 'Almanca',
  db_17: 'Sinema',
  db_170: 'İtalyanca',
  db_171: 'Fransızca',
  db_172: 'İspanyolca',
  db_173: 'Portekizce',
  db_174: 'İngilizce',
  db_175: 'Rusça',
  db_176: 'Tenis',
  db_177: 'Golf',
  db_178: 'Yelken',
  db_179: 'Futbol',
  db_18: 'Spor Programları',
  db_180: 'Kürek çekme',
  db_181: 'Dans',
  db_182: 'Judo',
  db_183: 'Binicilik',
  db_184: 'Badminton',
  db_185: 'Fitness',
  db_186: 'Masa Tenisi',
  db_187: 'Koşu',
  db_188: 'Kayak',
  db_189: 'Hentbol',
  db_19: 'Dışarıda yemek yemek',
  db_190: 'Hokey',
  db_191: 'Bisiklet sürme',
  db_192: 'Karate',
  db_193: 'Yüzme',
  db_194: 'Flört',
  db_195: 'Erotizm',
  db_198: 'İlişki',
  db_2: '1',
  db_20: 'Spor',
  db_204: 'SB / SD İlişki',
  db_206: 'Yemek/Alışveriş',
  db_207: 'Asya',
  db_208: 'Siyahi/Afro Amerikan',
  db_209: 'Latin / Hispanik',
  db_21: 'Bar',
  db_210: 'Hint',
  db_211: 'Orta Doğu',
  db_212: 'Karışık',
  db_213: 'Beyaz/Avrupalı',
  db_214: 'Diğer',
  db_216: 'Lehçe',
  db_217: 'Avcılık',
  db_218: 'Hollandaca',
  db_219: 'Türkçe',
  db_22: 'Yemek yapma',
  db_220: 'Ukraynaca',
  db_221: 'Macarca',
  db_222: 'Yunanca',
  db_223: 'Çekçe',
  db_224: 'Bulgarca',
  db_225: 'İsveççe',
  db_226: 'Danca',
  db_227: 'Norveççe',
  db_228: 'Fince',
  db_229: 'Çince',
  db_23: 'Doğa yürüyüşü',
  db_230: 'Yoga',
  db_231: 'Pilates',
  db_232: 'Tai Chi',
  db_233: 'Sörf',
  db_234: 'Plaj Voleybolu',
  db_235: 'Tırmanma',
  db_236: 'Uçurtma sörfü',
  db_237: 'Paten',
  db_238: 'Dağ bisikleti',
  db_239: 'Snowboard',
  db_24: 'Kitap okuma',
  db_240: 'Basketbol',
  db_241: 'Beyzbol',
  db_242: 'Futbol',
  db_243: 'Direk Dansı',
  db_244: 'Zumba',
  db_245: 'Bale',
  db_246: 'Danışman',
  db_247: 'Yönetici',
  db_248: 'Kıdemli personel',
  db_249: 'Denetçi',
  db_25: 'Arabalar',
  db_250: 'Vergi Danışmanı',
  db_251: 'Bilgisayar Uzmanı',
  db_252: 'Mühendis',
  db_253: 'Eczacı',
  db_254: 'Öğretmen',
  db_255: 'Bilim adamı',
  db_256: 'Öğretmen',
  db_257: 'Bilim kadını',
  db_258: 'Avukat',
  db_259: 'Doktor',
  db_26: 'Seyahat',
  db_260: 'Sporcu',
  db_261: 'Antrenör',
  db_262: 'Sekreter',
  db_263: 'Eğitimci',
  db_264: 'Güzellik uzmanı',
  db_265: 'Kuaför',
  db_266: 'Makyaj uzmanı',
  db_267: 'Fizyoterapist',
  db_268: 'Masöz',
  db_269: 'Model',
  db_27: 'Resim',
  db_270: 'Aşçı',
  db_271: 'Çiçekçi',
  db_272: 'Gazeteci',
  db_273: 'Oyuncu',
  db_274: 'Bahçe işleri',
  db_275: 'Sauna',
  db_276: 'Uçmak',
  db_277: 'Motosiklet',
  db_278: 'Fotoğrafçılık',
  db_279: 'Mühendis',
  db_28: "{{amount}} 'den daha az",
  db_280: 'Arapça',
  db_281: 'diğer',
  db_282: 'diğer',
  db_283: 'Diğer',
  db_284: 'Diğer',
  db_285: 'Diğer',
  db_286: 'Raketbol',
  db_287: 'Diğer',
  db_29: '{{amount_min}} - {{amount_max}}',
  db_3: '2',
  db_30: '{{amount_min}} - {{amount_max}}',
  db_31: '{{amount_min}} - {{amount_max}}',
  db_32: "{{amount}}'den daha fazla",
  db_33: 'Bunu paylaşmamayı tercih ederim',
  db_34: 'yeterince',
  db_35: 'mavi',
  db_36: 'yeşil',
  db_37: 'kahverengi',
  db_38: 'gri',
  db_39: 'kahverengi',
  db_4: '3',
  db_40: 'siyah',
  db_41: 'kırmızı',
  db_42: 'gümüş',
  db_43: 'beyaz',
  db_44: 'açık sarı',
  db_45: 'koyu sarı',
  db_5: '4',
  db_51: 'daha az {{amount}}',
  db_52: '{{amount_min}} - {{amount_max}}',
  db_53: '{{amount_min}} - {{amount_max}}',
  db_54: '{{amount_min}} - {{amount_max}}',
  db_55: "{{amount}}'den daha fazla",
  db_56: 'Bunu paylaşmamayı tercih ederim',
  db_57: 'yeterince',
  db_58: 'Prodüktör',
  db_59: 'Avukat',
  db_6: '4+',
  db_60: 'Girişimci',
  db_61: 'Mimar',
  db_62: 'Televizyon Starı',
  db_63: 'Profesyonel Sporcu',
  db_64: 'Serbest Meslek',
  db_65: 'Hedonist',
  db_66: 'Gurme',
  db_67: 'Sanatçı',
  db_68: 'Bankacı',
  db_69: 'Doktor',
  db_7: 'Evet',
  db_70: 'Pilot',
  db_71: 'Finans Danışmanı',
  db_72: 'Yatırım Bankacısı',
  db_73: 'Diğer',
  db_74: 'Yönetim Danışmanı',
  db_75: 'Varlık Yönetimi',
  db_76: 'Hemşire',
  db_77: 'Hostes',
  db_78: 'Banka çalışanı',
  db_79: 'Eczacı',
  db_8: 'Hayır',
  db_81: 'Sanatçı',
  db_83: 'Yönetici',
  db_84: 'Öğrenci',
  db_85: 'Asistan',
  db_86: 'Asistan doktor',
  db_87: 'Öğrenci',
  db_89: 'Fotoğrafçı',
  db_9: 'Bazen',
  db_90: 'Stajyer',
  db_profile_question_female_2: 'Bu hayalini kurduğum gelecek.',
  db_profile_question_female_3:
    'Mükemmel gecenin nasıl olacağını hayal ediyorum.',
  db_profile_question_female_4: 'Hep ne yapmak istedin?',
  db_profile_question_female_5: 'Birisi seni nasıl büyüleyebilir?',
  db_profile_question_female_6: 'Hayallerin nedir?',
  db_profile_question_female_7: 'Nelerden hoşlanırsın?',
  db_profile_question_male_2: 'Bu tam olarak hayal ettiğim gibi bir gece.',
  db_profile_question_male_3: 'Bu benim hakkımda bilmen gereken başka bir şey.',
  db_profile_question_male_4: 'Bana böyle bir süpriz yapabilirsin.',
  db_profile_question_male_5: 'Hep bunu yapmak istemişimdir.',
  ethnic_group_cont_button: 'İlerle',
  ethnic_group_header: 'Profilin',
  ethnic_group_header_info: 'Etnik kökenin nedir?',
  eye_color_cont_button: 'İlerle',
  eye_color_header: 'Profilin',
  eye_color_header_info: 'Göz rengin nedir?',
  flirt_text_cont_button: 'İlerle',
  flirt_text_header: 'Profilin',
  flirt_text_header_info: 'Kendinden bahset',
  hair_color_cont_button: 'İlerle',
  hair_color_header: 'Profilin',
  hair_color_header_info: 'Saç rengin nedir?',
  into_community_btn: 'Topluluğa katıl',
  job_cont_button: 'İlerle',
  job_header: 'Profilin',
  job_header_info: 'Mesleğin nedir?',
  languages_cont_button: 'İlerle',
  languages_header: 'Profilin',
  languages_header_info: 'Hangi dilleri konuşuyorsun?',
  left_part_header: 'Kendi <br>MySugardaddy yaşam tarzını yaşa',
  lifestyle_header: 'Senin yaşam tarzın',
  lifestyle_nav_activity: 'Boş zamanlar',
  lifestyle_nav_annual_income: 'Yıllık gelir',
  lifestyle_nav_job: 'Meslek',
  lifestyle_nav_lang: 'Diller',
  lifestyle_nav_mbudget: 'Aylık bütçe',
  lifestyle_nav_pproperty: 'Kişisel varlıklar',
  lifestyle_nav_sport: 'Spor',
  lifestyle1_cont_button: 'İlerle',
  lifestyle1_header: 'Profilin',
  lifestyle1_header_info: 'Boş zamanlarında neler yaparsın?',
  login_check_stay_login: 'Oturumu açık tut',
  login_err_invalid_mail_or_pw: 'Lütfen kullanıcı adını ve şifreni kontrol et',
  login_err_mail_unknow: 'Bilinmeyen e-mail',
  login_err_no_mail: 'Bir e-posta adresi girilmelidir',
  login_err_no_pw: 'Şifreni gir',
  login_forgot_pw: 'Şifreni mi unuttun?',
  login_header: 'Yeniden Hoşgeldin',
  login_input_mail_ph: 'Kullanıcı adı/e-mail',
  login_input_pw_ph: 'Şifre',
  login_login_btn: 'Giriş yap',
  login_login_fb: 'Facebook ile giriş yap',
  login_logout_header: '... Oturumu kapattınız.',
  login_no_acc: 'Henüz bir hesabın yok mu?',
  login_project_header: 'Hangi topluluğa katılmak istersiniz?',
  login_project_header_info:
    'Sizi doğru toplulukta gösterebilmemiz için lütfen seçiminizi bize bildirin.',
  login_project_id_0_desc: 'Klasik haliyle şeker randevuları',
  login_project_id_0_title: 'MSD classic',
  login_project_id_1_desc: 'LGBTQ+ topluluğu için şekerle tanışma',
  login_project_id_1_title: 'MSD gay',
  login_project_id_2_desc: 'Güçlü, bağımsız kadınlarla şeker buluşması',
  login_project_id_2_title: 'MSD mamas',
  login_project_validation_text: 'Lütfen giriş yapmak istediğiniz yeri seçin',
  login_reg: 'Şimdi kaydol',
  login_use_email: 'E-mail adresi ile giriş yap',
  login_want_reg: 'Giriş yapmak ister misin?',
  maintenance_message:
    'Web sitesi şu anda bakımda. Verdiğimiz rahatsızlık için özür dileriz.',
  nav_back_button: 'Geri',
  nav_next_button: 'İleri',
  partner_role_sugarbabe: 'sugarbabe',
  partner_role_sugarboy: 'Sugarboy',
  partner_role_sugardaddy: 'Sugardaddy',
  partner_role_sugarmom: 'Sugarmom',
  pimage_cont_button: 'İlerle',
  pimage_edit_image_btn: 'Düzenle',
  pimage_from: "'dan",
  pimage_header: 'Profil resmin',
  pimage_header_desc: 'En iyi tarafından göster kendini',
  pimage_header_info: 'Profilini genişlet, en iyi tarafından göster kendini',
  pimage_modal_close_btn: 'Kapat',
  pimage_modal_header: 'Fotoğraf yükle',
  pimage_modal_save_btn: 'Kaydet',
  pimage_prev_cont_button: 'İlerle',
  pimage_prev_header: 'Profilin',
  pimage_prev_header_desc: 'Güzel!',
  pimage_prev_header_info: 'Yüksek bir yaşam standardı seni bekliyor!',
  pimage_prev_sms_cont_button: 'Şimdi flört etmeye başla',
  pimage_prev_sms_edit_button: 'veya Profilini tamamla',
  pimage_prev_sms_header: 'Güvenlik',
  pimage_prev_sms_header_desc: 'Muhteşem! Profilin doğrulandı',
  pimage_up_image_btn: 'Şimdi bir fotoğraf yükle',
  pproperty_cont_button: 'İlerle',
  pproperty_header: 'Profilin',
  pproperty_header_info: 'Ne kadar kazanıyorsun?',
  pw_reset_btn: 'İlerle',
  pw_reset_desc:
    'Giriş yapmak için kullandığın e-posta adresini gir. Sonraki adımlar için hemen bir e-posta alacaksın.',
  pw_reset_err_no_mail: 'Lütfen e- mail adresinizi gir',
  pw_reset_header: 'Güvenlik',
  pw_reset_input_mail_ph: 'E-Mail',
  pw_reset_success_mail:
    'Sonraki adımları içeren bir e-mail gönderildi. E-maili hemen almadıysan lütfen spam klasörünüzü kontrol et.',
  pw_rt_btn: 'Şifreyi onayla',
  pw_rt_desc:
    'Lütfen yeni bir şifre gir ve ardından şifreni onayla. Herhangi bir sorunun varsa lütfen support@mysugardaddy.eu ile iletişime geçin.',
  pw_rt_header: 'Güvenlik',
  pw_rt_input_password_conf_ph: 'Şifreyi tekrar gir',
  pw_rt_input_password_equal_err: 'Şifreler eşleşmek zorunda',
  pw_rt_input_password_len_err:
    'Parolanız 6 ila 20 karakter uzunluğunda olmalıdır.',
  pw_rt_input_password_no_pw_err: 'Lütfen şifre gir',
  pw_rt_input_password_ph: 'Şifre',
  questions_cont_button: 'İlerle',
  questions_header: 'Profilin',
  questions_header_info: 'Cevaplamak isteyebileceğin bazı sorular',
  reg_already_member: 'Zaten üye misin?',
  reg_err_select_gender: 'Lütfen cinsiyetini belirt',
  reg_finished_1_success_msg: 'Harika!',
  reg_finished_2_success_msg: 'Profilini oluşturmayı tamamladın',
  reg_finished_cont_button: 'Şimdi flört etmeye başla',
  reg_finished_header: 'Profil',
  reg_header: 'Şimdi ücretsiz kaydol',
  reg_header_info: 'Ben bir',
  reg_header_info_hide: 'Ben',
  reg_login: 'Giriş yap',
  reg_or: 'veya',
  reg_prob_modal_body:
    'Maalesef hesabınla ilgili bir sorun var. Sana yardımcı olabilmemiz için lütfen destek ekibimizle iletişime geçin.',
  reg_prob_modal_close: 'Kapat',
  reg_prob_modal_header: 'Hesabınla ilgili bir sorun var',
  reg_sugarbabe: 'Sugar baby',
  reg_sugarbabe_hide: 'Kadın',
  reg_sugardaddy: 'Sugar daddy',
  reg_sugardaddy_hide: 'Erkek',
  reg_with_apple: 'Apple ile kaydol',
  reg_with_email: 'E-mail adresi ile kaydol',
  reg_with_facebook: 'Facebook ile kaydol',
  reg_with_google: 'Google ile kaydol',
  regd_18_error: '18 yaşın altındasın',
  regd_accept_agb:
    "Kaydolarak, MySugardaddy'nin <a href='https://www.mysugardaddy.com/privacy-policy' target='_blank'>Gizlilik Politikası</a>'nı ve <a href='https://www. mysugardaddy.com/terms-and-conditions' target='_blank'>Kullanım Koşulları</a>'na gidin ve 18 yaşından büyük olduğunu onaylayın.",
  regd_city_ph: 'Şehir',
  regd_email_ph: 'E-mail',
  regd_err_accept_agb: 'Kullanım Şartları ve Koşullarımızı kabul etmelisin',
  regd_err_add_loc: 'Konumunu belirle',
  regd_err_add_pw: 'Parolanız 6 ila 20 karakter uzunluğunda olmalıdır.',
  regd_err_add_username: 'Bir kullanıcı adı belirlemelisin',
  regd_err_mail_locked: 'Bu e-mail adresi zaten kullanılıyor',
  regd_err_no_lat_lng: 'Konum bulunamadı. Lütfen farklı bir konum gir',
  regd_err_no_mail: 'Bir e-mail adresi girmelisin',
  regd_err_recaptcha: 'Robot olmadığını doğrula',
  regd_eventcode_ph: 'Promosyon kodu',
  regd_header_info: 'Hayallerini gerçekleştir!',
  regd_label_birthday: 'Doğum tarihi',
  regd_label_eventcode: 'Promosyon kodun var mı?',
  regd_modal_btn_babe: 'Ben bir: sugar baby',
  regd_modal_btn_daddy: 'Ben bir: sugar daddy',
  regd_modal_conf_text:
    'Girmiş olduğun bilgiler doğruysa, "şimdi ücretsiz kaydol" düğmesini tıkla. <br> Bilgilerinde yanlışlık var ise buradan değiştirebilirsin.',
  regd_modal_content:
    '<p>Bir <b>{{iam}}</b> misin?</p><p> Ve bir <b>{{isearch}}</b> mi arıyorsun?</p>',
  regd_modal_header: 'Lütfen bilgilerini tekrar kontrol et',
  regd_modal_wrong_search: 'Yukarıdaki ifade yanlış mı?',
  regd_months_1: 'Ocak',
  regd_months_10: 'Ekim',
  regd_months_11: 'Kasım',
  regd_months_12: 'Aralık',
  regd_months_2: 'Şubat',
  regd_months_3: 'Mart',
  regd_months_4: 'Nisan',
  regd_months_5: 'Mayıs',
  regd_months_6: 'Haziran',
  regd_months_7: 'Temmuz',
  regd_months_8: 'Ağustos',
  regd_months_9: 'Eylül',
  regd_password_ph: 'Şifre',
  regd_reg_button: 'Şimdi ücretsiz kaydol',
  regd_select_gender: 'Cinsiyetini belirt',
  regd_select_gender_err: 'Cinsiyetini belirt',
  regd_select_gender_f: 'Kadın',
  regd_select_gender_m: 'Erkek',
  regd_username_ph: 'Takma iad',
  regd_want_login: 'Giriş yapmak ister misin?',
  register_partner_role_list_default_text: 'Lütfen seçiniz',
  register_partner_role_list_label: 'Arıyorum...',
  register_partner_role_validation_text: 'Lütfen kimi aradığınızı belirtin',
  smoker_cont_button: 'İlerle',
  smoker_header: 'Profilin',
  smoker_header_info: 'Sigara içiyor musun?',
  sports_cont_button: 'İlerle',
  sports_header: 'Profilin',
  sports_header_info: 'Hangi sporlarla ilgileniyorsun?',
  summary_edit_mail_btn: 'E-mail adresini değiştir',
  summary_edit_profile_btn: 'Veya profilini doldurmaya devam et',
  summary_go_to_community_btn: 'Şimdi flört etmeye başla',
  summary_header: 'Güvenlik',
  summary_header_desc: "Yeni bir başlangıç zamanı! MySugardaddy'ye hoşgeldin",
  summary_mail_info:
    '<p>E-posta adresine</p><p><b>{{mail}}</b></p><p> bir doğrulama bağlantısı gönderdik.</p>',
  summary_mail_repeat:
    '<p>Lütfen spam klasörünü kontrol et. Kaydınla ilgili hâlâ sorun yaşıyorsan destek ekibimiz</p><p><span>support@mysugardaddy.eu</span></p> adresinden size yardımcı olmaktan memnuniyet duyacaktır.',
  summary_modal_body: 'E-mail adresini buradan değiştirebilirsin',
  summary_modal_close: 'Kapat',
  summary_modal_header: 'E-mail adresini değiştir',
  summary_modal_save_send_btn: 'Kaydet ve Gönder',
  summary_new_veri_mail: 'Yeni doğrulama e-postası başarıyla gönderildi',
  summary_not_received_btn: 'E-postayı henüz almadın mı?',
  summary_resend_mail_btn: 'Tekrar e-mail gönder',
  summary_sms_btn: 'Şimdi SMS ile doğrula',
  summary_sms_info: 'Profilini telefon numaranla da doğrulayabilirsin.',
  summary_sms_info_success: 'Gerçek olduğunu göster',
  summary_success_header: 'İşlem tamam!',
  summary_success_msg: 'Harika!',
  summary_veri_mail_success: 'E-mail adresin başarıyla doğrulandı',
  summary_wrong_mail_info: 'Yanlış bir e-mail adresi mi girdin?',
  username_cont_button: 'İlerle',
  username_header: 'Profilin',
  username_header_info: 'sana ne denmesini istersin?',
  username_suggestions: 'İşte birkaç öneri',
  verif_prof_cont_button: 'İlerle',
  verif_prof_explain_info: 'Yüksek bir yaşam standardı seni bekliyor!',
  verif_prof_header: 'Güvenlik',
  verif_prof_header_desc: 'Profilini doğrula',
  verif_prof_header_info: 'SMS doğrulaması ile profilini tamamla',
  verif_prof_verf_email: 'E-mail ile doğrula',
  verif_prof_verf_sms: 'SMS ile doğrula',
  verify_phone_c_code_sent_info:
    'Telefonuna bir kod gönderdik <b>{{phonenumber}}</b>. Bu işlem bir dakika sürebilir.',
  verify_phone_c_cont_button: 'Kodu onayla',
  verify_phone_c_error_empty: 'Lütfen 6 haneli kodu gir',
  verify_phone_c_error_resends_limit:
    'Bir şeyler ters gitti. Lütfen destek ekibimizle bağlantıya geç: support@mysugardaddy.eu',
  verify_phone_c_error_wrong: 'Girdiğin kod yanlış',
  verify_phone_c_header: 'Güvenlik',
  verify_phone_c_header_info: 'Profilini doğrula',
  verify_phone_c_intro: 'Lütfen doğrulama kodunu gir',
  verify_phone_c_resend_success: 'Yeni kod başarıyla gönderildi',
  verify_phone_c_retry_btn: 'Yeniden kod gönder',
  verify_phone_codeform_error_empty: 'Lütfen telefon numaranı gir',
  verify_phone_codeform_error_invalid:
    'Lütfen geçerli bir telefon numarası gir',
  verify_phone_codeform_nocorrectphonenumbers_err: 'Telefon numaran yanlış',
  verify_phone_cont_button: 'Profilini doğrula',
  verify_phone_header: 'Güvenlik',
  verify_phone_header_info: 'Profilini doğrula',
  verify_phone_input_txt:
    'Yalnızca bir SMS doğrulaması alacaksın, spam göndermiyoruz',
  verify_phone_intro: 'Doğrulanmış bir üye olduğunu göster!',
};
