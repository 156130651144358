import { AvailableLoginSites } from '../interfaces';
import { environment } from '../../../environments/environment';
import { MSD_PROJECT } from '../config';

let defaultLoginSite = AvailableLoginSites.eu;

if (!environment.production) {
  const { classic, gay } = MSD_PROJECT;
  const devLoginSuites = [...classic.domains, ...gay.domains];
  const index = devLoginSuites.indexOf(window.location.hostname);

  defaultLoginSite = (devLoginSuites[index] ||
    'devregister.mysugardaddy.com') as AvailableLoginSites;
}

export const DEFAULT_LOGIN_SITE = defaultLoginSite;

export const HIDDEN_MODE_LOGIN_SITES = [AvailableLoginSites.datesCom];
