import { CanActivateFn } from '@angular/router';
import { first, map } from 'rxjs';
import { RegisterStep } from '../../data/interfaces';
import { inject } from '@angular/core';
import {
  RegisterNavigationService,
  RegisterVerifyProfileService,
} from '../../modules/registration/services';

export const verificationMethodsGuard: CanActivateFn = (route) => {
  const registerNavigationService = inject(RegisterNavigationService);
  const registerVerifyProfileService = inject(RegisterVerifyProfileService);

  const { routeTitle } = route.data;
  const prop =
    routeTitle === RegisterStep.verifyProfileEmail ? 'hasMail' : 'hasSms';

  const verifyMethod = registerVerifyProfileService.getVerificationMethod();

  if (verifyMethod === null) {
    return registerVerifyProfileService.getAvailableVerificationMethods().pipe(
      first(),
      map((response) => {
        if (response[prop]) {
          return true;
        }

        registerNavigationService.goToStep(RegisterStep.verifyProfile, false);

        return false;
      }),
    );
  }

  if (verifyMethod[prop]) {
    return true;
  }

  registerNavigationService.goToStep(RegisterStep.verifyProfile);

  return false;
};
