import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { finalize, map, tap } from 'rxjs';

import {
  BaseResponse,
  CountryISO,
  LoginSiteCountryCodes,
  VerificationMethod,
  VerificationMethodDataType,
} from '../../../data/interfaces';
import { environment } from '../../../../environments/environment';
import {
  AuthService,
  DataStorageService,
  LoaderService,
} from '../../../core/services';

interface SendEmail {
  email: string;
}

interface CheckEmailStatus {
  email: string;
  checked: boolean;
}

interface SendSms {
  message?: string;
}

enum UserPhoneNumber {
  prop = 'userPhoneNumber',
}

@Injectable({
  providedIn: 'root',
})
export class RegisterVerifyProfileService {
  private verificationMethod: VerificationMethodDataType = null;

  private isEmailChecked: boolean | null = null;

  private truncatedUserEmail = '';

  private newUserEmail = '';

  private userPhoneNumber = localStorage.getItem(UserPhoneNumber.prop) || '';

  private isSmsVerificationCompleted: boolean | null = null;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private dataStorageService: DataStorageService,
    private loaderService: LoaderService,
  ) {}

  getAvailableVerificationMethods() {
    this.loaderService.showLoader();

    return this.http
      .post<BaseResponse<VerificationMethod>>(
        `${environment.HOME_API_URL}/isRegCheck`,
        {},
      )
      .pipe(
        map(({ data }) => {
          const methods = Object.values(VerificationMethod);
          const result = methods.includes(data) ? data : VerificationMethod.all;

          this.verificationMethod = {
            hasSms: true,
            hasMail: true,
          };

          if (result !== VerificationMethod.all) {
            this.verificationMethod.hasSms = result === VerificationMethod.sms;
            this.verificationMethod.hasMail =
              result === VerificationMethod.mail;
          }

          return this.verificationMethod;
        }),
        finalize(() => {
          this.loaderService.hideLoader();
        }),
      );
  }

  verifyEmail(mailToken = '', registerToken = '') {
    const authData = this.authService.getAuthInfoForRequest();
    const requestPayload = {
      mailtoken: mailToken,
      ...authData,
    };

    if (registerToken) {
      requestPayload.regauth = registerToken;
    }

    return this.http
      .post<BaseResponse<CheckEmailStatus>>(
        `${environment.REG_API_URL}/registrationCreateRegGetMail`,
        requestPayload,
      )
      .pipe(
        tap(({ data }) => {
          this.truncatedUserEmail = data.email;
          this.isEmailChecked = data.checked;
        }),
      );
  }

  checkSMSVerificationStatus() {
    this.loaderService.showLoader();

    const authData = this.authService.getAuthInfoForRequest();
    const requestPayload = {
      ...authData,
    };

    return this.http
      .post<BaseResponse<boolean>>(
        `${environment.REG_API_URL}/isSMSChecked`,
        requestPayload,
      )
      .pipe(
        tap((response) => {
          this.isSmsVerificationCompleted = response.data;
        }),
        finalize(() => {
          this.loaderService.hideLoader();
        }),
      );
  }

  sendEmail(email = '') {
    const authData = this.authService.getAuthInfoForRequest();
    const requestPayload = {
      email,
      ...authData,
    };

    return this.http
      .post<BaseResponse<SendEmail>>(
        `${environment.REG_API_URL}/registrationCreateRegResendMail`,
        requestPayload,
      )
      .pipe(
        tap((response) => {
          this.truncatedUserEmail = response.data.email;
        }),
      );
  }

  sendSMSToRecipientWithCode(number: string) {
    const authData = this.authService.getAuthInfoForRequest();
    const requestPayload = {
      number: number.replace(/\s/g, ''),
      ...authData,
    };

    return this.http
      .post<BaseResponse<string | SendSms>>(
        `${environment.REG_API_URL}/verifySend`,
        requestPayload,
      )
      .pipe(
        map(({ data, errortxt, error }) => {
          const result = {
            success: !error,
            message: errortxt,
            isCorrectPhoneNumber: true,
          };

          if (!error && !errortxt) {
            this.setUserPhoneNumber(requestPayload.number);
          }

          if (
            typeof data === 'object' &&
            data?.message === 'nocorrectphonenumbers'
          ) {
            result.isCorrectPhoneNumber = false;
          }

          return result;
        }),
      );
  }

  verifySMSCode(code: string) {
    const authData = this.authService.getAuthInfoForRequest();
    const requestPayload = {
      number: this.userPhoneNumber,
      code,
      ...authData,
    };

    return this.http
      .post<BaseResponse<string>>(
        `${environment.REG_API_URL}/verifyCheck`,
        requestPayload,
      )
      .pipe(
        tap(({ error }) => {
          if (!error) {
            this.isSmsVerificationCompleted = true;
            this.clearUserPhoneNumber();
          }
        }),
      );
  }

  getVerificationMethod() {
    return this.verificationMethod;
  }

  getSmsVerificationStatus() {
    return this.isSmsVerificationCompleted;
  }

  getUserPhoneNumber() {
    return this.userPhoneNumber;
  }

  getEmailStatus() {
    return this.isEmailChecked;
  }

  getTruncatedUserEmail() {
    return this.truncatedUserEmail;
  }

  setNewUserEmail(email: string) {
    this.newUserEmail = email;
  }

  getNewUserEmail() {
    return this.newUserEmail;
  }

  resetSmsVerificationStatus() {
    this.isSmsVerificationCompleted = null;
  }

  getDefaultSelectedCountry() {
    const { loginsite } = this.dataStorageService.getDataStorage();
    const key = loginsite as keyof typeof LoginSiteCountryCodes;
    const result = LoginSiteCountryCodes[key] as unknown as CountryISO;

    return result || CountryISO.Germany;
  }

  private setUserPhoneNumber(number: string) {
    this.userPhoneNumber = number;
    localStorage.setItem(UserPhoneNumber.prop, number);
  }

  private clearUserPhoneNumber() {
    this.userPhoneNumber = '';
    localStorage.removeItem(UserPhoneNumber.prop);
  }
}
