import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, finalize, first, tap } from 'rxjs';

import { environment } from '../../../environments/environment';
import {
  AuthProps,
  AuthStorage,
  BaseResponse,
  LoginCredentials,
  LoginPossibleProjects,
  LoginRegisterFinished,
  LoginRegisterNotFinished,
  LoginResponse,
  RegisterDetailsData,
  RegisterStep,
  UserCredentials,
} from '../../data/interfaces';
import { hasObjectProperty } from '../../data/helpers';
import {
  DataStorageService,
  FingerPrintService,
  IpQualityScoreService,
  LoaderService,
  TrackingService,
} from '../services';
import { Router } from '@angular/router';
import { SYSTEM_ROUTES } from '../../data/constants';

enum StorageProperties {
  loginToken = 'loginToken',
  registerToken = 'registerToken',
}

type CommunityLink = string | null | undefined;

interface RegisterResponse {
  token: string;
}

interface CommunityLoginCredentials {
  login: boolean;
  link: string;
}

type CheckLoggedInUserResponse = CommunityLoginCredentials | [];

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private isRegisterAuth = false;

  private loginToken = localStorage.getItem(StorageProperties.loginToken);

  private registerToken = localStorage.getItem(StorageProperties.registerToken);

  private loginToRegisterStep$ = new BehaviorSubject<RegisterStep | null>(null);

  private userCredentials: UserCredentials | null = null;

  loginToRegisterStep = this.loginToRegisterStep$.asObservable();

  constructor(
    private http: HttpClient,
    private dataStorageService: DataStorageService,
    private loaderService: LoaderService,
    private fingerPrintService: FingerPrintService,
    private ipQualityScoreService: IpQualityScoreService,
    private trackingService: TrackingService,
    private router: Router,
  ) {}

  setRegisterAuth(value: boolean) {
    this.isRegisterAuth = value;
  }

  checkLoggedInUser() {
    const credentials = {
      rememberauth: this.loginToken,
      project: this.getMsdProjectId(),
    };

    return this.http.post<BaseResponse<CheckLoggedInUserResponse>>(
      `${environment.HOME_API_URL}/registrationLoginUserCheckRemember`,
      credentials,
    );
  }

  login(credentials: LoginCredentials, projectId?: number) {
    this.resetRegAuth();
    this.resetLoginAuth();
    this.loaderService.showLoader();

    const requestPayload = {
      ...credentials,
      device: 'Website',
      project: projectId,
    };

    if (projectId === undefined) {
      delete requestPayload.project;
    }

    return this.http
      .post<BaseResponse<LoginResponse>>(
        `${environment.NEW_HOME_API_URL_V1}/login`,
        requestPayload,
      )
      .pipe(
        tap(({ data, error }) => {
          if (error) {
            this.loaderService.hideLoader();

            return;
          }

          this.handleAuthResponse(data, credentials);
        }),
      );
  }

  register(registerData: RegisterDetailsData) {
    this.resetRegAuth();

    return this.http
      .post<BaseResponse<RegisterResponse | []>>(
        `${environment.HOME_API_URL}/registrationCreateRegWoMail`,
        registerData,
      )
      .pipe(
        tap(({ data }) => {
          if (!Array.isArray(data) && data.token) {
            const hasTrackingData = this.trackingService.hasTrackingData();
            const { token } = data;

            this.registerToken = token;
            this.isRegisterAuth = true;
            localStorage.setItem(StorageProperties.registerToken, token);

            this.fingerPrintService
              .sendUserId(token, registerData.project)
              .then()
              .catch();
            this.ipQualityScoreService.sendDeviceData().then().catch();

            if (hasTrackingData) {
              this.trackingService
                .sendAuthTrackingData(token, registerData.project)
                .pipe(first())
                .subscribe();
            }
          }
        }),
      );
  }

  isRegAuthenticated() {
    return this.isRegisterAuth;
  }

  isRememberedLogin() {
    return !!this.loginToken;
  }

  redirectToCommunity(link: CommunityLink) {
    this.resetRegAuth();
    window.location.href = link || environment.BASE_APP_URL;
  }

  resetRegAuth() {
    this.registerToken = null;
    this.isRegisterAuth = false;
    localStorage.removeItem(StorageProperties.registerToken);
  }

  resetLoginAuth() {
    this.loginToken = null;
    localStorage.removeItem(StorageProperties.loginToken);
  }

  handleAuthResponse(data: LoginResponse, credentials?: LoginCredentials) {
    if (!data) {
      return;
    }

    if (hasObjectProperty(data, 'register')) {
      const { regstep, auth } = data as LoginRegisterNotFinished;

      localStorage.setItem(AuthStorage.loggedIn, 'true');
      this.setRegisterToken(auth);
      this.loginToRegisterStep$.next(regstep);
    }

    if (hasObjectProperty(data, AuthProps.possibleProjects) && credentials) {
      const { possibleProjects } = data as LoginPossibleProjects;

      this.userCredentials = {
        email: credentials.email,
        password: credentials.password,
        remember: credentials.remember || false,
        possibleProjects,
      };

      this.router.navigate([
        `${SYSTEM_ROUTES.login}/${SYSTEM_ROUTES.loginProjects}`,
      ]);
    }

    if (hasObjectProperty(data, 'login')) {
      const { link, rememberauth } = data as LoginRegisterFinished;

      if (rememberauth) {
        this.loginToken = rememberauth;
        localStorage.setItem(StorageProperties.loginToken, rememberauth);
      }

      localStorage.setItem(AuthStorage.loggedIn, 'true');
      this.redirectToCommunity(link);
    }
  }

  getToken() {
    return { register: this.registerToken, login: this.loginToken };
  }

  setRegisterToken(token: string) {
    this.registerToken = token;
    localStorage.setItem(StorageProperties.registerToken, token);
  }

  setUserActivation() {
    this.loaderService.showLoader();

    const authData = this.getAuthInfoForRequest();
    const requestPayload = {
      ...authData,
    };

    return this.http
      .post<BaseResponse<CommunityLoginCredentials>>(
        `${environment.REG_API_URL}/setUserActiveReg`,
        requestPayload,
      )
      .pipe(
        tap(({ data }) => {
          this.handleAuthResponse(data);
        }),
        finalize(() => {
          this.loaderService.hideLoader();
        }),
      );
  }

  getAuthInfoForRequest() {
    const { msdProjectId } = this.dataStorageService.getDataStorage();

    return {
      regauth: this.registerToken,
      project: msdProjectId,
    };
  }

  getUserCredentials() {
    return this.userCredentials;
  }

  resetUserCredentials() {
    this.userCredentials = null;
  }

  private getMsdProjectId() {
    return this.dataStorageService.getDataStorage().msdProjectId;
  }
}
