import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { first, map } from 'rxjs';

import {
  RegisterNavigationService,
  RegisterVerifyProfileService,
} from '../../modules/registration/services';
import { RegisterStep } from '../../data/interfaces';

export const verifyProfileSmsGuard: CanActivateFn = () => {
  const registerNavigationService = inject(RegisterNavigationService);
  const registerVerifyProfileService = inject(RegisterVerifyProfileService);

  const isChecked = registerVerifyProfileService.getSmsVerificationStatus();

  if (isChecked === null) {
    return registerVerifyProfileService.checkSMSVerificationStatus().pipe(
      first(),
      map(({ data }) => {
        if (data) {
          registerNavigationService.goToStep(
            RegisterStep.profilePreviewSms,
            false,
          );

          return false;
        }

        return true;
      }),
    );
  }

  if (isChecked) {
    registerNavigationService.goToStep(RegisterStep.profilePreviewSms, false);

    return false;
  }

  return true;
};
