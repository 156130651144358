export enum Langs {
  en = 'en',
  de = 'de',
  es = 'es',
  fr = 'fr',
  it = 'it',
  ja = 'ja',
  pt = 'pt',
  ro = 'ro',
  tr = 'tr',
}

export enum LangTitles {
  en = 'English',
  de = 'Deutsch',
  es = 'Español',
  fr = 'Français',
  it = 'Italiano',
  ja = '日本語',
  pt = 'Português',
  ro = 'Românesc',
  tr = 'Turcă',
}

export interface LangListItem {
  code: Langs;
  title: string;
}
