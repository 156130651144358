import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { finalize, map, tap } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { AuthService, LoaderService } from '../../../core/services';
import {
  AuthProps,
  BaseResponse,
  LoginPossibleProjects,
  LoginRegisterFinished,
  LoginRegisterNotFinished,
} from '../../../data/interfaces';
import { hasObjectProperty } from '../../../data/helpers';

type Param = null | string;

type ChangePassword = LoginRegisterFinished | LoginRegisterNotFinished | [];

interface RequestPayload {
  email: string;
  project?: number;
}

@Injectable({
  providedIn: 'root',
})
export class PasswordResetService {
  private userToken: Param = null;

  private userId: Param = null;

  constructor(
    private http: HttpClient,
    private loaderService: LoaderService,
    private authService: AuthService,
  ) {}

  sendEmail(email: string, project?: number) {
    this.loaderService.showLoader();

    const requestPayload: RequestPayload = {
      email,
    };

    if (project !== undefined) {
      requestPayload.project = project;
    }

    return this.http
      .post<BaseResponse<LoginPossibleProjects | []>>(
        `${environment.NEW_HOME_API_URL_V1}/forgot_password`,
        requestPayload,
      )
      .pipe(
        map(({ data, error }) => {
          if (error) {
            return null;
          }

          if (hasObjectProperty(data, AuthProps.possibleProjects)) {
            const projectIds = data[
              AuthProps.possibleProjects as keyof typeof data
            ] as number[];

            return {
              email,
              projectIds,
            };
          }

          return null;
        }),
        finalize(() => {
          this.loaderService.hideLoader();
        }),
      );
  }

  checkQueryParams(token: string, userId: string) {
    const data = {
      usertoken: token,
      userrdid: userId,
    };

    return this.http.post<BaseResponse<[]>>(
      `${environment.HOME_API_URL}/forgotPasswordVerifyToken`,
      data,
    );
  }

  setData(token: string, userId: string) {
    this.userToken = token;
    this.userId = userId;
  }

  resetData() {
    this.userToken = null;
    this.userId = null;
  }

  changePassword(password: string) {
    this.loaderService.showLoader();

    const payload = {
      password,
      usertoken: this.userToken,
      userrdid: this.userId,
    };

    return this.http
      .post<BaseResponse<ChangePassword>>(
        `${environment.HOME_API_URL}/forgotPasswordNewReg`,
        payload,
      )
      .pipe(
        tap(({ data, error }) => {
          if (!Array.isArray(data) && !error) {
            this.resetData();
            this.authService.handleAuthResponse(data);
          }
        }),
        finalize(() => {
          this.loaderService.hideLoader();
        }),
      );
  }
}
