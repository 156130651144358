import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { FormDataInterceptor, GlobalApiErrorInterceptor } from './http';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: FormDataInterceptor, multi: true },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: GlobalApiErrorInterceptor,
    multi: true,
  },
];
