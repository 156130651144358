import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from '../../../../environments/environment';
import { SYSTEM_ROUTES } from '../../../data/constants';
import {
  DataStorageService,
  LangService,
  TrackingService,
} from '../../../core/services';
import {
  Gender,
  GenderEnum,
  RegisterDetailsData,
  RegisterDetailsFormData,
} from '../../../data/interfaces';
import { generateId } from '../../../data/helpers';
import { RegisterNavigationService } from '../services';

interface CheckEmail {
  exists: boolean;
  valid: boolean;
  message: string;
  error: boolean;
}

interface CheckUsername {
  valid: boolean;
  error: boolean;
  message?: string;
}

@Injectable({
  providedIn: 'root',
})
export class RegisterDetailsService {
  private selectedLocationLabel = this.getDefaultFormData().locationLabel;

  private detailsData: RegisterDetailsFormData =
    this.getDefaultFormData().detailsData;

  constructor(
    private http: HttpClient,
    private router: Router,
    private langService: LangService,
    private dataStorageService: DataStorageService,
    private registerNavigationService: RegisterNavigationService,
    private trackingService: TrackingService,
  ) {}

  navigateToHome() {
    this.router.navigate([SYSTEM_ROUTES.registration], {
      queryParamsHandling: 'preserve',
    });
  }

  getFormData() {
    return this.detailsData;
  }

  updateDetailsData(data: Partial<RegisterDetailsFormData>) {
    this.detailsData = { ...this.detailsData, ...data };
  }

  checkEmail(email: string) {
    const data = {
      email,
      lang: this.langService.getCurrentLang(),
      project: this.dataStorageService.getDataStorage().msdProjectId,
    };

    return this.http.post<CheckEmail>(
      `${environment.HOME_API_URL}/checkuseremail`,
      data,
    );
  }

  checkUsername(pseudonym: string) {
    const { msdProjectId } = this.dataStorageService.getDataStorage();
    const lang = this.langService.getCurrentLang();
    const requestPayload = {
      pseudonym,
      lang,
      project: msdProjectId,
    };

    return this.http.post<CheckUsername>(
      `${environment.HOME_API_URL}/checkusername`,
      requestPayload,
    );
  }

  isValidLocation() {
    const { city, latitude, longitude, country } = this.detailsData;

    return !!(city && latitude && longitude && country);
  }

  getData(): RegisterDetailsData {
    const {
      latitude,
      longitude,
      country,
      city,
      conditions,
      dateOfBirth,
      password,
      email,
      promoCode,
      recaptcha,
    } = this.getFormData();

    const { referrer, adwords } = this.trackingService.getData();
    const { loginsite, msdProjectId, gender } =
      this.dataStorageService.getDataStorage();

    return {
      gui_lang: this.langService.getCurrentLang(),
      zipcode: '', // TODO It has to be fixed sometime
      legaltxt: `${conditions}`, // TODO It has to be fixed sometime
      search_user_city_google_reg: city, // TODO It has to be fixed sometime
      birthday: dateOfBirth,
      pseudonym: `MSD${generateId(40)}`,
      sponsor: this.parseGender(gender),
      tsep: '.', // Number separator
      dsep: ',', // Number separator
      regstep: this.registerNavigationService.getCurrentStep(),
      regsubstep: this.registerNavigationService.regSubStep,
      domain: loginsite,
      eventcode: promoCode,
      captcha: recaptcha as string,
      project: msdProjectId as number,
      latitude,
      longitude,
      country,
      city,
      password,
      email,
      adwords,
      referrer,
    };
  }

  getSelectedLocationLabel() {
    return this.selectedLocationLabel;
  }

  setSelectedLocationLabel(label: string) {
    this.selectedLocationLabel = label;
  }

  resetData() {
    const { locationLabel, detailsData } = this.getDefaultFormData();

    this.selectedLocationLabel = locationLabel;
    this.detailsData = detailsData;
  }

  private getDefaultFormData() {
    return {
      locationLabel: '',
      detailsData: {
        email: '',
        password: '',
        dateOfBirth: '',
        promoCode: '',
        conditions: false,
        city: '',
        longitude: '',
        latitude: '',
        country: '',
        searchUserCityGoogleReg: '',
        recaptcha: null,
      },
    };
  }

  // TODO I don't know why, but be careful.
  private parseGender(gender: Gender) {
    return gender?.toLowerCase() === GenderEnum.male ? '1' : '2';
  }
}
