export default {
  aincome_cont_button: 'Next',
  aincome_header: 'About you',
  aincome_header_info: 'What is your annual income?',
  app_steps: 'Step {{from}} of {{to}}',
  body_height_cont_button: 'Next',
  body_height_header: 'About you',
  body_height_header_info: 'How tall are you?',
  body_height_km_100: '148 CM',
  body_height_km_101: '149 CM',
  body_height_km_102: '150 CM',
  body_height_km_103: '151 CM',
  body_height_km_104: '152 CM',
  body_height_km_105: '153 CM',
  body_height_km_106: '154 CM',
  body_height_km_107: '155 CM',
  body_height_km_108: '156 CM',
  body_height_km_109: '157 CM',
  body_height_km_110: '158 CM',
  body_height_km_111: '159 CM',
  body_height_km_112: '160 CM',
  body_height_km_113: '161 CM',
  body_height_km_114: '162 CM',
  body_height_km_115: '163 CM',
  body_height_km_116: '164 CM',
  body_height_km_117: '165 CM',
  body_height_km_118: '166 CM',
  body_height_km_119: '167 CM',
  body_height_km_120: '168 CM',
  body_height_km_121: '169 CM',
  body_height_km_122: '170 CM',
  body_height_km_123: '171 CM',
  body_height_km_124: '172 CM',
  body_height_km_125: '173 CM',
  body_height_km_126: '174CM',
  body_height_km_127: '175 CM',
  body_height_km_128: '176 CM',
  body_height_km_129: '177 CM',
  body_height_km_130: '178 CM',
  body_height_km_131: '179 CM',
  body_height_km_132: '180 CM',
  body_height_km_133: '181 CM',
  body_height_km_134: '182 CM',
  body_height_km_135: '183 CM',
  body_height_km_136: '184 CM',
  body_height_km_137: '185 CM',
  body_height_km_138: '186 CM',
  body_height_km_139: '187 CM',
  body_height_km_140: '188 CM',
  body_height_km_141: '189 CM',
  body_height_km_142: '190 CM',
  body_height_km_143: '191 CM',
  body_height_km_144: '192 CM',
  body_height_km_145: '193 CM',
  body_height_km_146: '194 CM',
  body_height_km_147: '195 CM',
  body_height_km_148: '196 CM',
  body_height_km_149: '197 CM',
  body_height_km_150: '198 CM',
  body_height_km_151: '199 CM',
  body_height_km_152: '200 CM',
  body_height_km_153: '201 CM',
  body_height_km_154: '202 CM',
  body_height_km_155: '203 CM',
  body_height_km_156: '204 CM',
  body_height_km_157: '205 CM',
  body_height_km_158: '206 CM',
  body_height_km_159: '207 CM',
  body_height_km_160: '208 CM',
  body_height_km_161: '209 CM',
  body_height_km_162: '210 CM',
  body_height_km_163: '> 210 CM',
  body_height_km_91: '< 140 CM',
  body_height_km_92: '140 CM',
  body_height_km_93: '141 CM',
  body_height_km_94: '142 CM',
  body_height_km_95: '143 CM',
  body_height_km_96: '144 CM',
  body_height_km_97: '145 CM',
  body_height_km_98: '146 CM',
  body_height_km_99: '147 CM',
  body_height_mi_101: '4\' 11"',
  body_height_mi_104: '5\' 0"',
  body_height_mi_106: '5\' 1"',
  body_height_mi_109: '5\' 2"',
  body_height_mi_112: '5\' 3"',
  body_height_mi_114: '5\' 4"',
  body_height_mi_117: '5\' 5"',
  body_height_mi_119: '5\' 6"',
  body_height_mi_122: '5\' 7"',
  body_height_mi_124: '5\' 8"',
  body_height_mi_127: '5\' 9"',
  body_height_mi_129: '5\' 10"',
  body_height_mi_132: '5\' 11"',
  body_height_mi_134: '6\' 0"',
  body_height_mi_137: '6\' 1"',
  body_height_mi_139: '6\' 2"',
  body_height_mi_142: '6\' 3"',
  body_height_mi_145: '6\' 4"',
  body_height_mi_147: '6\' 5"',
  body_height_mi_150: '6\' 6"',
  body_height_mi_152: '6\' 7"',
  body_height_mi_155: '6\' 8"',
  body_height_mi_157: '6\' 9"',
  body_height_mi_160: '6\' 10"',
  body_height_mi_162: '6\' 11"',
  body_height_mi_163: '> 6\' 11"',
  body_height_mi_91: '< 4\' 8"',
  body_height_mi_94: '4\' 8"',
  body_height_mi_96: '4\' 9"',
  body_height_mi_99: '4\' 10"',
  body_type_cont_button: 'Next',
  body_type_header: 'About you',
  body_type_header_info: 'What is your figure?',
  char_body: 'Body type',
  char_children: 'Kids',
  char_ethnicity: 'Ethnicity',
  char_eye_color: 'Eye color',
  char_hair_color: 'Hair color',
  char_header: 'Your profile',
  char_height: 'Height',
  char_my_height: 'My height',
  char_my_height_bigger: 'or taller',
  char_my_height_smaller: 'or shorter',
  char_smoker: 'Smoker',
  children_cont_button: 'Next',
  children_header: 'About you',
  children_header_info: 'Do you have children?',
  contactwish_answer_more_q: 'Great! Please answer one more question',
  contactwish_answer_q_ph: 'Your answer ...',
  contactwish_cwish: 'Date preferences',
  contactwish_flirttext: 'Flirt message',
  contactwish_header: 'Contact preferences',
  contactwish_header_info:
    'Show your personality through a unique flirt message',
  contactwish_input_ph: 'Your flirt message ...',
  contactwish_more_about_you: 'Tell more about yourself',
  contactwish_questions: 'Questions',
  contactwish_save_btn: 'Save',
  contactwish_selec_ph: 'Select a question',
  contactwish_what_you_search: 'What are you looking for here?',
  cwish_cont_button: 'Next',
  cwish_header: 'About you',
  cwish_header_info: 'What are you looking for here?',
  db_1: 'None',
  db_10: 'Concerts',
  db_11: 'Theatre',
  db_12: 'Shopping',
  db_13: 'Music',
  db_14: 'Art',
  db_15: 'Going Out',
  db_16: 'Drawing',
  db_164: 'athletic',
  db_165: 'slim',
  db_166: 'muscular',
  db_167: 'a few extra pounds',
  db_168: 'I will tell you later',
  db_169: 'German',
  db_17: 'Cinema',
  db_170: 'Italian',
  db_171: 'French',
  db_172: 'Spanish',
  db_173: 'Portuguese',
  db_174: 'English',
  db_175: 'Russian',
  db_176: 'Tennis',
  db_177: 'Golf',
  db_178: 'Sailing',
  db_179: 'Soccer',
  db_18: 'Sports on TV',
  db_180: 'Rowing',
  db_181: 'Dancing',
  db_182: 'Judo',
  db_183: 'Riding',
  db_184: 'Badminton',
  db_185: 'Fitness',
  db_186: 'Table Tennis',
  db_187: 'Jogging',
  db_188: 'Skiing',
  db_189: 'Handball',
  db_19: 'Dining out',
  db_190: 'Hockey',
  db_191: 'Biking',
  db_192: 'Karate',
  db_193: 'Swimming',
  db_194: 'Flirt',
  db_195: 'Erotic contact',
  db_198: 'Relationship',
  db_2: '1',
  db_20: 'Sports',
  db_204: 'SB / SD Relationship',
  db_206: 'Dining / Shopping',
  db_207: 'Asian',
  db_208: 'Black / African American',
  db_209: 'Latin / Hispanic',
  db_21: 'Bars',
  db_210: 'Indian',
  db_211: 'Middle Eastern',
  db_212: 'Mixed',
  db_213: 'White / European',
  db_214: 'Other',
  db_216: 'Polish',
  db_217: 'Hunting',
  db_218: 'Dutch',
  db_219: 'Turkish',
  db_22: 'Cooking',
  db_220: 'Ukrainian',
  db_221: 'Hungarian',
  db_222: 'Greek',
  db_223: 'Czech',
  db_224: 'Bulgarian',
  db_225: 'Swedish',
  db_226: 'Danish',
  db_227: 'Norwegian',
  db_228: 'Finnish',
  db_229: 'Chinese',
  db_23: 'Hiking',
  db_230: 'Yoga',
  db_231: 'Pilates',
  db_232: 'Tai Chi',
  db_233: 'Surfing',
  db_234: 'Beach Volleyball',
  db_235: 'Climbing',
  db_236: 'Kitesurfing',
  db_237: 'In-line Skating',
  db_238: 'Mountainbiking',
  db_239: 'Snowboarding',
  db_24: 'Reading',
  db_240: 'Basketball',
  db_241: 'Baseball',
  db_242: 'Football',
  db_243: 'Pole Dance',
  db_244: 'Zumba',
  db_245: 'Ballet',
  db_246: 'Consultant',
  db_247: 'Manager',
  db_248: 'Senior Staff',
  db_249: 'Auditor',
  db_25: 'Cars',
  db_250: 'Tax Consultant',
  db_251: 'Computer Scientist',
  db_252: 'Engineer',
  db_253: 'Pharmacist',
  db_254: 'Teacher',
  db_255: 'Scientist',
  db_256: 'Teacher',
  db_257: 'Scientist',
  db_258: 'Lawyer',
  db_259: 'Doctor',
  db_26: 'Travelling',
  db_260: 'Sportsman',
  db_261: 'Trainer',
  db_262: 'Secretary',
  db_263: 'Educator',
  db_264: 'Beautician',
  db_265: 'Hairdresser',
  db_266: 'Make-up Artist',
  db_267: 'Physiotherapist',
  db_268: 'Masseur',
  db_269: 'Model',
  db_27: 'Painting',
  db_270: 'Cook',
  db_271: 'Florist',
  db_272: 'Journalist',
  db_273: 'Actress',
  db_274: 'Gardening',
  db_275: 'Sauna',
  db_276: 'Flying',
  db_277: 'Motorcycle',
  db_278: 'Photography',
  db_279: 'Engineer',
  db_28: 'less than {{amount}}',
  db_280: 'Arabic',
  db_281: 'other',
  db_282: 'other',
  db_283: 'other',
  db_284: 'other',
  db_285: 'other',
  db_286: 'Racquetball',
  db_287: 'other',
  db_29: '{{amount_min}} - {{amount_max}}',
  db_3: '2',
  db_30: '{{amount_min}} - {{amount_max}}',
  db_31: '{{amount_min}} - {{amount_max}}',
  db_32: 'more than {{amount}}',
  db_33: 'I rather keep this to myself',
  db_34: 'enough',
  db_35: 'blue',
  db_36: 'green',
  db_37: 'brown',
  db_38: 'gray',
  db_39: 'brown',
  db_4: '3',
  db_40: 'black',
  db_41: 'red',
  db_42: 'silver',
  db_43: 'white',
  db_44: 'light blond',
  db_45: 'dark blond',
  db_5: '4',
  db_51: 'less than {{amount}}',
  db_52: '{{amount_min}} - {{amount_max}}',
  db_53: '{{amount_min}} - {{amount_max}}',
  db_54: '{{amount_min}} - {{amount_max}}',
  db_55: 'more than {{amount}}',
  db_56: 'I rather keep this to myself',
  db_57: 'enough',
  db_58: 'Producer',
  db_59: 'Lawyer',
  db_6: '4+',
  db_60: 'Entrepreneur',
  db_61: 'Architect',
  db_62: 'Television Star',
  db_63: 'Professional Sportsman',
  db_64: 'Self employed',
  db_65: 'Bon Vivant',
  db_66: 'Gourmet',
  db_67: 'Artist',
  db_68: 'Banker',
  db_69: 'Doctor',
  db_7: 'Yes',
  db_70: 'Pilot',
  db_71: 'Consultant',
  db_72: 'Investmentbanker',
  db_73: 'Other',
  db_74: 'Management Consultant',
  db_75: 'Asset Manager',
  db_76: 'Nurse',
  db_77: 'Flight Attendant',
  db_78: 'Banking Clerk',
  db_79: 'Pharmacist',
  db_8: 'No',
  db_81: 'Artist',
  db_83: 'Manager',
  db_84: 'Alumna',
  db_85: 'Assistant',
  db_86: 'Doctors Assistant',
  db_87: 'Student',
  db_89: 'Photographer',
  db_9: 'Sometimes',
  db_90: 'Trainee',
  db_profile_question_female_2: 'This is the future I dream of.',
  db_profile_question_female_3: 'How I imagine the perfect night.',
  db_profile_question_female_4: 'What did you always want to do?',
  db_profile_question_female_5: 'How can someone fascinate you?',
  db_profile_question_female_6: 'What are your dreams?',
  db_profile_question_female_7: 'What do you like?',
  db_profile_question_male_2: 'This is how I imagine a wonderful night to be.',
  db_profile_question_male_3:
    'This is something else you should know about me.',
  db_profile_question_male_4: 'This is how you could surprise me.',
  db_profile_question_male_5: "I've always wanted to do this someday.",
  ethnic_group_cont_button: 'Next',
  ethnic_group_header: 'About you',
  ethnic_group_header_info: 'What is your ethnicity?',
  eye_color_cont_button: 'Next',
  eye_color_header: 'About you',
  eye_color_header_info: 'What eye color do you have?',
  flirt_text_cont_button: 'Next',
  flirt_text_header: 'About you',
  flirt_text_header_info: 'Tell the community about yourself',
  hair_color_cont_button: 'Next',
  hair_color_header: 'About you',
  hair_color_header_info: 'What is your hair color?',
  into_community_btn: 'Enter the community',
  job_cont_button: 'Next',
  job_header: 'About you',
  job_header_info: 'What is your profession?',
  languages_cont_button: 'Next',
  languages_header: 'About you',
  languages_header_info: 'What languages do you speak?',
  left_part_header: 'Live your own<br>My Gay Sugar Daddy Lifestyle',
  lifestyle_header: 'Your lifestyle',
  lifestyle_nav_activity: 'Free time',
  lifestyle_nav_annual_income: 'Yearly income',
  lifestyle_nav_job: 'Profession',
  lifestyle_nav_lang: 'Languages',
  lifestyle_nav_mbudget: 'Monthly budget',
  lifestyle_nav_pproperty: 'Private assets',
  lifestyle_nav_sport: 'Sports',
  lifestyle1_cont_button: 'Next',
  lifestyle1_header: 'About you',
  lifestyle1_header_info: 'What do you do in your free time?',
  login_check_stay_login: 'Remain logged in',
  login_err_invalid_mail_or_pw: 'Please check your username and password',
  login_err_mail_unknow: 'Unknown email',
  login_err_no_mail: 'An email must be entered',
  login_err_no_pw: 'You must provide a password',
  login_forgot_pw: 'Forgot password?',
  login_header: 'Welcome back',
  login_input_mail_ph: 'Username/email',
  login_input_pw_ph: 'Password',
  login_login_btn: 'Login',
  login_login_fb: 'Log in with Facebook',
  login_logout_header: '... You are logged out.',
  login_no_acc: "Don't you have an account yet?",
  login_project_header: 'Which community would you like to join?',
  login_project_header_info:
    'Please tell us your selection so that we can show you in the right community.',
  login_project_id_0_desc: 'Sugar dating in its classic form',
  login_project_id_0_title: 'MSD classic',
  login_project_id_1_desc: 'Sugar dating for the LGBTQ+ community',
  login_project_id_1_title: 'MSD gay',
  login_project_id_2_desc: 'Sugar dating with strong, independent women',
  login_project_id_2_title: 'MSD mamas',
  login_project_validation_text: 'Please select where you want to log in',
  login_reg: 'Register now',
  login_use_email: 'Login with email',
  login_want_reg: 'Would you like to register?',
  maintenance_message:
    'The website is currently under maintenance. We apologize for the inconvenience.',
  nav_back_button: 'Back',
  nav_next_button: 'Forward',
  partner_role_sugarbabe: 'Sugarbabe',
  partner_role_sugarboy: 'Sugarboy',
  partner_role_sugardaddy: 'Sugardaddy',
  partner_role_sugarmom: 'Sugarmom',
  pimage_cont_button: 'Next',
  pimage_edit_image_btn: 'Edit',
  pimage_from: 'from',
  pimage_header: 'Your profile picture',
  pimage_header_desc: 'Show the best of you',
  pimage_header_info: 'Expand your profile, show your best self',
  pimage_modal_close_btn: 'Close',
  pimage_modal_header: 'Upload photo',
  pimage_modal_save_btn: 'Save',
  pimage_prev_cont_button: 'Next',
  pimage_prev_header: 'About you',
  pimage_prev_header_desc: 'Beautiful!',
  pimage_prev_header_info: 'The high class lifestyle is waiting for you!',
  pimage_prev_sms_cont_button: 'Join the community, Happy Hunting!',
  pimage_prev_sms_edit_button: 'Or complete your profile',
  pimage_prev_sms_header: 'Security',
  pimage_prev_sms_header_desc: 'Wonderful! You are now verified',
  pimage_up_image_btn: 'Upload a photo now',
  pproperty_cont_button: 'Next',
  pproperty_header: 'About you',
  pproperty_header_info: 'How much do you earn?',
  pw_reset_btn: 'Next',
  pw_reset_desc:
    'Enter the email address that you have used to log in. You will then immediately receive an email for the next steps.',
  pw_reset_err_no_mail: 'Please specify your email',
  pw_reset_header: 'Security',
  pw_reset_input_mail_ph: 'E-Mail',
  pw_reset_success_mail:
    'An email with the next steps has been sent. Please check your spam folder if you did not receive the email immediately.',
  pw_rt_btn: 'Confirm password',
  pw_rt_desc:
    'Please enter a new password and then confirm your password. If you have any problems please contact support@mygaysugardaddy.eu',
  pw_rt_header: 'Security',
  pw_rt_input_password_conf_ph: 'Repeat password',
  pw_rt_input_password_equal_err: 'Passwords must match',
  pw_rt_input_password_len_err:
    'Your password must be between 6 and 20 characters long.',
  pw_rt_input_password_no_pw_err: 'Please enter a password',
  pw_rt_input_password_ph: 'Password',
  questions_cont_button: 'Next',
  questions_header: 'About you',
  questions_header_info: 'Some questions you may want to answer',
  reg_already_member: 'Are you already a member?',
  reg_err_select_gender: 'Please indicate your sex',
  reg_finished_1_success_msg: 'Nice Job!',
  reg_finished_2_success_msg: 'You have completed setting up your profile',
  reg_finished_cont_button: 'Happy Hunting!',
  reg_finished_header: 'Profile',
  reg_header: 'Register now for free',
  reg_header_info: 'I am a',
  reg_header_info_hide: 'I am ...',
  reg_login: 'Login',
  reg_or: 'or',
  reg_prob_modal_body:
    'Unfortunately, there is a problem with your account. Please contact our Support Team so we can help you.',
  reg_prob_modal_close: 'Close',
  reg_prob_modal_header: 'Problem with your account',
  reg_sugarbabe: 'Sugar boy',
  reg_sugarbabe_hide: 'Female',
  reg_sugardaddy: 'Sugar daddy',
  reg_sugardaddy_hide: 'Male',
  reg_with_apple: 'Register with Apple',
  reg_with_email: 'Register with your email',
  reg_with_facebook: 'Register with Facebook',
  reg_with_google: 'Register with Google',
  regd_18_error: 'You are under 18',
  regd_accept_agb:
    "By registering, you agree to MyGaySugardaddy's <a href='https://www.mygaysugardaddy.com/privacy-policy' target='_blank'>Privacy Policy</a> and <a href='https://www.mygaysugardaddy.com/terms-and-conditions' target='_blank'>Terms of Use</a> and confirm that you are over 18 years of age.",
  regd_city_ph: 'City',
  regd_email_ph: 'Email',
  regd_err_accept_agb: 'You must accept our Terms and Conditions',
  regd_err_add_loc: 'You must provide a location',
  regd_err_add_pw: 'Your password must be between 6 and 20 characters long.',
  regd_err_add_username: 'You must choose a pseudonym',
  regd_err_mail_locked: 'This email is already in use',
  regd_err_no_lat_lng: 'Location not found. Please select a different one.',
  regd_err_no_mail: 'You must provide an email',
  regd_err_recaptcha: 'The captcha must be solved',
  regd_eventcode_ph: 'Promocode',
  regd_header_info: 'Make your dreams come true!',
  regd_label_birthday: 'Date of birth',
  regd_label_eventcode: 'Do you have a promocode?',
  regd_modal_btn_babe: 'I am a: sugar boy',
  regd_modal_btn_daddy: 'I am a: sugar daddy',
  regd_modal_conf_text:
    'If it is right, click "register now for free". <br> You can change your information here if it is wrong',
  regd_modal_content:
    '<p>Are you a <b>{{iam}}</b> ?</p><p> And are you looking for a <b>{{isearch}}</b> ?</p>',
  regd_modal_header: 'Please check your information again',
  regd_modal_wrong_search: 'Is the statement above wrong?',
  regd_months_1: 'January',
  regd_months_10: 'October',
  regd_months_11: 'November',
  regd_months_12: 'December',
  regd_months_2: 'February',
  regd_months_3: 'March',
  regd_months_4: 'April',
  regd_months_5: 'May',
  regd_months_6: 'June',
  regd_months_7: 'July',
  regd_months_8: 'August',
  regd_months_9: 'September',
  regd_password_ph: 'Password',
  regd_reg_button: 'Register now for free',
  regd_select_gender: 'Indicate your character ...',
  regd_select_gender_err: 'Indicate your character',
  regd_select_gender_f: 'Male (Sugar Boy)',
  regd_select_gender_m: 'Male (Sugar Daddy)',
  regd_username_ph: 'Pseudonym',
  regd_want_login: 'Would you like to log in?',
  register_partner_role_list_default_text: 'Please select',
  register_partner_role_list_label: 'I am looking for ...',
  register_partner_role_validation_text:
    'Please specify who you are looking for.',
  smoker_cont_button: 'Next',
  smoker_header: 'About you',
  smoker_header_info: 'Are you a smoker?',
  sports_cont_button: 'Next',
  sports_header: 'About you',
  sports_header_info: 'What sports are you interested in?',
  summary_edit_mail_btn: 'Change email',
  summary_edit_profile_btn: 'Or continue filling your profile',
  summary_go_to_community_btn: 'Start flirting now',
  summary_header: 'Security',
  summary_header_desc: 'It’s time for a new time! Welcome to MyGaySugardaddy',
  summary_mail_info:
    '<p>We have sent your email address</p><p><b>{{mail}}</b></p><p> a verification link.</p>',
  summary_mail_repeat:
    '<p>Please check your spam folder. If you are still having problems with your registration, our support team will be happy to help you at</p><p><span>support@mygaysugardaddy.eu</span></p>',
  summary_modal_body: 'You can change your email here',
  summary_modal_close: 'Close',
  summary_modal_header: 'Change email',
  summary_modal_save_send_btn: 'Save & send',
  summary_new_veri_mail: 'New verification email sent successfully',
  summary_not_received_btn: "Haven't you received the email yet?",
  summary_resend_mail_btn: 'Resend email',
  summary_sms_btn: 'Verify now via SMS',
  summary_sms_info: 'You can also verify yourself with your phone number',
  summary_sms_info_success: 'Show that you can be trusted',
  summary_success_header: 'Done!',
  summary_success_msg: 'Great!',
  summary_veri_mail_success:
    'Your email address has been successfully verified',
  summary_wrong_mail_info: 'Have you entered the wrong email address?',
  username_cont_button: 'Next',
  username_header: 'About you',
  username_header_info: 'What do you want to be called?',
  username_suggestions: 'Here are a few suggestions',
  verif_prof_cont_button: 'Next',
  verif_prof_explain_info: 'The high class lifestyle is waiting for you!',
  verif_prof_header: 'Security',
  verif_prof_header_desc: 'Verify your profile!',
  verif_prof_header_info: 'With SMS verification, solidify your profile',
  verif_prof_verf_email: 'Verify with email',
  verif_prof_verf_sms: 'Verify with SMS now',
  verify_phone_c_code_sent_info:
    'We have sent the code to your phone number <b>{{phonenumber}}</b>. This process may take up a min.',
  verify_phone_c_cont_button: 'Confirm code',
  verify_phone_c_error_empty: 'Please enter the 6-digit code',
  verify_phone_c_error_resends_limit:
    'Something went wrong, please contact support: support@mygaysugardaddy.eu',
  verify_phone_c_error_wrong: 'The entered code is not correct',
  verify_phone_c_header: 'Security',
  verify_phone_c_header_info: 'Verify your profile',
  verify_phone_c_intro: 'Please enter your verification code',
  verify_phone_c_resend_success: 'A new code was successfully sent',
  verify_phone_c_retry_btn: 'Resend code',
  verify_phone_codeform_error_empty: 'Please enter your phone number',
  verify_phone_codeform_error_invalid: 'Please enter a valid phone number',
  verify_phone_codeform_nocorrectphonenumbers_err:
    'Your phone number is not correct',
  verify_phone_cont_button: 'Verify your profile',
  verify_phone_header: 'Security',
  verify_phone_header_info: 'Verify your profile',
  verify_phone_input_txt:
    'You will only receive a SMS verification, we do not spam',
  verify_phone_intro: 'Show you are a verified member!',
};
