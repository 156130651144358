export default {
  aincome_cont_button: 'Continuar',
  aincome_header: 'Sobre você',
  aincome_header_info: 'Qual é sua renda anual?',
  app_steps: 'Passo {{from}} de {{to}}',
  body_height_cont_button: 'Continuar',
  body_height_header: 'Sobre você',
  body_height_header_info: 'Qual é a sua altura?',
  body_height_km_100: '148 CM',
  body_height_km_101: '149 CM',
  body_height_km_102: '150 CM',
  body_height_km_103: '151 CM',
  body_height_km_104: '152 CM',
  body_height_km_105: '153 CM',
  body_height_km_106: '154 CM',
  body_height_km_107: '155 CM',
  body_height_km_108: '156 CM',
  body_height_km_109: '157 CM',
  body_height_km_110: '158 CM',
  body_height_km_111: '159 CM',
  body_height_km_112: '160 CM',
  body_height_km_113: '161 CM',
  body_height_km_114: '162 CM',
  body_height_km_115: '163 CM',
  body_height_km_116: '164 CM',
  body_height_km_117: '165 CM',
  body_height_km_118: '166 CM',
  body_height_km_119: '167 CM',
  body_height_km_120: '168 CM',
  body_height_km_121: '169 CM',
  body_height_km_122: '170 CM',
  body_height_km_123: '171 CM',
  body_height_km_124: '172 CM',
  body_height_km_125: '173 CM',
  body_height_km_126: '174CM',
  body_height_km_127: '175 CM',
  body_height_km_128: '176 CM',
  body_height_km_129: '177 CM',
  body_height_km_130: '178 CM',
  body_height_km_131: '179 CM',
  body_height_km_132: '180 CM',
  body_height_km_133: '181 CM',
  body_height_km_134: '182 CM',
  body_height_km_135: '183 CM',
  body_height_km_136: '184 CM',
  body_height_km_137: '185 CM',
  body_height_km_138: '186 CM',
  body_height_km_139: '187 CM',
  body_height_km_140: '188 CM',
  body_height_km_141: '189 CM',
  body_height_km_142: '190 CM',
  body_height_km_143: '191 CM',
  body_height_km_144: '192 CM',
  body_height_km_145: '193 CM',
  body_height_km_146: '194 CM',
  body_height_km_147: '195 CM',
  body_height_km_148: '196 CM',
  body_height_km_149: '197 CM',
  body_height_km_150: '198 CM',
  body_height_km_151: '199 CM',
  body_height_km_152: '200 CM',
  body_height_km_153: '201 CM',
  body_height_km_154: '202 CM',
  body_height_km_155: '203 CM',
  body_height_km_156: '204 CM',
  body_height_km_157: '205 CM',
  body_height_km_158: '206 CM',
  body_height_km_159: '207 CM',
  body_height_km_160: '208 CM',
  body_height_km_161: '209 CM',
  body_height_km_162: '210 CM',
  body_height_km_163: '> 210 CM',
  body_height_km_91: '< 140 CM',
  body_height_km_92: '140 CM',
  body_height_km_93: '141 CM',
  body_height_km_94: '142 CM',
  body_height_km_95: '143 CM',
  body_height_km_96: '144 CM',
  body_height_km_97: '145 CM',
  body_height_km_98: '146 CM',
  body_height_km_99: '147 CM',
  body_height_mi_101: '4\' 11"',
  body_height_mi_104: '5\' 0"',
  body_height_mi_106: '5\' 1"',
  body_height_mi_109: '5\' 2"',
  body_height_mi_112: '5\' 3"',
  body_height_mi_114: '5\' 4"',
  body_height_mi_117: '5\' 5"',
  body_height_mi_119: '5\' 6"',
  body_height_mi_122: '5\' 7"',
  body_height_mi_124: '5\' 8"',
  body_height_mi_127: '5\' 9"',
  body_height_mi_129: '5\' 10"',
  body_height_mi_132: '5\' 11"',
  body_height_mi_134: '6\' 0"',
  body_height_mi_137: '6\' 1"',
  body_height_mi_139: '6\' 2"',
  body_height_mi_142: '6\' 3"',
  body_height_mi_145: '6\' 4"',
  body_height_mi_147: '6\' 5"',
  body_height_mi_150: '6\' 6"',
  body_height_mi_152: '6\' 7"',
  body_height_mi_155: '6\' 8"',
  body_height_mi_157: '6\' 9"',
  body_height_mi_160: '6\' 10"',
  body_height_mi_162: '6\' 11"',
  body_height_mi_163: '> 6\' 11"',
  body_height_mi_91: '< 4\' 8"',
  body_height_mi_94: '4\' 8"',
  body_height_mi_96: '4\' 9"',
  body_height_mi_99: '4\' 10"',
  body_type_cont_button: 'Continuar',
  body_type_header: 'Sobre você',
  body_type_header_info: 'Qual é o seu formato do seu corpo?',
  char_body: 'Tipo físico',
  char_children: 'Filhos',
  char_ethnicity: 'Etnia',
  char_eye_color: 'Cor dos olhos',
  char_hair_color: 'Cor dos cabelos',
  char_header: 'Seu perfil',
  char_height: 'Altura',
  char_my_height: 'Minha altura',
  char_my_height_bigger: 'ou maior',
  char_my_height_smaller: 'ou menor',
  char_smoker: 'Fumante',
  children_cont_button: 'Continuar',
  children_header: 'Sobre você',
  children_header_info: 'Você tem filhos?',
  contactwish_answer_more_q: 'Muito bom! Responda mais uma pergunta',
  contactwish_answer_q_ph: 'Sua resposta...',
  contactwish_cwish: 'Tipo de relação desejada',
  contactwish_flirttext: 'Mensagem de sedução',
  contactwish_header: 'O que procura?',
  contactwish_header_info:
    'Mostre sua personalidade e escreva uma mensagem de sedução única.',
  contactwish_input_ph: 'Sua mensagem de sedução...',
  contactwish_more_about_you: 'Fale mais sobre você',
  contactwish_questions: 'Perguntas',
  contactwish_save_btn: 'Salvar',
  contactwish_selec_ph: 'Selecione uma pergunta',
  contactwish_what_you_search: 'Descreva o que você está buscando',
  cwish_cont_button: 'Continuar',
  cwish_header: 'Sobre você',
  cwish_header_info: 'O que está procurando?',
  db_1: 'Nenhum',
  db_10: 'Concertos',
  db_11: 'Teatro',
  db_12: 'Compras',
  db_13: 'Música',
  db_14: 'Arte',
  db_15: 'Saindo',
  db_16: 'Desenho',
  db_164: 'atlético',
  db_165: 'esbelto',
  db_166: 'muscular',
  db_167: 'alguns quilos a mais',
  db_168: 'Contar-vos-ei mais tarde',
  db_169: 'Alemão',
  db_17: 'Cinema',
  db_170: 'Italiano',
  db_171: 'Francês',
  db_172: 'Espanhol',
  db_173: 'Português',
  db_174: 'Inglês',
  db_175: 'Russo',
  db_176: 'Ténis',
  db_177: 'Golfe',
  db_178: 'Vela',
  db_179: 'Futebol',
  db_18: 'Desporto na TV',
  db_180: 'Remo',
  db_181: 'Dança',
  db_182: 'Judo',
  db_183: 'Equitação',
  db_184: 'Badminton',
  db_185: 'Aptidão física',
  db_186: 'Ténis de mesa',
  db_187: 'Jogging',
  db_188: 'Esqui',
  db_189: 'Andebol',
  db_19: 'Jantar fora',
  db_190: 'Hóquei',
  db_191: 'Bicicleta',
  db_192: 'Karate',
  db_193: 'Natação',
  db_194: 'Flirt',
  db_195: 'Contacto erótico',
  db_198: 'Relação',
  db_2: '1',
  db_20: 'Desporto',
  db_204: 'Relação SB / SD',
  db_206: 'Jantar / Compras',
  db_207: 'Asiático',
  db_208: 'Negro / Afro-Americano',
  db_209: 'Latim / Hispânico',
  db_21: 'Bares',
  db_210: 'Índio',
  db_211: 'Médio Oriente',
  db_212: 'Misto',
  db_213: 'Branco / Europeu',
  db_214: 'Outros',
  db_216: 'Polaco',
  db_217: 'A caça',
  db_218: 'Holandês',
  db_219: 'Turco',
  db_22: 'Cozinhar',
  db_220: 'Ucraniano',
  db_221: 'Húngaro',
  db_222: 'Grego',
  db_223: 'Checo',
  db_224: 'Búlgaro',
  db_225: 'Sueco',
  db_226: 'Dinamarquês',
  db_227: 'Norueguês',
  db_228: 'Finlandês',
  db_229: 'Chinês',
  db_23: 'Caminhadas',
  db_230: 'Yoga',
  db_231: 'Pilates',
  db_232: 'Tai Chi',
  db_233: 'Navegação',
  db_234: 'Voleibol de Praia',
  db_235: 'Escalada',
  db_236: 'Kitesurfing',
  db_237: 'Patinagem em linha',
  db_238: 'Mountainbiking',
  db_239: 'Snowboarding',
  db_24: 'Leitura',
  db_240: 'Basquetebol',
  db_241: 'Basebol',
  db_242: 'Futebol',
  db_243: 'Dança do Polo',
  db_244: 'Zumba',
  db_245: 'Ballet',
  db_246: 'Consultor',
  db_247: 'Gerente',
  db_248: 'Pessoal Sénior',
  db_249: 'Auditor',
  db_25: 'Automóveis',
  db_250: 'Consultor fiscal',
  db_251: 'Cientista informático',
  db_252: 'Engenheiro',
  db_253: 'Pharmacist',
  db_254: 'Professor',
  db_255: 'Cientista',
  db_256: 'Professor',
  db_257: 'Cientistas',
  db_258: 'Advogado',
  db_259: 'Doutor',
  db_26: 'Viajando',
  db_260: 'Desportista',
  db_261: 'Formador',
  db_262: 'Secretário',
  db_263: 'Educador',
  db_264: 'Esteticista',
  db_265: 'Cabeleireiro',
  db_266: 'Artista de maquilhagem',
  db_267: 'Fisioterapeuta',
  db_268: 'Masseuse',
  db_269: 'Modelo',
  db_27: 'Pintura',
  db_270: 'Cozinheiro',
  db_271: 'Florista',
  db_272: 'Jornalista',
  db_273: 'Actriz',
  db_274: 'Jardinagem',
  db_275: 'Sauna',
  db_276: 'Voar',
  db_277: 'Motocicleta',
  db_278: 'Fotografia',
  db_279: 'Engenheiro',
  db_28: 'menos de {{amount}}',
  db_280: 'Arábico',
  db_281: 'outros',
  db_282: 'outros',
  db_283: 'outros',
  db_284: 'outros',
  db_285: 'outros',
  db_286: 'Racquetball',
  db_287: 'outros',
  db_29: '{{amount_min}} - {{amount_max}}',
  db_3: '2',
  db_30: '{{amount_min}} - {{amount_max}}',
  db_31: '{{amount_min}} - {{amount_max}}',
  db_32: 'mais de {{amount}}',
  db_33: 'Prefiro guardar isto para mim',
  db_34: 'suficiente',
  db_35: 'azul',
  db_36: 'verde',
  db_37: 'castanho',
  db_38: 'cinzento',
  db_39: 'castanho',
  db_4: '3',
  db_40: 'preto',
  db_41: 'vermelho',
  db_42: 'prata',
  db_43: 'branco',
  db_44: 'loira clara',
  db_45: 'loira escura',
  db_5: '4',
  db_51: 'menos de {{amount}}',
  db_52: '{{amount_min}} - {{amount_max}}',
  db_53: '{{amount_min}} - {{amount_max}}',
  db_54: '{{amount_min}} - {{amount_max}}',
  db_55: 'mais de {{amount}}',
  db_56: 'Prefiro guardar isto para mim',
  db_57: 'suficiente',
  db_58: 'Produtor',
  db_59: 'Advogado',
  db_6: '4+',
  db_60: 'Empresário',
  db_61: 'Arquitecto',
  db_62: 'Estrela de Televisão',
  db_63: 'Desportista profissional',
  db_64: 'Trabalhador independente',
  db_65: 'Bon Vivant',
  db_66: 'Gourmet',
  db_67: 'Artista',
  db_68: 'Banqueiro',
  db_69: 'Doutor',
  db_7: 'Sim',
  db_70: 'Piloto',
  db_71: 'Consultor',
  db_72: 'Banqueiro de investimento',
  db_73: 'Outros',
  db_74: 'Consultor de Gestão',
  db_75: 'Gestor de activos',
  db_76: 'enfermeiro',
  db_77: 'comissário de bordo',
  db_78: 'funcionário de banco',
  db_79: 'farmacêutico',
  db_8: 'Não',
  db_81: 'Artista',
  db_83: 'Comerciante',
  db_84: 'Graduado',
  db_85: 'Assistente',
  db_86: 'Auxiliar de enfermagem',
  db_87: 'Estudante',
  db_89: 'Fotógrafo',
  db_9: 'Por vezes',
  db_90: 'Aprendiz',
  db_profile_question_female_2: 'Este é o futuro com o qual eu sonho.',
  db_profile_question_female_3: 'Como eu imagino a noite perfeita',
  db_profile_question_female_4: 'O que você sempre quis fazer?',
  db_profile_question_female_5: 'Como alguém pode te fascinar?',
  db_profile_question_female_6: 'Quais são os seus sonhos?',
  db_profile_question_female_7: 'Do que você gosta?',
  db_profile_question_male_2: 'É assim que eu imagino uma noite maravilhosa!',
  db_profile_question_male_3:
    'Aqui está outra coisa que você deve saber sobre mim.',
  db_profile_question_male_4: 'É assim que me podem surpreender.',
  db_profile_question_male_5: 'Eu sempre quis fazer isto um dia.',
  ethnic_group_cont_button: 'Continuar',
  ethnic_group_header: 'Sobre você',
  ethnic_group_header_info: 'Etnia',
  eye_color_cont_button: 'Continuar',
  eye_color_header: 'Sobre você',
  eye_color_header_info: 'Qual é a cor dos seus olhos?',
  flirt_text_cont_button: 'Continuar',
  flirt_text_header: 'Sobre você',
  flirt_text_header_info: 'Fale um pouco sobre você',
  hair_color_cont_button: 'Continuar',
  hair_color_header: 'Sobre você',
  hair_color_header_info: 'Qual é a cor do seu cabelo?',
  into_community_btn: 'Comece a Seduzir',
  job_cont_button: 'Continuar',
  job_header: 'Sobre você',
  job_header_info: 'O que você faz profissionalmente?',
  languages_cont_button: 'Continuar',
  languages_header: 'Sobre você',
  languages_header_info: 'Quais idiomas você fala?',
  left_part_header: 'Viva seu próprio<br> MyGaySugardaddy estilo de vida',
  lifestyle_header: 'Seu estilo de vida',
  lifestyle_nav_activity: 'Tempo livre',
  lifestyle_nav_annual_income: 'Renda anual',
  lifestyle_nav_job: 'Profissão',
  lifestyle_nav_lang: 'Idiomas',
  lifestyle_nav_mbudget: 'Disponibilidade de valor mensal',
  lifestyle_nav_pproperty: 'Patrimônio privado',
  lifestyle_nav_sport: 'Esportes',
  lifestyle1_cont_button: 'Continuar',
  lifestyle1_header: 'Sobre você',
  lifestyle1_header_info: 'O que você faz no seu tempo livre?',
  login_check_stay_login: 'Me manter conectado',
  login_err_invalid_mail_or_pw:
    'Verifique o seu nome de utilizador e a sua palavra-passe',
  login_err_mail_unknow: 'E-mail desconhecido',
  login_err_no_mail: 'Um e-mail precisa ser inserido',
  login_err_no_pw: 'Você precisa inserir uma senha',
  login_forgot_pw: 'Esqueceu sua senha?',
  login_header: 'Bem-vindo',
  login_input_mail_ph: 'Nome de usuário/e-mail',
  login_input_pw_ph: 'Senha',
  login_login_btn: 'Entrar',
  login_login_fb: 'Entrar com o Facebook',
  login_logout_header: '... Agora você está desconectado.',
  login_no_acc: 'Você ainda não tem uma conta?',
  login_project_header: 'A que comunidade gostaria de aderir?',
  login_project_header_info:
    'Indique-nos a sua seleção para que o possamos colocar no bairro certo.',
  login_project_id_0_desc: 'Sugar dating na sua forma clássica',
  login_project_id_0_title: 'MSD classic',
  login_project_id_1_desc: 'Sugar dating para a comunidade LGBTQ+',
  login_project_id_1_title: 'MSD gay',
  login_project_id_2_desc:
    'Encontros sugar com mulheres fortes e independentes',
  login_project_id_2_title: 'MSD mamas',
  login_project_validation_text: 'Seleccione onde pretende iniciar sessão',
  login_reg: 'Cadastrar agora',
  login_use_email: 'Entrar com e-mail',
  login_want_reg: 'Deseja se cadastrar?',
  maintenance_message:
    'O site se encontra atualmente em manutenção. Pedimos desculpas pelo inconveniente.',
  nav_back_button: 'Voltar',
  nav_next_button: 'Continuar',
  partner_role_sugarbabe: 'Sugarbabe',
  partner_role_sugarboy: 'Sugarboy',
  partner_role_sugardaddy: 'Sugardaddy',
  partner_role_sugarmom: 'Sugarmom',
  pimage_cont_button: 'Continuar',
  pimage_edit_image_btn: 'Alterar',
  pimage_from: 'de',
  pimage_header: 'Sua foto de perfil',
  pimage_header_desc: 'Mostre o seu melhor',
  pimage_header_info: 'Aposte no seu melhor ângulo',
  pimage_modal_close_btn: 'Fechar',
  pimage_modal_header: 'Foto de perfil',
  pimage_modal_save_btn: 'Salvar',
  pimage_prev_cont_button: 'Continuar',
  pimage_prev_header: 'Sobre Você',
  pimage_prev_header_desc: 'Excelente! Este é o seu perfil',
  pimage_prev_header_info: 'Bem-vindo a um mundo cheio de possibilidades',
  pimage_prev_sms_cont_button: 'Chegou a hora de seduzir!',
  pimage_prev_sms_edit_button: 'Ou complete seu perfil',
  pimage_prev_sms_header: 'Segurança',
  pimage_prev_sms_header_desc: 'Ótimo! o seu perfil foi verificado com sucesso',
  pimage_up_image_btn: 'Inserir foto',
  pproperty_cont_button: 'Continuar',
  pproperty_header: 'Sobre você',
  pproperty_header_info: 'Quanto você recebe mensalmente?',
  pw_reset_btn: 'Continuar',
  pw_reset_desc:
    'Digite o endereço de e-mail que você utiliza para acessar o seu perfil. Você receberá imediatamente um e-mail com os próximos passos.',
  pw_reset_err_no_mail: 'O e-mail é obrigatório',
  pw_reset_header: 'Segurança',
  pw_reset_input_mail_ph: 'E-Mail',
  pw_reset_success_mail:
    'Um e-mail com os próximos passos foi enviado para seu endereço eletrônico. Se você não recebeu o e-mail imediatamente, por favor, verifique também sua pasta de spam.',
  pw_rt_btn: 'Confirmar senha',
  pw_rt_desc:
    'Por favor, digite uma nova senha e depois confirme. Se você tiver algum problema, entre em contato com support@mygaysugardaddy.eu.',
  pw_rt_header: 'Segurança',
  pw_rt_input_password_conf_ph: 'Introduza senha novamente',
  pw_rt_input_password_equal_err: 'As senhas devem ser iguais',
  pw_rt_input_password_len_err:
    'A sua palavra-passe deve ter entre 6 e 20 caracteres.',
  pw_rt_input_password_no_pw_err: 'Por favor, digite uma senha',
  pw_rt_input_password_ph: 'Senha',
  questions_cont_button: 'Continuar',
  questions_header: 'Sobre você',
  questions_header_info: 'Fale um pouco mais sobre você',
  reg_already_member: 'Já tem uma conta?',
  reg_err_select_gender: 'Qual o seu gênero?',
  reg_finished_1_success_msg: 'Pronto',
  reg_finished_2_success_msg: 'Você completou seu perfil',
  reg_finished_cont_button: 'Comece a seduzir',
  reg_finished_header: 'Perfil',
  reg_header: 'Cadastre-se grátis',
  reg_header_info: 'Eu sou um',
  reg_header_info_hide: 'Eu sou ...',
  reg_login: 'Entrar',
  reg_or: 'ou',
  reg_prob_modal_body:
    'Infelizmente, identificamos um problema em sua conta, por favor entre em contato com nossa equipe de suporte.',
  reg_prob_modal_close: 'Fechar',
  reg_prob_modal_header: 'Problema com sua conta',
  reg_sugarbabe: 'Sugar Boy',
  reg_sugarbabe_hide: 'Feminino',
  reg_sugardaddy: 'Sugar Daddy',
  reg_sugardaddy_hide: 'Masculino',
  reg_with_apple: 'Cadastre-se com Apple',
  reg_with_email: 'Cadastre-se com  e-mail',
  reg_with_facebook: 'Cadastre-se com Facebook',
  reg_with_google: 'Cadastre-se com Google',
  regd_18_error: 'Você é menor de 18 anos',
  regd_accept_agb:
    "Ao cadastrar-se você concorda com a <a href='https://www.mygaysugardaddy.com/privacy-policy' target='_blank'>Política de Privacidade</a> e  os <a href='https://www.mygaysugardaddy.com/terms-and-conditions' target='_blank'> Termos de uso</a> da MyGaySugardaddy e confirma que você é maior de 18 anos de idade.",
  regd_city_ph: 'Localização',
  regd_email_ph: 'E-mail',
  regd_err_accept_agb: 'Você precisa aceitar nossos Termos e Condições',
  regd_err_add_loc: 'Você precisa inserir uma localização',
  regd_err_add_pw: 'A sua palavra-passe deve ter entre 6 e 20 caracteres.',
  regd_err_add_username: 'Você precisa inserir um nome de usuário',
  regd_err_mail_locked:
    'Este e-mail já está sendo utilizado, por favor escolha outro.',
  regd_err_no_lat_lng:
    'Localização não encontrada, por favor escolha uma localização diferente.',
  regd_err_no_mail: 'O e-mail é obrigatório',
  regd_err_recaptcha: 'A captcha deve ser resolvida',
  regd_eventcode_ph: 'Código promocional',
  regd_header_info: 'Conquiste seus sonhos',
  regd_label_birthday: 'Data de nascimento',
  regd_label_eventcode: 'Tem um código promocional?',
  regd_modal_btn_babe: 'Eu sou um: Sugar Boy',
  regd_modal_btn_daddy: 'Eu sou um: Sugar daddy',
  regd_modal_conf_text:
    'Se está tudo correto, clique em "cadastrar grátis". <br> Aqui você pode alterar suas informações se não estiverem corretas.',
  regd_modal_content:
    '<p> Você é um(a) <b>{{iam}}</b> ? </p><p> E procura um(a) <b>{{isearch}}</b> ?</p>',
  regd_modal_header: 'Por favor verifique suas informações novamente',
  regd_modal_wrong_search: 'A informação acima não está correta?',
  regd_months_1: 'Janeiro',
  regd_months_10: 'Outubro',
  regd_months_11: 'Novembro',
  regd_months_12: 'Dezembro',
  regd_months_2: 'Fevereiro',
  regd_months_3: 'Março',
  regd_months_4: 'Abril',
  regd_months_5: 'Maio',
  regd_months_6: 'Junho',
  regd_months_7: 'Julho',
  regd_months_8: 'Agosto',
  regd_months_9: 'Setembro',
  regd_password_ph: 'Senha',
  regd_reg_button: 'Cadastre-se grátis',
  regd_select_gender: 'Escolha seu personagem ...',
  regd_select_gender_err: 'Escolha seu personagem',
  regd_select_gender_f: 'Masculino (Sugar Boy)',
  regd_select_gender_m: 'Masculino (Sugar Daddy)',
  regd_username_ph: 'Nome de usuário',
  regd_want_login: 'Você quer entrar?',
  register_partner_role_list_default_text: 'Por favor, seleccione',
  register_partner_role_list_label: 'Estou à procura de ...',
  register_partner_role_validation_text: 'Queira especificar quem procura.',
  smoker_cont_button: 'Continuar',
  smoker_header: 'Sobre você',
  smoker_header_info: 'Você fuma?',
  sports_cont_button: 'Continuar',
  sports_header: 'Sobre você',
  sports_header_info: 'Qual esporte você pratica?',
  summary_edit_mail_btn: 'Alterar e-mail',
  summary_edit_profile_btn: 'Ou continue preenchendo seu perfil',
  summary_go_to_community_btn: 'Comece a seduzir',
  summary_header: 'Segurança',
  summary_header_desc: 'Bem-vindo ao MyGaySugardady',
  summary_mail_info:
    '<p> Nós enviamos para seu endereço de E-mail</p><p><b>{{mail}}</b></p><p>um link de confirmação.',
  summary_mail_repeat:
    '<p>Você não recebeu o e-mail? Por favor verifique </p><p> também na sua pasta de Spam, se continuar tendo</p><p>problemas com seu cadastro,</p><p> nossa equipe de suporte terá o maior prazer em ajudar através do e-mail</p><p><span>support@mygaysugardaddy.eu</span></p>',
  summary_modal_body: 'Você pode alterar seu e-mail aqui',
  summary_modal_close: 'Fechar',
  summary_modal_header: 'Alterar e-mail',
  summary_modal_save_send_btn: 'Salvar e enviar',
  summary_new_veri_mail: 'Nova confirmação de e-mail enviada com sucesso',
  summary_not_received_btn: 'Você ainda não recebeu o nosso e-mail?',
  summary_resend_mail_btn: 'Reenviar e-mail',
  summary_sms_btn: 'Verifique agora por SMS',
  summary_sms_info: 'Você também pode optar por uma verificação pelo celular',
  summary_sms_info_success: 'Mostre que você é confiável e ganhe destaque',
  summary_success_header: 'Pronto',
  summary_success_msg: 'Muito bom!',
  summary_veri_mail_success:
    'Seu endereço de e-mail foi confirmado com sucesso',
  summary_wrong_mail_info: 'Você inseriu um  endereço de e-mail errado?',
  username_cont_button: 'Continuar',
  username_header: 'Sobre Você',
  username_header_info: 'Nome de usuário',
  username_suggestions: 'Sugestões',
  verif_prof_cont_button: 'Continuar',
  verif_prof_explain_info: 'Um estilo de vida exclusivo está te esperando!',
  verif_prof_header: 'Segurança',
  verif_prof_header_desc: 'Verifique seu perfil',
  verif_prof_header_info:
    'Quer ter mais chances de seduzir? Verifique o seu perfil com apenas um SMS',
  verif_prof_verf_email: 'Verificar com e-mail',
  verif_prof_verf_sms: 'Verificar agora com SMS',
  verify_phone_c_code_sent_info:
    'Enviámos-lhe o código para o seu número de telefone <b>{{phonenumber}}</b>. Este processo pode demorar até 1 minuto.',
  verify_phone_c_cont_button: 'Confirmar código',
  verify_phone_c_error_empty: 'Por favor introduza o código de 6 dígitos',
  verify_phone_c_error_resends_limit:
    'Algo deu errado, favor contatar o suporte: support@mygaysugardaddy.eu',
  verify_phone_c_error_wrong: 'O código introduzido está incorreto',
  verify_phone_c_header: 'Segurança',
  verify_phone_c_header_info: 'Tenha o perfil verificado',
  verify_phone_c_intro: 'Por favor, insira seu código de verificação',
  verify_phone_c_resend_success: 'Um novo código foi enviado com sucesso',
  verify_phone_c_retry_btn: 'Reenviar código',
  verify_phone_codeform_error_empty:
    'Por favor introduza o seu número de telefone',
  verify_phone_codeform_error_invalid:
    'Por favor introduza um número de telefone válido',
  verify_phone_codeform_nocorrectphonenumbers_err:
    'O seu número de telefone está incorreto',
  verify_phone_cont_button: 'Solicitar um código',
  verify_phone_header: 'Segurança',
  verify_phone_header_info:
    'Tenha o perfil verificado e aumente a sua credibilidade',
  verify_phone_input_txt:
    'Só irá receber um SMS de verificação. O seu número não será utilizado para outros fins.',
  verify_phone_intro: 'Mostre que você é um membro verificado',
};
