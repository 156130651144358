export default {
  aincome_cont_button: 'Suivant',
  aincome_header: 'À propos de vous',
  aincome_header_info: 'Quel est votre revenu annuel',
  app_steps: 'Étape {{from}} de {{to}}',
  body_height_cont_button: 'Suivant',
  body_height_header: 'À propos de vous',
  body_height_header_info: 'Quelle est votre taille ?',
  body_height_km_100: '148 CM',
  body_height_km_101: '149 CM',
  body_height_km_102: '150 CM',
  body_height_km_103: '151 CM',
  body_height_km_104: '152 CM',
  body_height_km_105: '153 CM',
  body_height_km_106: '154 CM',
  body_height_km_107: '155 CM',
  body_height_km_108: '156 CM',
  body_height_km_109: '157 CM',
  body_height_km_110: '158 CM',
  body_height_km_111: '159 CM',
  body_height_km_112: '160 CM',
  body_height_km_113: '161 CM',
  body_height_km_114: '162 CM',
  body_height_km_115: '163 CM',
  body_height_km_116: '164 CM',
  body_height_km_117: '165 CM',
  body_height_km_118: '166 CM',
  body_height_km_119: '167 CM',
  body_height_km_120: '168 CM',
  body_height_km_121: '169 CM',
  body_height_km_122: '170 CM',
  body_height_km_123: '171 CM',
  body_height_km_124: '172 CM',
  body_height_km_125: '173 CM',
  body_height_km_126: '174CM',
  body_height_km_127: '175 CM',
  body_height_km_128: '176 CM',
  body_height_km_129: '177 CM',
  body_height_km_130: '178 CM',
  body_height_km_131: '179 CM',
  body_height_km_132: '180 CM',
  body_height_km_133: '181 CM',
  body_height_km_134: '182 CM',
  body_height_km_135: '183 CM',
  body_height_km_136: '184 CM',
  body_height_km_137: '185 CM',
  body_height_km_138: '186 CM',
  body_height_km_139: '187 CM',
  body_height_km_140: '188 CM',
  body_height_km_141: '189 CM',
  body_height_km_142: '190 CM',
  body_height_km_143: '191 CM',
  body_height_km_144: '192 CM',
  body_height_km_145: '193 CM',
  body_height_km_146: '194 CM',
  body_height_km_147: '195 CM',
  body_height_km_148: '196 CM',
  body_height_km_149: '197 CM',
  body_height_km_150: '198 CM',
  body_height_km_151: '199 CM',
  body_height_km_152: '200 CM',
  body_height_km_153: '201 CM',
  body_height_km_154: '202 CM',
  body_height_km_155: '203 CM',
  body_height_km_156: '204 CM',
  body_height_km_157: '205 CM',
  body_height_km_158: '206 CM',
  body_height_km_159: '207 CM',
  body_height_km_160: '208 CM',
  body_height_km_161: '209 CM',
  body_height_km_162: '210 CM',
  body_height_km_163: '> 210 CM',
  body_height_km_91: '< 140 CM',
  body_height_km_92: '140 CM',
  body_height_km_93: '141 CM',
  body_height_km_94: '142 CM',
  body_height_km_95: '143 CM',
  body_height_km_96: '144 CM',
  body_height_km_97: '145 CM',
  body_height_km_98: '146 CM',
  body_height_km_99: '147 CM',
  body_height_mi_101: '4\' 11"',
  body_height_mi_104: '5\' 0"',
  body_height_mi_106: '5\' 1"',
  body_height_mi_109: '5\' 2"',
  body_height_mi_112: '5\' 3"',
  body_height_mi_114: '5\' 4"',
  body_height_mi_117: '5\' 5"',
  body_height_mi_119: '5\' 6"',
  body_height_mi_122: '5\' 7"',
  body_height_mi_124: '5\' 8"',
  body_height_mi_127: '5\' 9"',
  body_height_mi_129: '5\' 10"',
  body_height_mi_132: '5\' 11"',
  body_height_mi_134: '6\' 0"',
  body_height_mi_137: '6\' 1"',
  body_height_mi_139: '6\' 2"',
  body_height_mi_142: '6\' 3"',
  body_height_mi_145: '6\' 4"',
  body_height_mi_147: '6\' 5"',
  body_height_mi_150: '6\' 6"',
  body_height_mi_152: '6\' 7"',
  body_height_mi_155: '6\' 8"',
  body_height_mi_157: '6\' 9"',
  body_height_mi_160: '6\' 10"',
  body_height_mi_162: '6\' 11"',
  body_height_mi_163: '> 6\' 11"',
  body_height_mi_91: '< 4\' 8"',
  body_height_mi_94: '4\' 8"',
  body_height_mi_96: '4\' 9"',
  body_height_mi_99: '4\' 10"',
  body_type_cont_button: 'Suivant',
  body_type_header: 'À propos de vous',
  body_type_header_info: 'Quelle est votre corpulence ?',
  char_body: 'Corpulence',
  char_children: 'Enfants',
  char_ethnicity: 'Appartenance ethnique',
  char_eye_color: 'Couleur des yeux',
  char_hair_color: 'Couleur de cheveux',
  char_header: 'Votre profil',
  char_height: 'Taille',
  char_my_height: 'Ma taille',
  char_my_height_bigger: 'ou plus grand',
  char_my_height_smaller: 'ou plus petit',
  char_smoker: 'Fumeur',
  children_cont_button: 'Suivant',
  children_header: 'À propos de vous',
  children_header_info: 'Avez-vous des enfants ?',
  contactwish_answer_more_q:
    'Très bien, veuillez épondre à une dernière question',
  contactwish_answer_q_ph: 'Votre réponse ...',
  contactwish_cwish: 'Types de rendez-vous',
  contactwish_flirttext: 'Texte de flirt',
  contactwish_header: 'Votre demande de contact',
  contactwish_header_info:
    'Montrez votre personnalité et rédigez votre texte de flirt',
  contactwish_input_ph: 'Votre texte de flirt ...',
  contactwish_more_about_you: 'Dites-en plus sur vous',
  contactwish_questions: 'Questions',
  contactwish_save_btn: 'Sauvegarder',
  contactwish_selec_ph: 'Choisissez une question ...',
  contactwish_what_you_search: "Qu'êtes-vous venu chercher chez nous?",
  cwish_cont_button: 'Suivant',
  cwish_header: 'À propos de vous',
  cwish_header_info: "Qu'êtes-vous venu chercher ici ?",
  db_1: 'Aucun',
  db_10: 'Concerts',
  db_11: 'Théatre',
  db_12: 'Shopping',
  db_13: 'Musique',
  db_14: 'Art',
  db_15: 'Sortir',
  db_16: 'Dessiner',
  db_164: 'Sportif/ve',
  db_165: 'Mince',
  db_166: 'Musclé(e)',
  db_167: 'Quelques Kilos en trop',
  db_168: 'Je te le dirai plus tard.',
  db_169: 'Allemand',
  db_17: 'Cinéma',
  db_170: 'Italien',
  db_171: 'Français',
  db_172: 'Espagnol',
  db_173: 'Portugais',
  db_174: 'Anglais',
  db_175: 'Russe',
  db_176: 'Tennis',
  db_177: 'Golf',
  db_178: 'Voile',
  db_179: 'Football',
  db_18: 'Sport à la télévision',
  db_180: 'Avrion',
  db_181: 'Dance',
  db_182: 'Judo',
  db_183: 'Équitation',
  db_184: 'Badminton',
  db_185: 'Fitnesse',
  db_186: 'Ping Pong',
  db_187: 'Jogging',
  db_188: 'Ski',
  db_189: 'Handball',
  db_19: 'Aller au restaurant',
  db_190: 'Hockey',
  db_191: 'Cyclisme',
  db_192: 'Karaté',
  db_193: 'Natation',
  db_194: 'Flirt',
  db_195: 'Contact érotique',
  db_198: 'Relation',
  db_2: '1',
  db_20: 'Sports',
  db_204: 'Relation SB/SB',
  db_206: 'Restaurant/ shopping',
  db_207: 'Asiqtique',
  db_208: 'Afro-Américain',
  db_209: 'Latino',
  db_21: 'Bar',
  db_210: 'Indien',
  db_211: 'Moyen-oriental',
  db_212: 'Métisse',
  db_213: 'Caucasien',
  db_214: 'Autre',
  db_216: 'Polonais',
  db_217: 'Chasse',
  db_218: 'Néerlandais',
  db_219: 'Turque',
  db_22: 'Cuisiner',
  db_220: 'Ukrainien(ne)',
  db_221: 'Hongrois(e)',
  db_222: 'Grecque',
  db_223: 'Tchèque',
  db_224: 'Bulgare',
  db_225: 'Suédois(e)',
  db_226: 'Danois(e)',
  db_227: 'Norvégien(ne)',
  db_228: 'Finlandais(e)',
  db_229: 'Chinois(e)',
  db_23: 'Randonnée',
  db_230: 'Yoga',
  db_231: 'Pilates',
  db_232: 'Tai Chi',
  db_233: 'Surf',
  db_234: 'Beach Volleyball',
  db_235: 'Escalade',
  db_236: 'Kitesurfing',
  db_237: 'Roller',
  db_238: 'Mountainbike',
  db_239: 'Snowboard',
  db_24: 'Lire',
  db_240: 'Basketball',
  db_241: 'Baseball',
  db_242: 'Footbal',
  db_243: 'Pole Dance',
  db_244: 'Zumba',
  db_245: 'Dance Classique',
  db_246: 'Consultant',
  db_247: 'Manager',
  db_248: 'Directeur',
  db_249: 'Auditeur',
  db_25: 'Voitures',
  db_250: 'Consultant en fiscalité',
  db_251: 'Informaticien',
  db_252: 'Ingénieur',
  db_253: 'Pharmacien',
  db_254: 'Enseignant',
  db_255: 'Chercheur',
  db_256: 'Enseignant',
  db_257: 'Chercheur',
  db_258: 'Avocat',
  db_259: 'Médecin',
  db_26: 'Voyager',
  db_260: 'Athlète',
  db_261: 'Entraîneur',
  db_262: 'Secrétaire',
  db_263: 'Éducateur',
  db_264: 'Esthéticien',
  db_265: 'Coiffeuse',
  db_266: 'Make-up Artist',
  db_267: 'Physiothérapeute',
  db_268: 'Masseuse',
  db_269: 'Model',
  db_27: 'Peinture',
  db_270: 'Chef (cuisine)',
  db_271: 'Fleuriste',
  db_272: 'Journaliste',
  db_273: 'Acteur',
  db_274: 'Jardinage',
  db_275: 'Sauna',
  db_276: 'Voyager en avion',
  db_277: 'Faire de la moto',
  db_278: 'Photographie',
  db_279: 'Ingénieur',
  db_28: 'Moins de {{amount}}',
  db_280: 'Arabe',
  db_281: 'Autre',
  db_282: 'Autre',
  db_283: 'Autre',
  db_284: 'Autre',
  db_285: 'Autre',
  db_286: 'Racquetball',
  db_287: 'Autre',
  db_29: '{{amount_min}} - {{amount_max}}',
  db_3: '2',
  db_30: '{{amount_min}} - {{amount_max}}',
  db_31: '{{amount_min}} - {{amount_max}}',
  db_32: 'Plus de {{amount}}',
  db_33: 'Je préfère garder ça pour moi',
  db_34: 'Assez',
  db_35: 'Bleu',
  db_36: 'Vert',
  db_37: 'Marron',
  db_38: 'Gris',
  db_39: 'Marron',
  db_4: '3',
  db_40: 'Noir',
  db_41: 'Rouge',
  db_42: 'Gris',
  db_43: 'Blanc',
  db_44: 'Blond clair',
  db_45: 'Blond foncé',
  db_5: '4',
  db_51: 'Moins de {{amount}}',
  db_52: '{{amount_min}} - {{amount_max}}',
  db_53: '{{amount_min}} - {{amount_max}}',
  db_54: '{{amount_min}} - {{amount_max}}',
  db_55: 'Plus de {{amount}}',
  db_56: 'Je préfère garder ça pour moi',
  db_57: 'Assez',
  db_58: 'Producteur',
  db_59: 'Avocat',
  db_6: '4+',
  db_60: 'Entrepreneur',
  db_61: 'Architecte',
  db_62: 'Star du petit écran',
  db_63: 'Sportif professionel',
  db_64: 'Indépendant',
  db_65: 'Bon vivant',
  db_66: 'Gourmet',
  db_67: 'Artiste',
  db_68: 'Banquier',
  db_69: 'Docteur',
  db_7: 'Oui',
  db_70: 'Pilote',
  db_71: 'Consultant',
  db_72: 'Banquier',
  db_73: 'Autre',
  db_74: 'Consultant en management',
  db_75: "Gestionnaire d'actifs",
  db_76: 'Infirmier',
  db_77: 'Agents de bord',
  db_78: 'Employé de banque',
  db_79: 'Pharmacien',
  db_8: 'Non',
  db_81: 'Artiste',
  db_83: 'Manager',
  db_84: 'Étudiant',
  db_85: 'Assistant',
  db_86: 'Assistant médical',
  db_87: 'Étudiant',
  db_89: 'Photographe',
  db_9: 'Parfois',
  db_90: 'Stagiaire',
  db_profile_question_female_2: 'Le futur dont je rêve',
  db_profile_question_female_3: 'Ma soirée parfaite',
  db_profile_question_female_4:
    'Quelle est la chose que vous avez toujours voulu faire',
  db_profile_question_female_5:
    'Comment une personne peut-elle vous fasciner ?',
  db_profile_question_female_6: 'Quels sont vos rêves?',
  db_profile_question_female_7: "Qu'est-ce que vous aimez?",
  db_profile_question_male_2: "Comment j'imagine une soirée fantastique.",
  db_profile_question_male_3: "Ce qu'il faut savoir sur moi",
  db_profile_question_male_4: 'Comment me surprendre',
  db_profile_question_male_5: "Ce que j'ai toujours voulu faire",
  ethnic_group_cont_button: 'Suivant',
  ethnic_group_header: 'À propos de vous',
  ethnic_group_header_info: 'Quelles sont vos origines ?',
  eye_color_cont_button: 'Suivant',
  eye_color_header: 'À propos de vous',
  eye_color_header_info: 'De quelle couleur sont vos yeux ?',
  flirt_text_cont_button: 'Suivant',
  flirt_text_header: 'À propos de vous',
  flirt_text_header_info: 'Présentez-vous à la communauté',
  hair_color_cont_button: 'Suivant',
  hair_color_header: 'À propos de vous',
  hair_color_header_info: 'De quelle couleur sont vos cheveux ?',
  into_community_btn: 'Rejoindre la communauté',
  job_cont_button: 'Suivant',
  job_header: 'À propos de vous',
  job_header_info: 'Quel est votre métier ?',
  languages_cont_button: 'Suivant',
  languages_header: 'À propos de vous',
  languages_header_info: 'Quelles langues parlez-vous ?',
  left_part_header: 'Vivez votre propre style de vie <br> de MyGaySugardaddy',
  lifestyle_header: 'Votre style de vie',
  lifestyle_nav_activity: 'Loisirs',
  lifestyle_nav_annual_income: 'Revenu annuel',
  lifestyle_nav_job: 'Profession',
  lifestyle_nav_lang: 'Langues',
  lifestyle_nav_mbudget: 'Budget mensuel',
  lifestyle_nav_pproperty: 'Fortune privée',
  lifestyle_nav_sport: 'Sport',
  lifestyle1_cont_button: 'Suivant',
  lifestyle1_header: 'À propos de vous',
  lifestyle1_header_info: 'Comment occupez-vous votre temps libre ?',
  login_check_stay_login: 'Rester connecté',
  login_err_invalid_mail_or_pw:
    "Veuillez vérifier votre nom d'utilisateur et votre mot de passe",
  login_err_mail_unknow: 'Cette adresse e-mail est inconnue',
  login_err_no_mail: 'Veuillez indiquer une adresse e-mail',
  login_err_no_pw: 'Veuillez indiquer un mot de passe',
  login_forgot_pw: 'Vous avez oublié votre mot de passe ?',
  login_header: 'Bienvenue, ravis de vous revoir',
  login_input_mail_ph: "Nom d'utilisateur / e-mail",
  login_input_pw_ph: 'Mot de passe',
  login_login_btn: 'Se connecter',
  login_login_fb: 'Se connecter avec Facebook',
  login_logout_header: '... Vous êtes maintenant déconnecté.',
  login_no_acc: "Vous n'avez pas encore de compte ?",
  login_project_header: "À quelle communauté souhaites-tu t'inscrire ?",
  login_project_header_info:
    "Merci de nous indiquer ton choix afin que nous puissions t'afficher dans la bonne communauté.",
  login_project_id_0_desc: 'Sugar dating dans sa forme classique',
  login_project_id_0_title: 'MSD classic',
  login_project_id_1_desc: 'Sugar dating pour la communauté LGBTQ',
  login_project_id_1_title: 'MSD gay',
  login_project_id_2_desc:
    'Sugar dating avec des femmes fortes et indépendantes',
  login_project_id_2_title: 'MSD mamas',
  login_project_validation_text:
    "Veuillez choisir l'endroit où vous voulez vous connecter",
  login_reg: "S'inscrire maintenant",
  login_use_email: 'Connexion par e-mail',
  login_want_reg: 'Voulez-vous vous enregistrer ?',
  maintenance_message:
    'Le site web est actuellement en cours de maintenance. Nous nous excusons pour ce désagrément.',
  nav_back_button: 'Retour',
  nav_next_button: 'Suivant',
  partner_role_sugarbabe: 'Sugarbabe',
  partner_role_sugarboy: 'Sugarboy',
  partner_role_sugardaddy: 'Sugardaddy',
  partner_role_sugarmom: 'Sugarmom',
  pimage_cont_button: 'Suivant',
  pimage_edit_image_btn: 'Modifier',
  pimage_from: 'de',
  pimage_header: 'Votre photo de profil',
  pimage_header_desc: 'Montrez-vous sous votre meilleur jour !',
  pimage_header_info:
    'Complétez votre profil avec des photos et augmentez vos chances de rencontre !',
  pimage_modal_close_btn: 'Fermer',
  pimage_modal_header: 'Télécharger une photo',
  pimage_modal_save_btn: 'Sauvegarder',
  pimage_prev_cont_button: 'Suivant',
  pimage_prev_header: 'À propos de vous',
  pimage_prev_header_desc: 'Excellent !',
  pimage_prev_header_info: 'Le lifestyle de haut standing MSD est à vous !',
  pimage_prev_sms_cont_button: 'Partez à la chasse dès maintenant !',
  pimage_prev_sms_edit_button: "Ou continuer l'élaboration de votre profil",
  pimage_prev_sms_header: 'Sécurité',
  pimage_prev_sms_header_desc:
    'Excellent ! Vous êtes désormais un membre vérifié.',
  pimage_up_image_btn: 'Télécharger une photo maintenant',
  pproperty_cont_button: 'Suivant',
  pproperty_header: 'À propos de vous',
  pproperty_header_info: 'Quels sont vos revenus ?',
  pw_reset_btn: 'Suivant',
  pw_reset_desc:
    "Entrez l'adresse e-mail avec laquelle vous vous êtes inscrit. Vous allez recevoir un e-mail vous expliquant la suite.",
  pw_reset_err_no_mail: 'Veuillez entrer votre adresse e-mail',
  pw_reset_header: 'Sécurité',
  pw_reset_input_mail_ph: 'E-mail',
  pw_reset_success_mail:
    'Un e-mail vous expliquant la suite vous a été envoyé. Veuillez vérifier le dossier "Spams" de votre boite mail si vous n\'avez rien reçu.',
  pw_rt_btn: 'Confirmer le mot de passe',
  pw_rt_desc:
    'Veuillez entrer un nouveau mot de passe et le confirmer. Si vous rencontrez des problèmes, veuillez nous contacter à support@mygaysugardaddy.eu',
  pw_rt_header: 'Sécurité',
  pw_rt_input_password_conf_ph: 'Répéter le mot de passe',
  pw_rt_input_password_equal_err: 'Erreur dans la confirmation du mot de passe',
  pw_rt_input_password_len_err:
    'Votre mot de passe doit comporter entre 6 et 20 caractères.',
  pw_rt_input_password_no_pw_err: 'Veuillez enter un mot de passe',
  pw_rt_input_password_ph: 'Mot de passe',
  questions_cont_button: 'Suivant',
  questions_header: 'À propos de vous',
  questions_header_info: "Des questions que vous aimeriez que l'on vous pose",
  reg_already_member: 'Vous êtes déjà membre?',
  reg_err_select_gender: 'Veuillez choisir un sexe',
  reg_finished_1_success_msg: 'Excellent !',
  reg_finished_2_success_msg: 'Votre profil est complet !',
  reg_finished_cont_button: 'Partez à la chasse !',
  reg_finished_header: 'Profil',
  reg_header: "S'inscrire gratuitement",
  reg_header_info: 'Je suis un(e)',
  reg_header_info_hide: 'Je suis ...',
  reg_login: 'Connexion',
  reg_or: 'ou',
  reg_prob_modal_body:
    "Malheureusement, il y a un problème avec votre compte, veuillez contacter notre support pour obtenir de l'aide",
  reg_prob_modal_close: 'Fermer',
  reg_prob_modal_header: 'Problème avec votre compte',
  reg_sugarbabe: 'Sugar Boy',
  reg_sugarbabe_hide: 'Femme',
  reg_sugardaddy: 'Sugar Daddy',
  reg_sugardaddy_hide: 'Masculin',
  reg_with_apple: "S'inscrire sur Apple",
  reg_with_email: "S'inscrire par e-mail",
  reg_with_facebook: "S'inscrire sur Facebook",
  reg_with_google: "S'inscrire sur Google",
  regd_18_error: 'Vous avez moins de 18 ans.',
  regd_accept_agb:
    "En vous inscrivant, vous acceptez notre <a href='https://www.mygaysugardaddy.com/privacy-policy' target='_blank'>Politique de protection des données</a> et les <a href='https://www.mygaysugardaddy.com/terms-and-conditions' target='_blank'>Conditions d'utilisation</a> de MyGaySugardaddy et confirmez que vous avez au moins 18 ans.",
  regd_city_ph: 'Ville',
  regd_email_ph: 'E-mail',
  regd_err_accept_agb: 'Veuillez accepter les conditions générales',
  regd_err_add_loc: 'Veuillez indiquer un lieu',
  regd_err_add_pw:
    'Votre mot de passe doit comporter entre 6 et 20 caractères.',
  regd_err_add_username: 'Veuillez indiquer un pseudonyme',
  regd_err_mail_locked: 'Cette adresse e-mail est déjà prise',
  regd_err_no_lat_lng: 'Lieu non trouvé. Veuillez en choisir un autre.',
  regd_err_no_mail: 'Veuillez indiquer une adresse e-mail',
  regd_err_recaptcha: 'Le captcha doit être résolu',
  regd_eventcode_ph: 'Code promo',
  regd_header_info: "S'inscrire par e-mail",
  regd_label_birthday: 'Date de naissance',
  regd_label_eventcode: 'Tu as un code promo ?',
  regd_modal_btn_babe: 'Je suis une : Sugar Boy',
  regd_modal_btn_daddy: 'Je suis un : Sugar daddy',
  regd_modal_conf_text:
    'Si cela est correct, cliquez sur "S\'inscrire gratuitement". <br> Ici, vous pouvez modifier vos données si elles ne sont pas correctes.',
  regd_modal_content:
    'Vous êtes un(e) <b>{{iam}}</b> ?</p><p> et cherchez un(e) <b>{{isearch}}</b> ?</p>',
  regd_modal_header: 'Veuillez vérifier à nouveau vos données',
  regd_modal_wrong_search: 'La déclaration ci-dessus est-elle fausse?',
  regd_months_1: 'Janvier',
  regd_months_10: 'Octobre',
  regd_months_11: 'Novembre',
  regd_months_12: 'Décembre',
  regd_months_2: 'Février',
  regd_months_3: 'Mars',
  regd_months_4: 'Avril',
  regd_months_5: 'Mai',
  regd_months_6: 'Juin',
  regd_months_7: 'Juillet',
  regd_months_8: 'Août',
  regd_months_9: 'Septembre',
  regd_password_ph: 'Mot de passe',
  regd_reg_button: "S'inscrire gratuitement",
  regd_select_gender: 'Choisis ton personnage ...',
  regd_select_gender_err: 'Choisis ton personnage',
  regd_select_gender_f: 'Masculin (Sugar Boy)',
  regd_select_gender_m: 'Masculin (Sugar Daddy)',
  regd_username_ph: 'Pseudonyme',
  regd_want_login: 'Voulez-vous vous connecter?',
  register_partner_role_list_default_text: 'Veuillez sélectionner',
  register_partner_role_list_label: 'Je cherche ...',
  register_partner_role_validation_text:
    'Veuillez préciser qui vous recherchez.',
  smoker_cont_button: 'Suivant',
  smoker_header: 'À propos de vous',
  smoker_header_info: 'Êtes-vous fumeur ?',
  sports_cont_button: 'Suivant',
  sports_header: 'À propos de vous',
  sports_header_info: 'Quels sports vous intéressent ?',
  summary_edit_mail_btn: "Changer l'adresse e-mail",
  summary_edit_profile_btn: "Ou continuer l'élaboration de votre profil",
  summary_go_to_community_btn: 'Commencez à flirter dès maintenant',
  summary_header: 'Sécurité',
  summary_header_desc: 'Bienvenue sur MyGaySugardaddy',
  summary_mail_info:
    '<p>Nous vous avons envoyé un lien de vérification vers votre adresse e-mail</p><p><b>{{mail}}</b></p><p>.</p>',
  summary_mail_repeat:
    "<p>Vous n'avez pas reçu d'e-mail ? Veuillez </p><p>vérifier votre dossier \"spam\". Si vous avez</p><p>des problèmes avec votre inscription, veuillez contacter notre équipe d'assistance à </p><p><span>support@mygaysugardaddy.eu</span></p>",
  summary_modal_body: 'Vous pouvez modifier votre adresse e-mail ici',
  summary_modal_close: 'Fermer',
  summary_modal_header: "Modifier l'adresse e-mail",
  summary_modal_save_send_btn: 'Sauvegarder & envoyer',
  summary_new_veri_mail: 'Nouvel e-mail de vérification envoyé avec succès',
  summary_not_received_btn: "Vous n'avez pas encore reçu d'e-mail ?",
  summary_resend_mail_btn: 'Renvoyer un e-mail',
  summary_sms_btn: 'Vérifier par SMS',
  summary_sms_info:
    'Vous pouvez aussi procéder à la vérification avec votre numéro de téléphone.',
  summary_sms_info_success: "Montrez que l'on peut vous faire confiance.",
  summary_success_header: "Vous l'avez fait !",
  summary_success_msg: 'Super !',
  summary_veri_mail_success: 'Votre adresse e-mail a été vérifiée avec succès',
  summary_wrong_mail_info: 'Vous avez saisi une adresse e-mail erronée ?',
  username_cont_button: 'Suivant',
  username_header: 'À propos de vous',
  username_header_info: 'Comment voulez-vous vous appeler ?',
  username_suggestions: 'Quelques suggestions',
  verif_prof_cont_button: 'Suivant',
  verif_prof_explain_info: 'Un style de vie de première classe vous attend !',
  verif_prof_header: 'Sécurité',
  verif_prof_header_desc: 'Vérifiez votre profil',
  verif_prof_header_info:
    'Solidifiez votre profil avec la vérification par SMS.',
  verif_prof_verf_email: 'Vérifier par e-mail',
  verif_prof_verf_sms: 'Vérifier par SMS',
  verify_phone_c_code_sent_info:
    "Nous avons envoyé le code à votre numéro de téléphone <b>{{phonenumber}}</b>. Ce processus peut prendre jusqu'à une minute.",
  verify_phone_c_cont_button: 'Confirmer votre code',
  verify_phone_c_error_empty: 'Veuillez saisir le code à 6 chiffres',
  verify_phone_c_error_resends_limit:
    'Quelque chose a faussé. Veuillez contacter le service de support : support@mygaysugardaddy.eu',
  verify_phone_c_error_wrong: "Le code saisi n'est pas correct",
  verify_phone_c_header: 'Sécurité',
  verify_phone_c_header_info: 'Vérifiez votre profil',
  verify_phone_c_intro: "Veuillez entrer votre code d'authentification",
  verify_phone_c_resend_success: 'Un nouveau code a été envoyé avec succès',
  verify_phone_c_retry_btn: 'Renvoyez-moi un code',
  verify_phone_codeform_error_empty: 'Veuillez saisir votre numéro de mobile',
  verify_phone_codeform_error_invalid:
    'Veuillez saisir un numéro de mobile valide',
  verify_phone_codeform_nocorrectphonenumbers_err:
    "Votre numéro de mobile n'est pas correct",
  verify_phone_cont_button: "Demander un code d'authentification",
  verify_phone_header: 'Sécurité',
  verify_phone_header_info: 'Vérifiez votre profil',
  verify_phone_input_txt:
    "Vous recevrez par SMS uniquement votre code d'authentication, nous ne faisons pas de publicité.",
  verify_phone_intro: 'Montrez que vous êtes un membre vérifié !',
};
