import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';

import { DataStorageService } from '../services';
import { SYSTEM_ROUTES } from '../../data/constants';
import { msdProjectHelper } from '../../data/helpers';

export const checkProjectIdGuard: CanActivateFn = () => {
  const router = inject(Router);
  const dataStorageService = inject(DataStorageService);

  const { msdProjectId } = dataStorageService.getDataStorage();

  if (msdProjectId === null) {
    router.navigate([SYSTEM_ROUTES.registration]);

    return false;
  }

  const { tempPageLink } = msdProjectHelper.getById(msdProjectId);

  if (tempPageLink) {
    window.open(tempPageLink, '_blank', 'noopener,noreferrer');

    return false;
  }

  return true;
};
