export default {
  aincome_cont_button: '次へ',
  aincome_header: 'あなたについて',
  aincome_header_info: '年収はどのくらいですか？',
  app_steps: 'ステップ{{from}}の{{to}}の{{from}}',
  body_height_cont_button: '次へ',
  body_height_header: 'あなたについて',
  body_height_header_info: '身長は何センチですか？',
  body_height_km_100: '148 CM',
  body_height_km_101: '149 CM',
  body_height_km_102: '150 CM',
  body_height_km_103: '151 CM',
  body_height_km_104: '152 CM',
  body_height_km_105: '153 CM',
  body_height_km_106: '154 CM',
  body_height_km_107: '155 CM',
  body_height_km_108: '156 CM',
  body_height_km_109: '157 CM',
  body_height_km_110: '158 CM',
  body_height_km_111: '159 CM',
  body_height_km_112: '160 CM',
  body_height_km_113: '161 CM',
  body_height_km_114: '162 CM',
  body_height_km_115: '163 CM',
  body_height_km_116: '164 CM',
  body_height_km_117: '165 CM',
  body_height_km_118: '166 CM',
  body_height_km_119: '167 CM',
  body_height_km_120: '168 CM',
  body_height_km_121: '169 CM',
  body_height_km_122: '170 CM',
  body_height_km_123: '171 CM',
  body_height_km_124: '172 CM',
  body_height_km_125: '173 CM',
  body_height_km_126: '174CM',
  body_height_km_127: '175 CM',
  body_height_km_128: '176 CM',
  body_height_km_129: '177 CM',
  body_height_km_130: '178 CM',
  body_height_km_131: '179 CM',
  body_height_km_132: '180 CM',
  body_height_km_133: '181 CM',
  body_height_km_134: '182 CM',
  body_height_km_135: '183 CM',
  body_height_km_136: '184 CM',
  body_height_km_137: '185 CM',
  body_height_km_138: '186 CM',
  body_height_km_139: '187 CM',
  body_height_km_140: '188 CM',
  body_height_km_141: '189 CM',
  body_height_km_142: '190 CM',
  body_height_km_143: '191 CM',
  body_height_km_144: '192 CM',
  body_height_km_145: '193 CM',
  body_height_km_146: '194 CM',
  body_height_km_147: '195 CM',
  body_height_km_148: '196 CM',
  body_height_km_149: '197 CM',
  body_height_km_150: '198 CM',
  body_height_km_151: '199 CM',
  body_height_km_152: '200 CM',
  body_height_km_153: '201 CM',
  body_height_km_154: '202 CM',
  body_height_km_155: '203 CM',
  body_height_km_156: '204 CM',
  body_height_km_157: '205 CM',
  body_height_km_158: '206 CM',
  body_height_km_159: '207 CM',
  body_height_km_160: '208 CM',
  body_height_km_161: '209 CM',
  body_height_km_162: '210 CM',
  body_height_km_163: '> 210 CM以上',
  body_height_km_91: '< 140CM未満',
  body_height_km_92: '140 CM',
  body_height_km_93: '141 CM',
  body_height_km_94: '142 CM',
  body_height_km_95: '143 CM',
  body_height_km_96: '144 CM',
  body_height_km_97: '145 CM',
  body_height_km_98: '146 CM',
  body_height_km_99: '147 CM',
  body_height_mi_101: '4\' 11"',
  body_height_mi_104: '5\' 0"',
  body_height_mi_106: '5\' 1"',
  body_height_mi_109: '5\' 2"',
  body_height_mi_112: '5\' 3"',
  body_height_mi_114: '5\' 4"',
  body_height_mi_117: '5\' 5"',
  body_height_mi_119: '5\' 6"',
  body_height_mi_122: '5\' 7"',
  body_height_mi_124: '5\' 8"',
  body_height_mi_127: '5\' 9"',
  body_height_mi_129: '5\' 10"',
  body_height_mi_132: '5\' 11"',
  body_height_mi_134: '6\' 0"',
  body_height_mi_137: '6\' 1"',
  body_height_mi_139: '6\' 2"',
  body_height_mi_142: '6\' 3"',
  body_height_mi_145: '6\' 4"',
  body_height_mi_147: '6\' 5"',
  body_height_mi_150: '6\' 6"',
  body_height_mi_152: '6\' 7"',
  body_height_mi_155: '6\' 8"',
  body_height_mi_157: '6\' 9"',
  body_height_mi_160: '6\' 10"',
  body_height_mi_162: '6\' 11"',
  body_height_mi_163: '> 6\' 11"以上',
  body_height_mi_91: '< 4\' 8"未満',
  body_height_mi_94: '4\' 8"',
  body_height_mi_96: '4\' 9"',
  body_height_mi_99: '4\' 10"',
  body_type_cont_button: '次へ',
  body_type_header: 'あなたについて',
  body_type_header_info: 'あなたはどんな体型ですか？',
  char_body: '体型',
  char_children: '子供',
  char_ethnicity: '人種',
  char_eye_color: '目の色',
  char_hair_color: '髪の色',
  char_header: 'あなたのプロフィール',
  char_height: '身長',
  char_my_height: '身長',
  char_my_height_bigger: '以上',
  char_my_height_smaller: '以下',
  char_smoker: '喫煙者',
  children_cont_button: '次へ',
  children_header: 'あなたについて',
  children_header_info: '子供はいますか？',
  contactwish_answer_more_q: '素晴らしい！もう1つの質問に答えてください',
  contactwish_answer_q_ph: 'あなたの回答は…',
  contactwish_cwish: '希望するデート',
  contactwish_flirttext: 'お誘いメッセージ',
  contactwish_header: '連絡先設定',
  contactwish_header_info: 'あなたのお誘いメッセージで自分をアピールしましょう',
  contactwish_input_ph: 'お誘いメッセージはこちらにどうぞ…',
  contactwish_more_about_you: 'あなたについて教えてください',
  contactwish_questions: '質問',
  contactwish_save_btn: '保存',
  contactwish_selec_ph: '質問を選択してください…',
  contactwish_what_you_search: 'あなたは何を探し求めていますか？',
  cwish_cont_button: '次へ',
  cwish_header: 'あなたについて',
  cwish_header_info: 'ここで何を探し求めているのでしょうか？',
  db_1: 'なし',
  db_10: 'コンサート',
  db_11: 'お芝居',
  db_12: 'お買物',
  db_13: '音楽',
  db_14: '芸術',
  db_15: 'お出掛け',
  db_16: 'デッサン',
  db_164: 'スポーティー',
  db_165: 'スリム',
  db_166: '筋肉質',
  db_167: 'ぽっちゃり',
  db_168: '後で教える',
  db_169: 'ドイツ語',
  db_17: '映画',
  db_170: 'イタリア語',
  db_171: 'フランス語',
  db_172: 'スペイン語',
  db_173: 'ポルトガル語',
  db_174: '英語',
  db_175: 'ロシア語',
  db_176: 'テニス',
  db_177: 'ゴルフ',
  db_178: 'セーリング',
  db_179: 'サッカー',
  db_18: 'スポーツ鑑賞',
  db_180: 'ボート',
  db_181: '社交ダンス',
  db_182: '柔道',
  db_183: '乗馬',
  db_184: 'バドミントン',
  db_185: 'フィットネス',
  db_186: '卓球',
  db_187: 'ジョギング',
  db_188: 'スキー',
  db_189: 'ハンドボール',
  db_19: '外食',
  db_190: 'ホッケー',
  db_191: 'バイキング',
  db_192: '空手',
  db_193: '水泳',
  db_194: 'おしゃべり',
  db_195: '性的な関係',
  db_198: '恋愛交際',
  db_2: '1人',
  db_20: 'スポーツ',
  db_204: 'シュガーベイビー/ シュガーダディの関係',
  db_206: '食事/ショッピング',
  db_207: 'アジア系',
  db_208: '黒人系／アフリカン・アメリカン系',
  db_209: 'ラテン系／ヒスパニック系',
  db_21: 'バー',
  db_210: 'インド系',
  db_211: '中近東系',
  db_212: 'ハーフ、クウォーター等',
  db_213: '白人系／ヨーロピアン系',
  db_214: 'その他',
  db_216: 'ポーランド語',
  db_217: '狩猟',
  db_218: 'オランダ語',
  db_219: 'トルコ語',
  db_22: '料理作り',
  db_220: 'ウクライナ語',
  db_221: 'ハンガリー語',
  db_222: 'ギリシャ語',
  db_223: 'チェコ語',
  db_224: 'ブルガリア語',
  db_225: 'スウェーデン語',
  db_226: 'デンマーク語',
  db_227: 'ノルウェー語',
  db_228: 'フィンランド語',
  db_229: '中国語',
  db_23: 'ハイキング',
  db_230: 'ヨガ',
  db_231: 'ピラティス',
  db_232: '太極拳',
  db_233: 'サーフィン',
  db_234: 'ビーチバレーボール',
  db_235: 'クライミング',
  db_236: 'カイトサーフィン',
  db_237: 'インラインスケート',
  db_238: 'マウンテンバイク',
  db_239: 'スノーボード',
  db_24: '読書',
  db_240: 'バスケットボール',
  db_241: '野球',
  db_242: 'サッカー',
  db_243: 'ポールダンス',
  db_244: 'ズンバ',
  db_245: 'バレエ',
  db_246: 'コンサルタント',
  db_247: 'マネージャー',
  db_248: '係長',
  db_249: '監査役',
  db_25: '車',
  db_250: '税理士',
  db_251: 'プログラマー',
  db_252: 'エンジニア',
  db_253: '薬剤師',
  db_254: '教師',
  db_255: '科学者',
  db_256: '教師',
  db_257: '科学者',
  db_258: '弁護士',
  db_259: '医師',
  db_26: '旅行',
  db_260: 'スポーツウーマン',
  db_261: 'トレーナー',
  db_262: '秘書',
  db_263: '教育者',
  db_264: 'エステティシャン',
  db_265: '美容師',
  db_266: 'メイクアップアーティスト',
  db_267: '理学療法士',
  db_268: 'マッサージ師',
  db_269: 'モデル',
  db_27: 'ペインティング',
  db_270: '調理師',
  db_271: 'フローリスト',
  db_272: 'ジャーナリスト',
  db_273: '女優',
  db_274: '園芸',
  db_275: 'サウナ',
  db_276: 'フライング',
  db_277: 'モーターサイクル',
  db_278: '写真撮影',
  db_279: 'エンジニア',
  db_28: '{{amount}}以下',
  db_280: 'アラビア語',
  db_281: 'その他',
  db_282: 'その他',
  db_283: 'その他',
  db_284: 'その他',
  db_285: 'その他',
  db_286: 'ラケットボール',
  db_287: 'その他',
  db_29: '{{amount_min}}- {{amount_max}}',
  db_3: '2人',
  db_30: '{{amount_min}}- {{amount_max}}',
  db_31: '{{amount_min}}- {{amount_max}}',
  db_32: '{{amount}}以上',
  db_33: '秘密にしておく',
  db_34: 'それ以上',
  db_35: 'ブルー',
  db_36: 'グリーン',
  db_37: 'ブラウン',
  db_38: 'グレイ',
  db_39: 'ブラウン',
  db_4: '3人',
  db_40: '黒',
  db_41: 'レッド',
  db_42: '白髪',
  db_43: 'ホワイト',
  db_44: '明るいブロンド',
  db_45: '暗いブロンド',
  db_5: '4人',
  db_51: '{{amount}}以下',
  db_52: '{{amount_min}}- {{amount_max}}',
  db_53: '{{amount_min}}- {{amount_max}}',
  db_54: '{{amount_min}}- {{amount_max}}',
  db_55: '{{amount}}以上',
  db_56: '秘密にしておく',
  db_57: 'その他',
  db_58: 'プロデューサー',
  db_59: '弁護士',
  db_6: '4人以上',
  db_60: '起業家',
  db_61: '建築家',
  db_62: 'タレント',
  db_63: 'プロのスポーツマン',
  db_64: '自営業',
  db_65: '人生を楽しむ人',
  db_66: '美食家',
  db_67: 'アーティスト',
  db_68: '銀行員',
  db_69: '医師',
  db_7: 'はい',
  db_70: 'パイロット',
  db_71: 'コンサルタント',
  db_72: '投資銀行家',
  db_73: 'その他',
  db_74: '経営コンサルタント',
  db_75: '資産運用管理',
  db_76: '看護師',
  db_77: 'フライトアテンダント',
  db_78: '銀行員',
  db_79: '薬剤師',
  db_8: 'いいえ',
  db_81: 'アーティスト',
  db_83: '営業',
  db_84: '学生',
  db_85: 'アシスタント',
  db_86: '看護師',
  db_87: '大学生',
  db_89: '写真家',
  db_9: '時々',
  db_90: '専門学校学生',
  db_profile_question_female_2: 'これが私の夢見る未来です。',
  db_profile_question_female_3: '完璧な夜をどう想像するか。',
  db_profile_question_female_4: 'ずっとやりたかったことは何ですか？',
  db_profile_question_female_5: 'どうすればあなたを魅了することができるのか？',
  db_profile_question_female_6: 'あなたの夢は何ですか？',
  db_profile_question_female_7: 'あなたは何がすきですか？',
  db_profile_question_male_2:
    '素敵な夜って、こんな感じなんだろうなと想像しています。',
  db_profile_question_male_3: 'これは、私について知っておいてほしいことです。',
  db_profile_question_male_4: 'こんな風に私を驚かせることができるんですね。',
  db_profile_question_male_5: 'いつかやってみたいと思っていたんです。',
  ethnic_group_cont_button: '次へ',
  ethnic_group_header: 'あなたについて',
  ethnic_group_header_info: 'あなたの人種は何ですか？',
  eye_color_cont_button: '次へ',
  eye_color_header: 'あなたについて',
  eye_color_header_info: '瞳の色は何色ですか？',
  flirt_text_cont_button: '次へ',
  flirt_text_header: 'あなたについて',
  flirt_text_header_info: '自分のことを皆様に伝えましょう',
  hair_color_cont_button: '次へ',
  hair_color_header: 'あなたについて',
  hair_color_header_info: '髪の色は何色はですか？',
  into_community_btn: 'さあ、始めましょう！',
  job_cont_button: '次へ',
  job_header: 'あなたについて',
  job_header_info: '職業は何ですか？',
  languages_cont_button: '次へ',
  languages_header: 'あなたについて',
  languages_header_info: 'どの言語が話せるのでしょうか？',
  left_part_header: '自分らしく生きる<br>MySugardaddy Lifestyle',
  lifestyle_header: 'あなたのライフスタイル',
  lifestyle_nav_activity: '趣味',
  lifestyle_nav_annual_income: '年収',
  lifestyle_nav_job: '職業',
  lifestyle_nav_lang: '言語',
  lifestyle_nav_mbudget: '月収',
  lifestyle_nav_pproperty: '私有財産',
  lifestyle_nav_sport: 'スポーツ',
  lifestyle1_cont_button: '次へ',
  lifestyle1_header: 'あなたについて',
  lifestyle1_header_info: '自由時間にはどんなことをしているのでしょうか？',
  login_check_stay_login: 'ログインした状態にする',
  login_err_invalid_mail_or_pw: 'ユーザー名とパスワードを再度ご確認ください',
  login_err_mail_unknow: 'メールアドレスが不明です',
  login_err_no_mail: 'メールの入力が必要です',
  login_err_no_pw: 'パスワードを記入してください',
  login_forgot_pw: 'パスワードを忘れましたか？',
  login_header: 'お帰りなさい',
  login_input_mail_ph: 'ユーザー名／Email',
  login_input_pw_ph: 'パスワード',
  login_login_btn: 'ログイン',
  login_login_fb: 'Facebookでログインする',
  login_logout_header: '...ログアウトしています',
  login_no_acc: 'まだ登録をされていませんか？',
  login_project_header: 'どのコミュニティに参加したいですか？',
  login_project_header_info:
    '適切なコミュニティにご案内できるよう、あなたの選択をお知らせください。',
  login_project_id_0_desc: 'シュガー・デートの定番',
  login_project_id_0_title: 'MSDクラシック',
  login_project_id_1_desc: 'LGBTQ+コミュニティのためのシュガーデート',
  login_project_id_1_title: 'MSDゲイ',
  login_project_id_2_desc: '強くて自立した女性とのシュガーデート',
  login_project_id_2_title: 'MSDママ',
  login_project_validation_text: 'ログインする場所を選択してください。',
  login_reg: '今すぐ登録する',
  login_use_email: 'メールでログイン',
  login_want_reg: '登録を希望されますか？',
  maintenance_message:
    '現在、当ホームページはメンテナンス中です。ご不便をおかけいたしまして申し訳ございません。',
  nav_back_button: '戻る',
  nav_next_button: '次へ',
  partner_role_sugarbabe: 'シュガーベイブ',
  partner_role_sugarboy: 'シュガーボーイ',
  partner_role_sugardaddy: 'シュガーダディ',
  partner_role_sugarmom: 'シュガーママ',
  pimage_cont_button: '次へ',
  pimage_edit_image_btn: '編集',
  pimage_from: 'から',
  pimage_header: 'あなたのプロフィール写真',
  pimage_header_desc: 'あなたの魅力を最大限に発揮しましょう',
  pimage_header_info:
    '素敵な写真をアップロードすることで、素敵な相手が見つかるでしょう',
  pimage_modal_close_btn: '閉じる',
  pimage_modal_header: '写真のアップロード',
  pimage_modal_save_btn: '保存',
  pimage_prev_cont_button: '次へ',
  pimage_prev_header: 'あなたについて',
  pimage_prev_header_desc: '美しいです！',
  pimage_prev_header_info:
    'ハイクラスなMSDライフスタイルがあなたを待っています！',
  pimage_prev_sms_cont_button: '幸せを掴もう！',
  pimage_prev_sms_edit_button: '又はプロフィールを完成させますか？',
  pimage_prev_sms_header: 'セキュリティ',
  pimage_prev_sms_header_desc:
    '素晴らしい！あなたのプロフィールは認証確認済みになりました。',
  pimage_up_image_btn: '今すぐ写真をアップロード',
  pproperty_cont_button: '次へ',
  pproperty_header: 'あなたについて',
  pproperty_header_info: 'どのくらいの資産がありますか？',
  pw_reset_btn: '次へ',
  pw_reset_desc:
    'ログイン時に使用したメールアドレスを入力してください。その後、次のステップに関するメールが送信されます。',
  pw_reset_err_no_mail: 'メールを入力してください',
  pw_reset_header: 'セキュリティ',
  pw_reset_input_mail_ph: 'Eメール',
  pw_reset_success_mail:
    '次のステップに関してメールが送信されました。メールが届かなかった場合は、迷惑メールフォルダをご確認ください。',
  pw_rt_btn: 'パスワードを確認してください',
  pw_rt_desc:
    '新しいパスワードを入力し、パスワードを確認してください。問題がある場合は下記までご連絡下さい。support@mysugardaddy.eu',
  pw_rt_header: 'セキュリティ',
  pw_rt_input_password_conf_ph: 'パスワードの再入力をしてください',
  pw_rt_input_password_equal_err: 'パスワードが一致していません',
  pw_rt_input_password_len_err:
    'パスワードは6文字以上20文字以内で設定してください。',
  pw_rt_input_password_no_pw_err: 'パスワードを入力してください',
  pw_rt_input_password_ph: 'パスワードを入力してください',
  questions_cont_button: '次へ',
  questions_header: 'あなたについて',
  questions_header_info: 'あなたについてもっと教えてください',
  reg_already_member: 'あなたはもうすでにメンバーですか？',
  reg_err_select_gender: '性別を選択してください',
  reg_finished_1_success_msg: '良くできました！',
  reg_finished_2_success_msg: 'プロフィールの設定が完了しました',
  reg_finished_cont_button: 'さあ、始めましょう！',
  reg_finished_header: 'プロフィール',
  reg_header: '今すぐ無料で登録する',
  reg_header_info: '私は',
  reg_header_info_hide: '私は',
  reg_login: 'ログイン',
  reg_or: '又は',
  reg_prob_modal_body:
    'アカウントに関する問題が発生したため、お手数ですが、 サポートチームまでお問合せください',
  reg_prob_modal_close: '閉じる',
  reg_prob_modal_header: 'アカウントに関する問題が発生しました',
  reg_sugarbabe: 'シュガーベイビー',
  reg_sugarbabe_hide: '女性',
  reg_sugardaddy: 'シュガーダディ',
  reg_sugardaddy_hide: '男性',
  reg_with_apple: 'Apple IDで登録する',
  reg_with_email: 'Eメールで登録する',
  reg_with_facebook: 'Facebookで登録する',
  reg_with_google: 'Googleで登録する',
  regd_18_error: 'あなたは18歳未満',
  regd_accept_agb:
    "こちらに会員登録することにより、お客様は当社、マイシュガーダディの<a href='https://www.mysugardaddy.com/privacy-policy' target='_blank'>プライバシーポリシー</a>と<a href='https://www.mysugardaddy.com/terms-and-conditions' target='_blank'>利用規約</a>に同意し、お客様が18歳以上であることに同意したことになります。",
  regd_city_ph: '都市',
  regd_email_ph: 'Eメール',
  regd_err_accept_agb: '当社の利用規約に同意する必要があります',
  regd_err_add_loc: '居住地を入力する必要があります',
  regd_err_add_pw: 'パスワードは6文字以上20文字以内で設定してください。',
  regd_err_add_username: 'ニックネームを選択してください',
  regd_err_mail_locked: 'このメールはすでに使用されています',
  regd_err_no_lat_lng:
    'そちらの場所は見つかりませんでした。別の場所を選択してください。',
  regd_err_no_mail: 'Eメールを記入する必要があります',
  regd_err_recaptcha: 'キャプチャーを解いてください',
  regd_eventcode_ph: 'プロモーションコード',
  regd_header_info: '夢を叶えよう！',
  regd_label_birthday: '生年月日',
  regd_label_eventcode: 'あなたはプロモーションコードをお持ちですか？',
  regd_modal_btn_babe: '私はシュガーベイビーです',
  regd_modal_btn_daddy: '私はシュガーダディです',
  regd_modal_conf_text:
    '記入した情報が正しければ「今すぐ無料登録する」をクリックしてください。<br> 変更したい場合は、こちらから変更できます。',
  regd_modal_content:
    '<p>あなたは<b>{{iam}}</b>ですか？ </p><p> そして、あなたは<b>{{isearch}}</b>を探していますか？ </p> <p>あなたは<b>{{iam}}</b>ですか？',
  regd_modal_header: '入力した情報をもう一度、ご確認してください',
  regd_modal_wrong_search: '上記は間違いですか？',
  regd_months_1: '1月',
  regd_months_10: '10月',
  regd_months_11: '11月',
  regd_months_12: '12月',
  regd_months_2: '2月',
  regd_months_3: '3月',
  regd_months_4: '4月',
  regd_months_5: '5月',
  regd_months_6: '6月',
  regd_months_7: '7月',
  regd_months_8: '8月',
  regd_months_9: '9月',
  regd_password_ph: 'パスワード',
  regd_reg_button: '今すぐ無料登録する',
  regd_select_gender: '性別を指定してください ...',
  regd_select_gender_err: '性別を指定してください',
  regd_select_gender_f: '女性',
  regd_select_gender_m: '男性',
  regd_username_ph: 'ニックネーム',
  regd_want_login: 'ログインしますか？',
  register_partner_role_list_default_text: '選択してください',
  register_partner_role_list_label: '私は検索する',
  register_partner_role_validation_text: 'どのような方をお探しですか？',
  smoker_cont_button: '次へ',
  smoker_header: 'あなたについて',
  smoker_header_info: '喫煙者ですか？',
  sports_cont_button: '次へ',
  sports_header: 'あなたについて',
  sports_header_info: '興味のあるスポーツは何ですか？',
  summary_edit_mail_btn: 'メール変更',
  summary_edit_profile_btn: '又はプロフィールの入力を続ける',
  summary_go_to_community_btn: '今すぐ誘いましょう',
  summary_header: 'セキュリティ',
  summary_header_desc:
    'MySugardaddyへようこそ、これから素晴らしい経験ができるでしょう!',
  summary_mail_info:
    '<p>お客様のメールアドレス</p><p><b>{{mail}}</b></p><p>に認証確認のリンク先を送信しました</p>',
  summary_mail_repeat:
    '<p>迷惑メールフォルダをご確認ください。それでも問題がある場合は、下記のサポートチームが対応致しますので、お問い合わせください。</p><p><span>support@mysugardaddy.eu</span></p>',
  summary_modal_body: 'メールはこちらから変更できます',
  summary_modal_close: '閉じる',
  summary_modal_header: 'メール変更',
  summary_modal_save_send_btn: '保存&送信',
  summary_new_veri_mail: '新しい認証確認メールが送信されました',
  summary_not_received_btn: 'まだメールが届いていないのでしょうか？',
  summary_resend_mail_btn: 'メールの再送信',
  summary_sms_btn: 'SMSメールで認証確認する',
  summary_sms_info: '電話番号で認証確認することもできます',
  summary_sms_info_success: 'あなたの信頼性を見せましょう',
  summary_success_header: '良くできました！',
  summary_success_msg: '素晴らしい！',
  summary_veri_mail_success: '記入したメールアドレスの認証確認が完了しました',
  summary_wrong_mail_info: 'メールアドレスの入力を間違えていませんか？',
  username_cont_button: '次へ',
  username_header: 'あなたについて',
  username_header_info: 'どのニックネームで呼ばれたいですか？',
  username_suggestions: '以下は、いくつかの提案です',
  verif_prof_cont_button: '次へ',
  verif_prof_explain_info: 'ハイクラスなライフスタイルがあなたを待っています！',
  verif_prof_header: 'セキュリティ',
  verif_prof_header_desc: 'プロフィールの認証確認をしましょう！',
  verif_prof_header_info: 'SMS認証で、プロフィールの信頼性を高めましょう。',
  verif_prof_verf_email: 'Emailで認証確認する',
  verif_prof_verf_sms: 'SMSメールで認証確認する',
  verify_phone_c_code_sent_info:
    'お客様の電話番号<b>{{phonenumber}}</b>にコードを送信しました。受信までに1分ほどかかる場合がございますので、ご了承ください。',
  verify_phone_c_cont_button: 'コードを確認',
  verify_phone_c_error_empty: '6桁のコードを記入してください',
  verify_phone_c_error_resends_limit:
    '問題が発生したため、お手数ながら, 下記のメールアドレスまでお問合せください: support@mysugardaddy.eu',
  verify_phone_c_error_wrong: '入力されたコードが正しくありません',
  verify_phone_c_header: 'セキュリティ',
  verify_phone_c_header_info: 'プロフィールの認証確認',
  verify_phone_c_intro: '認証確認のコード番号を入力してください',
  verify_phone_c_resend_success: '只今、新しいコードが送信されました',
  verify_phone_c_retry_btn: '認証確認コードの再送信',
  verify_phone_codeform_error_empty: '電話番号を入力してください',
  verify_phone_codeform_error_invalid: '有効な電話番号を入力してください',
  verify_phone_codeform_nocorrectphonenumbers_err:
    '入力された電話番号が見つかりませんでした',
  verify_phone_cont_button: 'コードで認証確認する',
  verify_phone_header: 'セキュリティ',
  verify_phone_header_info: 'プロフィールの認証確認',
  verify_phone_input_txt:
    'SMS認証の為、電話番号が必要です。この電話番号は他の用途には使用されません。',
  verify_phone_intro: '認証確認済みメンバーであることをアピールしよう！',
};
