import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';

import { RegisterDataService } from '../../modules/registration/services';
import { AuthService } from '../services';

export const checkRegisterStepGuard: CanActivateFn = (route) => {
  const authService = inject(AuthService);
  const registerDataService = inject(RegisterDataService);

  const isAuth = authService.isRegAuthenticated();
  const { regstep } = registerDataService.getSavedUserProfileData();
  const { routeTitle } = route.data;

  return isAuth && routeTitle === regstep;
};
