import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { finalize, tap } from 'rxjs';

import { AuthService, LoaderService } from '../../../core/services';
import { environment } from '../../../../environments/environment';
import { RegisterDataService } from './register-data.service';
import {
  AmountData,
  Answer,
  BaseResponse,
  BaseUserProfileInputData,
  BodyHeightData,
  ParsedAnswer,
  Question,
  QuestionAnswer,
  RequestIdentifiers,
  UserProfile,
  UserProfileCheckStatuses,
  UserProfileGender,
} from '../../../data/interfaces';

interface Data {
  ids: number[];
  identifier: RequestIdentifiers;
  updateProp: keyof UserProfile;
}

interface Amount {
  amount?: number;
  amount_min?: number;
  amount_max?: number;
}

@Injectable({
  providedIn: 'root',
})
export class RegisterCompleteProfileService {
  private readonly PRIVATE_PROPERTY_AMOUNT: Record<string, Amount> = {
    db_51: {
      amount: 250000,
    },
    db_52: {
      amount_min: 250000,
      amount_max: 500000,
    },
    db_53: {
      amount_min: 500000,
      amount_max: 1000000,
    },
    db_54: {
      amount_min: 1000000,
      amount_max: 10000000,
    },
    db_55: {
      amount: 10000000,
    },
  };

  private readonly ANNUAL_INCOME_AMOUNT: Record<string, Amount> = {
    db_28: {
      amount: 100000,
    },
    db_29: {
      amount_min: 100000,
      amount_max: 250000,
    },
    db_30: {
      amount_min: 250000,
      amount_max: 500000,
    },
    db_31: {
      amount_min: 500000,
      amount_max: 1000000,
    },
    db_32: {
      amount: 1000000,
    },
  };

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private registerDataService: RegisterDataService,
    private loaderService: LoaderService,
  ) {}

  saveSelectedData({ ids, identifier, updateProp }: Data) {
    const authData = this.authService.getAuthInfoForRequest();
    const requestPayload = {
      identifier,
      selected: ids,
      ...authData,
    };

    return this.http
      .post<BaseResponse<[]>>(
        `${environment.REG_API_URL}/profileAddPersonalData`,
        requestPayload,
      )
      .pipe(
        tap({
          next: () => {
            this.registerDataService.updateUserProfileProp(updateProp, ids);
          },
        }),
      );
  }

  saveFlirtText(text: string) {
    this.loaderService.showLoader();

    const auth = this.authService.getAuthInfoForRequest();
    const requestPayload = {
      flirttext: text,
      ...auth,
    };

    return this.http
      .post<BaseResponse<string>>(
        `${environment.REG_API_URL}/changeFlirtText`,
        requestPayload,
      )
      .pipe(
        tap(({ data, error }) => {
          if (error) {
            return;
          }

          this.registerDataService.updateUserProfileProp('flirttext', data);
        }),
        finalize(() => {
          this.loaderService.hideLoader();
        }),
      );
  }

  saveQuestionAnswer(answer: QuestionAnswer) {
    this.loaderService.showLoader();

    const authData = this.authService.getAuthInfoForRequest();
    const requestPayload = {
      ...answer,
      ...authData,
    };

    return this.http
      .post<BaseResponse<[]>>(
        `${environment.REG_API_URL}/profileAddQuestion`,
        requestPayload,
      )
      .pipe(
        tap(({ error }) => {
          if (error) {
            return;
          }

          this.addToUserProfileQuestionsData(answer);
        }),
        finalize(() => {
          this.loaderService.hideLoader();
        }),
      );
  }

  deleteQuestionAnswer(id: number) {
    this.loaderService.showLoader();

    const authData = this.authService.getAuthInfoForRequest();
    const requestPayload = {
      identifier: id,
      ...authData,
    };

    return this.http
      .post<BaseResponse<[]>>(
        `${environment.REG_API_URL}/profileRemoveQuestion`,
        requestPayload,
      )
      .pipe(
        tap(({ error }) => {
          if (error) {
            return;
          }

          this.deleteFromUserProfileQuestionsData(id);
        }),
        finalize(() => {
          this.loaderService.hideLoader();
        }),
      );
  }

  getBodyHeightData() {
    // eslint-disable-next-line
    const { body_height, selectedBody_height, milesorkm } =
      this.registerDataService.getSavedUserProfileData();

    const bodyHeightData = this.parseBodyHeightData(body_height, milesorkm);

    return {
      bodyHeightData,
      selectedBodyHeightData: selectedBody_height,
    };
  }

  getPrivateProperties() {
    // eslint-disable-next-line
    const { private_property, selectedPrivate_property, currencySign } =
      this.registerDataService.getSavedUserProfileData();

    const parsedData = this.parseAmountData(private_property, currencySign);

    return {
      data: parsedData,
      selectedData: selectedPrivate_property,
    };
  }

  getAnnualIncomeData() {
    // eslint-disable-next-line
    const { annual_income, selectedAnnual_income, currencySign } =
      this.registerDataService.getSavedUserProfileData();

    const parsedData = this.parseAmountData(
      annual_income,
      currencySign,
      RequestIdentifiers.annualIncome,
    );

    return {
      data: parsedData,
      selectedData: selectedAnnual_income,
    };
  }

  getQuestionsData() {
    const { profilequestions, profilequestionsArray } =
      this.registerDataService.getSavedUserProfileData();

    const answers = this.parseAnswersData(profilequestionsArray);
    const questions = this.parseQuestionsData(profilequestions, answers);

    return {
      answers,
      questions,
      originalQuestions: profilequestions,
    };
  }

  getQuestionTrKey(questionId: number) {
    const { usergender } = this.registerDataService.getSavedUserProfileData();

    if (usergender === UserProfileGender.female) {
      return `db_profile_question_female_${questionId}`;
    }

    return `db_profile_question_male_${questionId}`;
  }

  private addToUserProfileQuestionsData(answer: QuestionAnswer) {
    const { profilequestionsArray } =
      this.registerDataService.getSavedUserProfileData();

    const oldAnswer = profilequestionsArray.find(
      (a) => a.id === answer.identifier,
    );

    if (oldAnswer) {
      oldAnswer.profile_content = answer.content;
      oldAnswer.isApprove = UserProfileCheckStatuses.pending;

      return;
    }

    const newAnswer: Answer = {
      id: answer.identifier,
      profile_content: answer.content,
      isApprove: UserProfileCheckStatuses.pending,
    };

    profilequestionsArray.push(newAnswer);
  }

  private deleteFromUserProfileQuestionsData(answerId: number) {
    const { profilequestionsArray } =
      this.registerDataService.getSavedUserProfileData();

    const newData = profilequestionsArray.filter(
      (answer) => answer.id !== answerId,
    );

    this.registerDataService.updateUserProfileProp(
      'profilequestionsArray',
      newData,
    );
  }

  private parseQuestionsData(
    questions: BaseUserProfileInputData[],
    answers: Answer[],
  ) {
    const answersIds = answers.reduce((acc, answer) => {
      acc.push(answer.id);

      return acc;
    }, [] as number[]);

    return questions.reduce((acc, question) => {
      const isAnsweredQuestion = answersIds.includes(question.id);

      if (isAnsweredQuestion) {
        return acc;
      }

      const newQuestion: Question = {
        ...question,
        trKey: this.getQuestionTrKey(question.id),
      };

      acc.push(newQuestion);

      return acc;
    }, [] as Question[]);
  }

  private parseAnswersData(answers: Answer[]) {
    return answers.reduce((acc, answer) => {
      acc.push({
        ...answer,
        questionTrKey: this.getQuestionTrKey(answer.id),
      });

      return acc;
    }, [] as ParsedAnswer[]);
  }

  private parseAmountData(
    data: BaseUserProfileInputData[],
    currencySign: string,
    amountData = RequestIdentifiers.privateProperty,
  ) {
    let amount = this.PRIVATE_PROPERTY_AMOUNT;

    if (amountData === RequestIdentifiers.annualIncome) {
      amount = this.ANNUAL_INCOME_AMOUNT;
    }

    return data.reduce((acc, item) => {
      const key = `db_${item.id}`;
      const newPrivateProperty = {
        id: item.id,
        amount: '',
        amount_min: '',
        amount_max: '',
      } as AmountData;

      const value: Amount | undefined = amount[key];

      if (value === undefined) {
        acc.push(newPrivateProperty);

        return acc;
      }

      if (value.amount !== undefined) {
        newPrivateProperty.amount = `${value.amount} ${currencySign}`;
      }

      if (value.amount_min !== undefined) {
        newPrivateProperty.amount_min = `${value.amount_min} ${currencySign}`;
        newPrivateProperty.amount_max = `${value.amount_max} ${currencySign}`;
      }

      acc.push(newPrivateProperty);

      return acc;
    }, [] as AmountData[]);
  }

  private parseBodyHeightData(
    data: BaseUserProfileInputData[],
    milesorkm: string,
  ) {
    return data.reduce((acc, item) => {
      const newData: BodyHeightData = {
        ...item,
        trKey: `body_height_${milesorkm}_${item.id}`,
      };

      acc.push(newData);

      return acc;
    }, [] as BodyHeightData[]);
  }
}
