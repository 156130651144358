export const environment = {
  production: true,
  BASE_APP_URL: 'https://register.mysugardaddy.com',
  HOME_API_URL: 'https://regapi.mysugardaddy.com/homeapi',
  REG_API_URL: 'https://regapi.mysugardaddy.com/regapi',
  RECAPTCHA_SITE_KEY: '6LcdAU4jAAAAAPujHcxP6simZFAr5wYvQ1sz9pOR',
  GEOCODE_EARTH_AUTH: 'ge-47297d896a9e1b32',
  CLOUDFRONT: 'https://d2rbskte0h59w5.cloudfront.net',

  // The new API
  NEW_HOME_API_URL_V1: 'https://api.msdapi.com/api/v1/homeapi',
  NEW_REG_API_URL_V1: 'https://api.msdapi.com/api/v1/regapi',
};
