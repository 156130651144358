import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { first, map } from 'rxjs';

import {
  RegisterNavigationService,
  RegisterVerifyProfileService,
} from '../../modules/registration/services';
import { RegisterStep } from '../../data/interfaces';

export const previewSmsGuard: CanActivateFn = () => {
  const registerVerifyProfileService = inject(RegisterVerifyProfileService);
  const registerNavigationService = inject(RegisterNavigationService);

  const isVerified = registerVerifyProfileService.getSmsVerificationStatus();

  if (isVerified === null) {
    return registerVerifyProfileService.checkSMSVerificationStatus().pipe(
      first(),
      map(({ data }) => {
        if (data) {
          return true;
        }

        registerNavigationService.goToStep(
          RegisterStep.verifyProfilePhone,
          false,
        );

        return false;
      }),
    );
  }

  if (!isVerified) {
    registerNavigationService.goToStep(RegisterStep.verifyProfilePhone, false);

    return false;
  }

  return true;
};
