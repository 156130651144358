import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';

import { MaintenanceService } from '../services';
import { SYSTEM_ROUTES } from '../../data/constants';

export const maintenanceGuard: CanActivateFn = () => {
  const isMaintenance = inject(MaintenanceService).getMaintenanceStatus();
  const router = inject(Router);

  if (isMaintenance) {
    return true;
  }

  router.navigate([SYSTEM_ROUTES.registration]);

  return false;
};
