import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AnimationService {
  private flashTrigger$ = new BehaviorSubject(false);

  flash = this.flashTrigger$.asObservable();

  showFlash() {
    this.flashTrigger$.next(!this.flashTrigger$.value);
  }
}
