import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { map } from 'rxjs';

import { PasswordResetService } from '../../modules/password-reset/services';
import { SYSTEM_ROUTES } from '../../data/constants';

export const passwordResetLinkGuard: CanActivateFn = (route) => {
  const router = inject(Router);
  const passwordResetService = inject(PasswordResetService);
  const { token, userId } = route.params;

  return passwordResetService.checkQueryParams(token, userId).pipe(
    map(({ error }) => {
      if (error) {
        passwordResetService.resetData();
        router.navigate([SYSTEM_ROUTES.registration]);
      } else {
        passwordResetService.setData(token, userId);
      }

      return !error;
    }),
  );
};
