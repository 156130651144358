import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { first, map } from 'rxjs';

import { GeocodeEarthService } from '../services';

export const locationResolver: ResolveFn<boolean> = () => {
  const geocodeEarthService = inject(GeocodeEarthService);

  return geocodeEarthService.getUserLocation().pipe(
    first(),
    map(() => {
      return true;
    }),
  );
};
