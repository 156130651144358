import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';

import { LangService } from '../services';

export const defaultLangDataResolver: ResolveFn<boolean> = () => {
  const langService = inject(LangService);

  langService.setDefaultLangData();

  return true;
};
