import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs';

import { environment } from '../../../environments/environment';
import { LangService } from '../services';
import { AutocompleteResponse, BaseResponse } from '../../data/interfaces';

interface UserLocation {
  country: string;
  city: string;
  latitude: number;
  longitude: number;
  accuracyRadius: number;
}

@Injectable({
  providedIn: 'root',
})
export class GeocodeEarthService {
  private cache = new Map<string, AutocompleteResponse>();

  private readonly baseUrl = 'https://api.geocode.earth/v1';

  private userLatitude = 0;

  private userLongitude = 0;

  constructor(
    private http: HttpClient,
    private langService: LangService,
  ) {}

  getAutocompleteData(location: string) {
    const lang = this.langService.getCurrentLang();
    const params = {
      api_key: environment.GEOCODE_EARTH_AUTH,
      text: location,
      layers: 'locality',
      sources: '-geonames',
      'focus.point.lat': this.userLatitude,
      'focus.point.lon': this.userLongitude,
      lang,
    };

    return this.http
      .get<AutocompleteResponse>(`${this.baseUrl}/autocomplete`, {
        params,
      })
      .pipe(
        tap((response) => {
          const key = `${location}_${lang}`;

          this.cache.set(key, response);
        }),
      );
  }

  getCacheData(location: string) {
    const key = `${location}_${this.langService.getCurrentLang()}`;

    return this.cache.get(key) || null;
  }

  getUserLocation() {
    const apiUrl = `${environment.HOME_API_URL}/getUserLatLong`;

    return this.http.post<BaseResponse<UserLocation>>(apiUrl, {}).pipe(
      tap(({ data }) => {
        if (!data) {
          return;
        }

        const { latitude, longitude } = data;

        this.userLatitude = latitude;
        this.userLongitude = longitude;
      }),
    );
  }

  clearCache() {
    this.cache.clear();
  }
}
