import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';

import { AuthService, DataStorageService } from '../../../core/services';
import { environment } from '../../../../environments/environment';
import { ApiResponse, UserProfile } from '../../../data/interfaces';

type GetUserProfile = ApiResponse<UserProfile>;

@Injectable({
  providedIn: 'root',
})
export class RegisterDataService {
  private userProfileData: UserProfile | Record<string, never> = {};

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private dataStorageService: DataStorageService,
  ) {}

  getUserProfile() {
    const { register } = this.authService.getToken();

    return this.http
      .get<GetUserProfile>(
        `${environment.NEW_REG_API_URL_V1}/getUserProfileReg`,
        {
          headers: {
            'X-Authorization': register as string,
          },
        },
      )
      .pipe(
        map((response) => {
          if (response && response.data && typeof response.data !== 'string') {
            this.dataStorageService.updateDataStorage({
              msdProjectId: response.data.projectId,
            });

            response.data = this.parseUserProfile(response.data);
          }

          return response;
        }),
      );
  }

  getSavedUserProfileData() {
    return this.userProfileData;
  }

  setUserProfile(data: UserProfile) {
    this.userProfileData = data;
  }

  updateUserProfileProp<K extends keyof UserProfile>(
    key: K,
    value: UserProfile[K],
  ) {
    this.userProfileData[key] = value;
  }

  parseCheckCurrentUserResponse(response: GetUserProfile) {
    if (!response || response.error || typeof response.data === 'string') {
      return null;
    }

    return response.data;
  }

  resetData() {
    this.userProfileData = {};
  }

  private parseUserProfile(data: UserProfile): UserProfile {
    const { userpseudonym } = data;
    const parsedUserProfile = { ...data };

    // We don't show the automatically generated server-side userpseudonym
    if (userpseudonym.startsWith('MSD') && userpseudonym.length === 43) {
      parsedUserProfile.userpseudonym = '';
    }

    return parsedUserProfile;
  }
}
