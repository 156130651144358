export default {
  aincome_cont_button: 'Continuar',
  aincome_header: 'Acerca de ti',
  aincome_header_info: '¿Cuál es tu ingreso anual?',
  app_steps: 'Paso {{from}} de {{to}}',
  body_height_cont_button: 'Continuar',
  body_height_header: 'Acerca de ti',
  body_height_header_info: '¿Cuánto mides?',
  body_height_km_100: '148 CM',
  body_height_km_101: '149 CM',
  body_height_km_102: '150 CM',
  body_height_km_103: '151 CM',
  body_height_km_104: '152 CM',
  body_height_km_105: '153 CM',
  body_height_km_106: '154 CM',
  body_height_km_107: '155 CM',
  body_height_km_108: '156 CM',
  body_height_km_109: '157 CM',
  body_height_km_110: '158 CM',
  body_height_km_111: '159 CM',
  body_height_km_112: '160 CM',
  body_height_km_113: '161 CM',
  body_height_km_114: '162 CM',
  body_height_km_115: '163 CM',
  body_height_km_116: '164 CM',
  body_height_km_117: '165 CM',
  body_height_km_118: '166 CM',
  body_height_km_119: '167 CM',
  body_height_km_120: '168 CM',
  body_height_km_121: '169 CM',
  body_height_km_122: '170 CM',
  body_height_km_123: '171 CM',
  body_height_km_124: '172 CM',
  body_height_km_125: '173 CM',
  body_height_km_126: '174CM',
  body_height_km_127: '175 CM',
  body_height_km_128: '176 CM',
  body_height_km_129: '177 CM',
  body_height_km_130: '178 CM',
  body_height_km_131: '179 CM',
  body_height_km_132: '180 CM',
  body_height_km_133: '181 CM',
  body_height_km_134: '182 CM',
  body_height_km_135: '183 CM',
  body_height_km_136: '184 CM',
  body_height_km_137: '185 CM',
  body_height_km_138: '186 CM',
  body_height_km_139: '187 CM',
  body_height_km_140: '188 CM',
  body_height_km_141: '189 CM',
  body_height_km_142: '190 CM',
  body_height_km_143: '191 CM',
  body_height_km_144: '192 CM',
  body_height_km_145: '193 CM',
  body_height_km_146: '194 CM',
  body_height_km_147: '195 CM',
  body_height_km_148: '196 CM',
  body_height_km_149: '197 CM',
  body_height_km_150: '198 CM',
  body_height_km_151: '199 CM',
  body_height_km_152: '200 CM',
  body_height_km_153: '201 CM',
  body_height_km_154: '202 CM',
  body_height_km_155: '203 CM',
  body_height_km_156: '204 CM',
  body_height_km_157: '205 CM',
  body_height_km_158: '206 CM',
  body_height_km_159: '207 CM',
  body_height_km_160: '208 CM',
  body_height_km_161: '209 CM',
  body_height_km_162: '210 CM',
  body_height_km_163: '> 210 CM',
  body_height_km_91: '< 140 CM',
  body_height_km_92: '140 CM',
  body_height_km_93: '141 CM',
  body_height_km_94: '142 CM',
  body_height_km_95: '143 CM',
  body_height_km_96: '144 CM',
  body_height_km_97: '145 CM',
  body_height_km_98: '146 CM',
  body_height_km_99: '147 CM',
  body_height_mi_101: '4\' 11"',
  body_height_mi_104: '5\' 0"',
  body_height_mi_106: '5\' 1"',
  body_height_mi_109: '5\' 2"',
  body_height_mi_112: '5\' 3"',
  body_height_mi_114: '5\' 4"',
  body_height_mi_117: '5\' 5"',
  body_height_mi_119: '5\' 6"',
  body_height_mi_122: '5\' 7"',
  body_height_mi_124: '5\' 8"',
  body_height_mi_127: '5\' 9"',
  body_height_mi_129: '5\' 10"',
  body_height_mi_132: '5\' 11"',
  body_height_mi_134: '6\' 0"',
  body_height_mi_137: '6\' 1"',
  body_height_mi_139: '6\' 2"',
  body_height_mi_142: '6\' 3"',
  body_height_mi_145: '6\' 4"',
  body_height_mi_147: '6\' 5"',
  body_height_mi_150: '6\' 6"',
  body_height_mi_152: '6\' 7"',
  body_height_mi_155: '6\' 8"',
  body_height_mi_157: '6\' 9"',
  body_height_mi_160: '6\' 10"',
  body_height_mi_162: '6\' 11"',
  body_height_mi_163: '> 6\' 11"',
  body_height_mi_91: '< 4\' 8"',
  body_height_mi_94: '4\' 8"',
  body_height_mi_96: '4\' 9"',
  body_height_mi_99: '4\' 10"',
  body_type_cont_button: 'Continuar',
  body_type_header: 'Acerca de ti',
  body_type_header_info: '¿Cómo es tu figura?',
  char_body: 'Figura',
  char_children: 'Hijos',
  char_ethnicity: 'Pertenencia étnica',
  char_eye_color: 'Color de ojos',
  char_hair_color: 'Color de cabello',
  char_header: 'Tu perfil',
  char_height: 'Altura',
  char_my_height: 'Mi altura',
  char_my_height_bigger: 'o más grande',
  char_my_height_smaller: 'o más pequeño',
  char_smoker: 'Fumador/a',
  children_cont_button: 'Continuar',
  children_header: 'Acerca de ti',
  children_header_info: '¿Tienes hijos?',
  contactwish_answer_more_q: 'Genial, sólo responde una pregunta más',
  contactwish_answer_q_ph: 'Selecciona una respuesta',
  contactwish_cwish: 'Tipo de cita deseada',
  contactwish_flirttext: 'Texto de seducción',
  contactwish_header: 'Tipo de relación deseada',
  contactwish_header_info:
    'Muestra tu personalidad, escribiendo un texto de seducción individual',
  contactwish_input_ph: 'Tu texto de seducción',
  contactwish_more_about_you: 'Cuenta más sobre ti',
  contactwish_questions: 'Preguntas',
  contactwish_save_btn: 'Guardar',
  contactwish_selec_ph: 'Selecciona tu pregunta',
  contactwish_what_you_search: '¿Qué estás buscando?',
  cwish_cont_button: 'Continuar',
  cwish_header: 'Acerca de ti',
  cwish_header_info: '¿Qué estás buscando aquí?',
  db_1: 'Ninguno',
  db_10: 'Conciertos',
  db_11: 'Teatro',
  db_12: 'Shopping',
  db_13: 'Música',
  db_14: 'Arte',
  db_15: 'Salir',
  db_16: 'Dibujar',
  db_164: 'Atlético',
  db_165: 'delgado',
  db_166: 'musculoso',
  db_167: 'unos kilos de más',
  db_168: 'te lo diré después',
  db_169: 'Alemán',
  db_17: 'Cine',
  db_170: 'Italiano',
  db_171: 'Francés',
  db_172: 'Español',
  db_173: 'Portugués',
  db_174: 'Inglés',
  db_175: 'Ruso',
  db_176: 'Tenis',
  db_177: 'Golf',
  db_178: 'Navegación',
  db_179: 'Fútbol',
  db_18: 'Deportes en la TV',
  db_180: 'Remo',
  db_181: 'Baile',
  db_182: 'Judo',
  db_183: 'Montar a caballo',
  db_184: 'Bádminton',
  db_185: 'Fitness',
  db_186: 'Tenis de mesa',
  db_187: 'Jogging',
  db_188: 'Esquí',
  db_189: 'Balonmano',
  db_19: 'Salir a cenar',
  db_190: 'Hockey',
  db_191: 'Bicicleta',
  db_192: 'Karate',
  db_193: 'Natación',
  db_194: 'Coqueteo',
  db_195: 'Contacto erótico',
  db_198: 'Relación',
  db_2: '1',
  db_20: 'Deportes',
  db_204: 'Relación SB / SD',
  db_206: 'Cenar / Shopping',
  db_207: 'Asiático/a',
  db_208: 'Negro / afroamericano/a',
  db_209: 'Latino/a / Hispánico/a',
  db_21: 'Bares',
  db_210: 'Indio/a',
  db_211: 'Oriente Medio',
  db_212: 'Mixto/a',
  db_213: 'Blanco/a  / Europeo/a',
  db_214: 'Otro',
  db_216: 'Polaco',
  db_217: 'Caza',
  db_218: 'Holandés',
  db_219: 'Turco/a',
  db_22: 'Cocinar',
  db_220: 'Ucraniano/a',
  db_221: 'Húngaro/a',
  db_222: 'Griego/a',
  db_223: 'Checo/a',
  db_224: 'Búlgaro/a',
  db_225: 'Sueco/a',
  db_226: 'Danés',
  db_227: 'Noruego/a',
  db_228: 'Finlandés',
  db_229: 'Chino/a',
  db_23: 'Senderismo',
  db_230: 'Yoga',
  db_231: 'Pilates',
  db_232: 'Tai Chi',
  db_233: 'Surf',
  db_234: 'Volleyball de playa',
  db_235: 'Montañismo',
  db_236: 'Kitesurfing',
  db_237: 'Patinaje en línea',
  db_238: 'Mountainbiking',
  db_239: 'Snowboarding',
  db_24: 'Leer',
  db_240: 'Baloncesto',
  db_241: 'Béisbol',
  db_242: 'Fútbol',
  db_243: 'Danza en barra',
  db_244: 'Zumba',
  db_245: 'Ballet',
  db_246: 'Consultor',
  db_247: 'Gerente',
  db_248: 'Personal superior',
  db_249: 'Auditor',
  db_25: 'Autos',
  db_250: 'Asesor fiscal',
  db_251: 'Informático',
  db_252: 'Ingeniero',
  db_253: 'Farmacéutico',
  db_254: 'Profesor',
  db_255: 'Científico',
  db_256: 'Profesora',
  db_257: 'Científica',
  db_258: 'Abogada',
  db_259: 'Doctora',
  db_26: 'Viajar',
  db_260: 'Deportista',
  db_261: 'Entrenadora',
  db_262: 'Secretaria',
  db_263: 'Educadora',
  db_264: 'Esteticista',
  db_265: 'Peluquera',
  db_266: 'Maquilladora',
  db_267: 'Fisioterapeuta',
  db_268: 'Masajista',
  db_269: 'Modelo',
  db_27: 'Pintar',
  db_270: 'Cocinera',
  db_271: 'Florista',
  db_272: 'Periodista',
  db_273: 'Actriz',
  db_274: 'Jardinería',
  db_275: 'Sauna',
  db_276: 'Volar',
  db_277: 'Motocicleta',
  db_278: 'Fotografía',
  db_279: 'Ingeniero',
  db_28: 'menos de {{amount}}',
  db_280: 'Árabe',
  db_281: 'otro',
  db_282: 'otro',
  db_283: 'otro',
  db_284: 'otro',
  db_285: 'otro',
  db_286: 'Racquetball',
  db_287: 'otro',
  db_29: '{{amount_min}} - {{amount_max}}',
  db_3: '2',
  db_30: '{{amount_min}} - {{amount_max}}',
  db_31: '{{amount_min}} - {{amount_max}}',
  db_32: 'más de {{amount}}',
  db_33: 'Prefiero reservármelo',
  db_34: 'suficiente',
  db_35: 'azul',
  db_36: 'verde',
  db_37: 'marrón',
  db_38: 'gris',
  db_39: 'marrón',
  db_4: '3',
  db_40: 'negro',
  db_41: 'rojo',
  db_42: 'plateado',
  db_43: 'blanco',
  db_44: 'rubio claro',
  db_45: 'rubio oscuro',
  db_5: '4',
  db_51: 'menos de {{amount}}',
  db_52: '{{amount_min}} - {{amount_max}}',
  db_53: '{{amount_min}} - {{amount_max}}',
  db_54: '{{amount_min}} - {{amount_max}}',
  db_55: 'más de {{amount}}',
  db_56: 'Prefiero reservármelo',
  db_57: 'suficiente',
  db_58: 'Productor',
  db_59: 'Abogado',
  db_6: '4+',
  db_60: 'Empresario',
  db_61: 'Arquitecto',
  db_62: 'Estrella de televisión',
  db_63: 'Deportista profesional',
  db_64: 'Independiente',
  db_65: 'Buena vida',
  db_66: 'Gourmet',
  db_67: 'Artista',
  db_68: 'Banquero',
  db_69: 'Médico',
  db_7: 'Sí',
  db_70: 'Piloto',
  db_71: 'Consultor',
  db_72: 'banquero de inversiones',
  db_73: 'Otro',
  db_74: 'Consultor de gestión',
  db_75: 'Gestor de activos',
  db_76: 'Enfermero/a',
  db_77: 'Azafata',
  db_78: 'Empleado/a bancario',
  db_79: 'Farmacéutico/a',
  db_8: 'No',
  db_81: 'Artista',
  db_83: 'Gerente',
  db_84: 'Alumna',
  db_85: 'Asistente',
  db_86: 'Asistente de médicos',
  db_87: 'Estudiante',
  db_89: 'Fotógrafo/a',
  db_9: 'A veces',
  db_90: 'Aprendiz',
  db_profile_question_female_2: 'Este es el futuro que sueño',
  db_profile_question_female_3: 'Cómo me imagino la noche perfecta',
  db_profile_question_female_4: '¿Qué es lo que siempre has querido hacer?',
  db_profile_question_female_5: '¿Cómo puede alguien fascinarte?',
  db_profile_question_female_6: '¿Con qué sueñas?',
  db_profile_question_female_7: '¿Qué es lo que te gusta?',
  db_profile_question_male_2: 'Así es como me imagino una noche maravillosa.',
  db_profile_question_male_3: 'Esto es algo más que debes saber sobre mí.',
  db_profile_question_male_4: 'Así es como podrías sorprenderme.',
  db_profile_question_male_5: 'Siempre he querido hacer esto algún día.',
  ethnic_group_cont_button: 'Continuar',
  ethnic_group_header: 'Acerca de ti',
  ethnic_group_header_info: '¿Qué origen étnico tienes?',
  eye_color_cont_button: 'Continuar',
  eye_color_header: 'Acerca de ti',
  eye_color_header_info: '¿Qué color de ojos tienes?',
  flirt_text_cont_button: 'Continuar',
  flirt_text_header: 'Acerca de ti',
  flirt_text_header_info: 'Cuenta un poco sobre ti',
  hair_color_cont_button: 'Continuar',
  hair_color_header: 'Acerca de ti',
  hair_color_header_info: '¿Cuál es tu color de pelo?',
  into_community_btn: 'Entrar a la comunidad',
  job_cont_button: 'Continuar',
  job_header: 'Acerca de ti',
  job_header_info: '¿Cuál es tu profesión?',
  languages_cont_button: 'Continuar',
  languages_header: 'Acerca de ti',
  languages_header_info: '¿Qué idiomas hablas?',
  left_part_header: 'Personaliza tu estilo de <br>vida con MySugardaddy',
  lifestyle_header: 'Tu estilo de vida',
  lifestyle_nav_activity: 'Tiempo libre',
  lifestyle_nav_annual_income: 'Ingreso anual',
  lifestyle_nav_job: 'Ocupación',
  lifestyle_nav_lang: 'Idiomas',
  lifestyle_nav_mbudget: 'presupuesto mensual',
  lifestyle_nav_pproperty: 'Patrimonio personal',
  lifestyle_nav_sport: 'Deporte',
  lifestyle1_cont_button: 'Continuar',
  lifestyle1_header: 'Acerca de ti',
  lifestyle1_header_info: '¿Qué haces en tu tiempo libre?',
  login_check_stay_login: 'Permanecer conectado',
  login_err_invalid_mail_or_pw: 'Compruebe su nombre de usuario y contraseña',
  login_err_mail_unknow: 'Este email es desconocido',
  login_err_no_mail: 'Es necesario ingresar una dirección de email',
  login_err_no_pw: 'Es necesario ingresar una contraseña',
  login_forgot_pw: '¿Olvidaste tu contraseña?',
  login_header: 'Bienvenido de nuevo',
  login_input_mail_ph: 'Nombre de usuario/email',
  login_input_pw_ph: 'Contraseña',
  login_login_btn: 'Iniciar sesión',
  login_login_fb: 'Iniciar sesión con Facebook',
  login_logout_header: '... Ya has cerrado la sesión.',
  login_no_acc: '¿Todavía no tienes una cuenta?',
  login_project_header: '¿A qué comunidad le gustaría unirse?',
  login_project_header_info:
    'Por favor, díganos su selección para que podamos mostrarle en la comunidad adecuada.',
  login_project_id_0_desc: 'Sugar dating en su forma clásica',
  login_project_id_0_title: 'MSD classic',
  login_project_id_1_desc: 'Sugar dating para la comunidad LGBTQ',
  login_project_id_1_title: 'MSD gay',
  login_project_id_2_desc: 'Sugar dating con mujeres fuertes e independientes',
  login_project_id_2_title: 'MSD mamas',
  login_project_validation_text: 'Seleccione dónde desea conectarse',
  login_reg: 'Registrarse ahora',
  login_use_email: 'Iniciar sesión con email',
  login_want_reg: '¿Deseas registrarte?',
  maintenance_message:
    'El sitio web está actualmente en mantenimiento. Te pedimos disculpas por las molestias.',
  nav_back_button: 'Atrás',
  nav_next_button: 'Continuar',
  partner_role_sugarbabe: 'sugarbabe',
  partner_role_sugarboy: 'Sugarboy',
  partner_role_sugardaddy: 'Sugardaddy',
  partner_role_sugarmom: 'Sugarmom',
  pimage_cont_button: 'Continuar',
  pimage_edit_image_btn: 'Modificar',
  pimage_from: 'de',
  pimage_header: 'Tu foto de perfil',
  pimage_header_desc: '¡Muestra por qué eres la mejor opción!',
  pimage_header_info:
    'Con unas bonitas fotos aumenta tus posibilidades de conseguir grandes contactos y citas impresionantes.',
  pimage_modal_close_btn: 'Cerrar',
  pimage_modal_header: 'Subir foto',
  pimage_modal_save_btn: 'Guardar',
  pimage_prev_cont_button: 'Continuar',
  pimage_prev_header: 'Acerca de ti',
  pimage_prev_header_desc: '¡Fenomenal! Este es tu perfil',
  pimage_prev_header_info:
    '¡El estilo de vida inigualabe de MSD te está esperando!',
  pimage_prev_sms_cont_button: '¡No te pierdas este momento: Hora de seducir!',
  pimage_prev_sms_edit_button: 'O completar tu perfil ahora',
  pimage_prev_sms_header: 'Seguridad',
  pimage_prev_sms_header_desc: '¡Magnífico! Ahora estás verificado',
  pimage_up_image_btn: 'Subir foto ahora',
  pproperty_cont_button: 'Continuar',
  pproperty_header: 'Acerca de ti',
  pproperty_header_info: '¿Cuánto ganas?',
  pw_reset_btn: 'Continuar',
  pw_reset_desc:
    'Introduce la dirección de correo electrónico que utilizaste para acceder a tu perfil. A continuación, recibirás inmediatamente un correo electrónico con los siguientes pasos.',
  pw_reset_err_no_mail: 'Por favor, especifica tu correo electrónico',
  pw_reset_header: 'Seguridad',
  pw_reset_input_mail_ph: 'Email',
  pw_reset_success_mail:
    'Se ha enviado un correo electrónico con los siguientes pasos. Por favor, comprueba tu carpeta de spam si no has recibido el correo electrónico inmediatamente.',
  pw_rt_btn: 'Confirmar contraseña',
  pw_rt_desc:
    'Por favor, introduce una nueva contraseña y luego confírmala. Si tienes algún problema, ponte en contacto con support@mysugardaddy.eu',
  pw_rt_header: 'Seguridad',
  pw_rt_input_password_conf_ph: 'Repetir contraseña',
  pw_rt_input_password_equal_err: 'Las contraseñas deben coincidir',
  pw_rt_input_password_len_err:
    'Su contraseña debe tener entre 6 y 20 caracteres.',
  pw_rt_input_password_no_pw_err: 'Por favor ingresa una contraseña',
  pw_rt_input_password_ph: 'Contraseña',
  questions_cont_button: 'Continuar',
  questions_header: 'Acerca de ti',
  questions_header_info: 'Cuenta más sobre ti',
  reg_already_member: '¿Ya eres miembro?',
  reg_err_select_gender: 'Por favor, selecciona un género',
  reg_finished_1_success_msg: '¡Genial!',
  reg_finished_2_success_msg: '¡Has rellenado tu perfil por completo!',
  reg_finished_cont_button: '¡Directo a la acción!',
  reg_finished_header: 'Perfil',
  reg_header: 'Regístrate ahora gratis',
  reg_header_info: 'Soy un/a',
  reg_header_info_hide: 'Soy ...',
  reg_login: 'Ingresar',
  reg_or: 'o',
  reg_prob_modal_body:
    'Desafortunadamente hay un problema con tu cuenta, por favor contáctate con nuestro equipo de soporte para más ayuda.',
  reg_prob_modal_close: 'Cerrar',
  reg_prob_modal_header: 'Problema con tu cuenta',
  reg_sugarbabe: 'Sugar Baby',
  reg_sugarbabe_hide: 'Femenino',
  reg_sugardaddy: 'Sugar Daddy',
  reg_sugardaddy_hide: 'Hombre',
  reg_with_apple: 'Regístrate con Apple',
  reg_with_email: 'Registrarse con Email',
  reg_with_facebook: 'Regístrate con Facebook',
  reg_with_google: 'Regístrate con Google',
  regd_18_error: 'Eres menor de 18 años',
  regd_accept_agb:
    "Con tu registro aceptas la <a href='https://www.mysugardaddy.com/privacy-policy' target='_blank'>Política de privacidad</a>, los <a href='https://www.mysugardaddy.com/terms-and-conditions' target='_blank'>Términos y Condiciones</a> de MySugardaddy y confirmas que tienes al menos 18 años.",
  regd_city_ph: 'Ciudad',
  regd_email_ph: 'Email',
  regd_err_accept_agb: 'Debes aceptar los Términos y Condiciones de Uso',
  regd_err_add_loc: 'Es necesario ingresar un lugar',
  regd_err_add_pw: 'Su contraseña debe tener entre 6 y 20 caracteres.',
  regd_err_add_username: 'Es necesario ingresar un seudónimo',
  regd_err_mail_locked: 'Este email ya está en uso',
  regd_err_no_lat_lng:
    'La ubicación no fue localizada. Por favor seleccione una opción',
  regd_err_no_mail: 'Es necesario ingresar una dirección e-mail',
  regd_err_recaptcha: 'El captcha debe ser resuelto',
  regd_eventcode_ph: 'Código promocional',
  regd_header_info: '¡Haz tus sueños realidad!',
  regd_label_birthday: 'Fecha de nacimiento',
  regd_label_eventcode: '¿Tiene un código de promoción?',
  regd_modal_btn_babe: 'Soy una: Sugar Baby',
  regd_modal_btn_daddy: 'Soy un: Sugar Daddy',
  regd_modal_conf_text:
    'Si todo esto es correcto, haz clic en "Registrarse ahora gratis". <br> Aquí puedes cambiar tus datos si no son correctos.',
  regd_modal_content:
    '<p>Tú eres un/a <b>{{iam}}</b> ?</p><p> y buscas un/a <b>{{isearch}}</b> ?</p>',
  regd_modal_header: 'Por favor, comprueba tus datos de nuevo',
  regd_modal_wrong_search: '¿La información anterior no es correcta?',
  regd_months_1: 'Enero',
  regd_months_10: 'Octubre',
  regd_months_11: 'Noviembre',
  regd_months_12: 'Diciembre',
  regd_months_2: 'Febrero',
  regd_months_3: 'Marzo',
  regd_months_4: 'Abril',
  regd_months_5: 'Mayo',
  regd_months_6: 'Junio',
  regd_months_7: 'Julio',
  regd_months_8: 'Agosto',
  regd_months_9: 'Septiembre',
  regd_password_ph: 'Contraseña',
  regd_reg_button: 'Regístrate ahora gratis',
  regd_select_gender: 'Selecciona tu género',
  regd_select_gender_err: 'Selecciona tu género',
  regd_select_gender_f: 'Femenino',
  regd_select_gender_m: 'Masculino',
  regd_username_ph: 'Seudónimo',
  regd_want_login: '¿Quieres iniciar sesión?',
  register_partner_role_list_default_text: 'Seleccione una opción',
  register_partner_role_list_label: 'Busco ...',
  register_partner_role_validation_text: 'Especifique a quién busca',
  smoker_cont_button: 'Continuar',
  smoker_header: 'Acerca de ti',
  smoker_header_info: '¿Eres fumador(a)?',
  sports_cont_button: 'Continuar',
  sports_header: 'Acerca de ti',
  sports_header_info: '¿Qué deportes te interesan?',
  summary_edit_mail_btn: 'Modificar el email',
  summary_edit_profile_btn: 'O continúa rellenando tu perfil',
  summary_go_to_community_btn: 'Empieza a flirtear ahora',
  summary_header: 'Seguridad',
  summary_header_desc: 'Bienvenido a MySugardaddy',
  summary_mail_info:
    '<p>Te hemos enviado a tu e-mail</p><p><b>{{mail}}</b></p><p>un enlace de confirmación.</p>',
  summary_mail_repeat:
    '<p>¿No recibiste el e-mail? Por favor revisa</p><p>también en la carpeta de correo spam, si sigues teniendo</p><p>problemas con tu registro, te ayudará</p><p>encantado nuestro equipo de soporte en</p><p><span>support@mysugardaddy.eu</span></p>',
  summary_modal_body: 'Aquí puedes cambiar tu dirección de e-mail',
  summary_modal_close: 'Cerrar',
  summary_modal_header: 'Modificar email',
  summary_modal_save_send_btn: 'Guardar y enviar',
  summary_new_veri_mail: 'Nuevo email de verificación enviado con éxito',
  summary_not_received_btn: '¿Aún no has recibido el correo electrónico?',
  summary_resend_mail_btn: 'Reenviar el email',
  summary_sms_btn: 'Verificarse ahora por SMS',
  summary_sms_info: 'También puedes verificarte con tu número de teléfono',
  summary_sms_info_success: 'Demuestra que eres confiable',
  summary_success_header: '¡Lo lograste!',
  summary_success_msg: '¡Grandioso!',
  summary_veri_mail_success: 'Tu email ha sido verificado con éxito',
  summary_wrong_mail_info:
    '¿Has introducido una dirección de email incorrecta?',
  username_cont_button: 'Continuar',
  username_header: 'Acerca de ti',
  username_header_info: '¿Cómo te quieres llamar?',
  username_suggestions: 'Aquí te damos algunas sugerencias',
  verif_prof_cont_button: 'Continuar',
  verif_prof_explain_info:
    '¡El estilo de vida exclusivo está cada vez más cerca!',
  verif_prof_header: 'Seguridad',
  verif_prof_header_desc: '¡Verifica tu perfil!',
  verif_prof_header_info: 'Consolida tu perfil con la verificación SMS',
  verif_prof_verf_email: 'Verificarse con email',
  verif_prof_verf_sms: 'Verificarse ahora con SMS',
  verify_phone_c_code_sent_info:
    'Te hemos enviado el código a tu número de teléfono <b>{{phonenumber}}</b>. Este proceso puede durar hasta 1 minuto.',
  verify_phone_c_cont_button: 'Confirmar código',
  verify_phone_c_error_empty: 'Introduce el código de 6 dígitos',
  verify_phone_c_error_resends_limit:
    'Algo ha salido mal, ponte en contacto con el equipo de Soporte: support@mysugardaddy.eu',
  verify_phone_c_error_wrong: 'El código introducido no es correcto',
  verify_phone_c_header: 'Seguridad',
  verify_phone_c_header_info: 'Verifica tu perfil',
  verify_phone_c_intro: 'Por favor, introduce tu código de verificación',
  verify_phone_c_resend_success: 'Se ha enviado un nuevo código con éxito',
  verify_phone_c_retry_btn: 'Reenviar código',
  verify_phone_codeform_error_empty:
    'Por favor, introduce tu número de teléfono',
  verify_phone_codeform_error_invalid: 'Introduce un número de teléfono válido',
  verify_phone_codeform_nocorrectphonenumbers_err:
    'Tu número de teléfono no es correcto',
  verify_phone_cont_button: 'Solicitar código',
  verify_phone_header: 'Seguridad',
  verify_phone_header_info: 'Verifica tu perfil',
  verify_phone_input_txt:
    'Sólo necesitamos tu número para efectos de verificación. Nunca recibirás un mensaje nuestro.',
  verify_phone_intro: '¡Demuestra que eres un miembro verificado!',
};
