export enum TrackingState {
  landingPage = '0',
  register = '1',
  converted = '2',
}

export enum TrackingDataKeys {
  ADWORDS = 'adwords',
  REFERRER = 'referrer',
  REF_ID = 'refid',
  OLD_REF_ID = 'oldRefId',
}

export interface IpQualityScoreResponse {
  active_tor: boolean;
  active_vpn: boolean;
  bot_status: boolean;
  brand: string;
  browser: string;
  canvas_hash: string;
  city: string;
  click_date: Date;
  connection_type: string;
  country: string;
  device_id: string;
  first_seen: Date;
  fraud_chance: number;
  guid: string;
  guid_confidence: number;
  ip_address: string;
  is_crawler: boolean;
  isp: string;
  last_seen: boolean;
  message: string;
  mobile: boolean;
  model: string;
  operating_system: string;
  proxy: boolean;
  recent_abuse: boolean;
  region: string;
  request_id: string;
  ssl_fingerprint: string;
  success: boolean;
  time: number;
  timezone: string;
  tor: boolean;
  unique: boolean;
  vpn: boolean;
  webgl_hash: string;
}
