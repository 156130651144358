import { RegisterStep } from './register';

export enum UserProfileCheckStatuses {
  yes = 'yes',
  no = 'no',
  pending = 'pending',
}

type CheckPicture = 'Registration' | UserProfileCheckStatuses;

export enum UserProfileGender {
  female = 'F',
  male = 'M',
}

export interface BaseUserProfileInputData {
  readonly id: number;
}

export interface BodyHeight extends BaseUserProfileInputData {
  readonly description: string;
  readonly lang: string;
}

export interface Answer {
  id: number;
  profile_content: string;
  isApprove: UserProfileCheckStatuses;
}

export type SelectedValue = number | null;

export interface UserProfile {
  readonly userId: number;
  readonly projectId: number;
  guilang: string;
  usergender: UserProfileGender;
  flirttextChecked: UserProfileCheckStatuses;
  flirttext: string;
  birth_date: string;
  userpseudonym: string;
  usercity: string;
  userzipcode: string;
  usercountry: string;
  isRealitychecked: UserProfileCheckStatuses;
  isIdchecked: UserProfileCheckStatuses;
  isIncomechecked: UserProfileCheckStatuses;
  selectedSmoking: SelectedValue;
  selectedBody_height: SelectedValue;
  selectedChildren: SelectedValue;
  selectedFigure: SelectedValue;
  selectedEye_color: SelectedValue;
  selectedEthnic_group: SelectedValue;
  selectedHair_color: SelectedValue;
  selectedProfession: SelectedValue;
  selectedAnnual_income: SelectedValue;
  selectedPrivate_property: SelectedValue;
  selectedMonthly_budget: SelectedValue;
  recreation_array: number[];
  spoken_languages_array: number[];
  sport_array: number[];
  contactwish_array: number[];
  contactwish: readonly BaseUserProfileInputData[];
  spoken_languages: BaseUserProfileInputData[];
  children: BaseUserProfileInputData[];
  figure: BaseUserProfileInputData[];
  smoking: BaseUserProfileInputData[];
  recreation: readonly BaseUserProfileInputData[];
  eye_color: BaseUserProfileInputData[];
  hair_color: BaseUserProfileInputData[];
  sport: BaseUserProfileInputData[];
  profession: BaseUserProfileInputData[];
  annual_income: BaseUserProfileInputData[];
  private_property: BaseUserProfileInputData[];
  monthly_budget: BaseUserProfileInputData[];
  ethnic_group: BaseUserProfileInputData[];
  milesorkm: string;
  body_height: BodyHeight[];
  currency: string;
  currencySign: string;
  profilequestions: BaseUserProfileInputData[];
  profilequestionsArray: Answer[];
  albumData: [];
  albumDataVip: [];
  pictureChecked: CheckPicture;
  avatarPicture: string;
  checkuser: boolean;
  regstep: RegisterStep;
  regsubstep: string;
}

export interface AmountData extends BaseUserProfileInputData {
  amount: string;
  amount_min: string;
  amount_max: string;
}

export interface BodyHeightData extends BaseUserProfileInputData {
  trKey: string;
}

export interface Question extends BaseUserProfileInputData {
  trKey: string;
}

export interface ParsedAnswer extends Answer {
  questionTrKey: string;
}

export interface QuestionAnswer {
  identifier: number;
  content: string;
}
