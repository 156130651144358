import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';

import { FbAuthService } from '../services';

import { RegisterDetailsService } from '../../modules/registration/services';

export const fbRegisterDetailsResolver: ResolveFn<boolean> = () => {
  const fbService = inject(FbAuthService);
  const registerDetailsService = inject(RegisterDetailsService);

  const { token, email, isBlocked } = fbService.getData();

  if (token && email && !isBlocked) {
    registerDetailsService.updateDetailsData({
      email,
    });

    return true;
  }

  return false;
};
