import { Component } from '@angular/core';

import { SYSTEM_ROUTES, iconLink } from '../../../data/constants';
import { DataStorageService } from '../../services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  protected readonly shortLogo = iconLink.shortLogo;

  protected readonly systemRoutes = SYSTEM_ROUTES;

  constructor(public dataStorageService: DataStorageService) {}
}
