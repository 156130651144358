export const SYSTEM_ROUTES = {
  index: '',
  registration: 'registration',
  login: 'login',
  loginProjects: 'projects',
  logout: 'logout',
  passwordReset: 'password-reset',
  maintenance: 'maintenance',
  details: 'details',
} as const;
