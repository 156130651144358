export default {
  aincome_cont_button: 'Weiter',
  aincome_header: 'Über Dich',
  aincome_header_info: 'Wie hoch ist Dein jährliches Einkommen?',
  app_steps: 'Schritt {{from}} von {{to}}',
  body_height_cont_button: 'Weiter',
  body_height_header: 'Über dich',
  body_height_header_info: 'Wie groß bist Du?',
  body_height_km_100: '148 CM',
  body_height_km_101: '149 CM',
  body_height_km_102: '150 CM',
  body_height_km_103: '151 CM',
  body_height_km_104: '152 CM',
  body_height_km_105: '153 CM',
  body_height_km_106: '154 CM',
  body_height_km_107: '155 CM',
  body_height_km_108: '156 CM',
  body_height_km_109: '157 CM',
  body_height_km_110: '158 CM',
  body_height_km_111: '159 CM',
  body_height_km_112: '160 CM',
  body_height_km_113: '161 CM',
  body_height_km_114: '162 CM',
  body_height_km_115: '163 CM',
  body_height_km_116: '164 CM',
  body_height_km_117: '165 CM',
  body_height_km_118: '166 CM',
  body_height_km_119: '167 CM',
  body_height_km_120: '168 CM',
  body_height_km_121: '169 CM',
  body_height_km_122: '170 CM',
  body_height_km_123: '171 CM',
  body_height_km_124: '172 CM',
  body_height_km_125: '173 CM',
  body_height_km_126: '174CM',
  body_height_km_127: '175 CM',
  body_height_km_128: '176 CM',
  body_height_km_129: '177 CM',
  body_height_km_130: '178 CM',
  body_height_km_131: '179 CM',
  body_height_km_132: '180 CM',
  body_height_km_133: '181 CM',
  body_height_km_134: '182 CM',
  body_height_km_135: '183 CM',
  body_height_km_136: '184 CM',
  body_height_km_137: '185 CM',
  body_height_km_138: '186 CM',
  body_height_km_139: '187 CM',
  body_height_km_140: '188 CM',
  body_height_km_141: '189 CM',
  body_height_km_142: '190 CM',
  body_height_km_143: '191 CM',
  body_height_km_144: '192 CM',
  body_height_km_145: '193 CM',
  body_height_km_146: '194 CM',
  body_height_km_147: '195 CM',
  body_height_km_148: '196 CM',
  body_height_km_149: '197 CM',
  body_height_km_150: '198 CM',
  body_height_km_151: '199 CM',
  body_height_km_152: '200 CM',
  body_height_km_153: '201 CM',
  body_height_km_154: '202 CM',
  body_height_km_155: '203 CM',
  body_height_km_156: '204 CM',
  body_height_km_157: '205 CM',
  body_height_km_158: '206 CM',
  body_height_km_159: '207 CM',
  body_height_km_160: '208 CM',
  body_height_km_161: '209 CM',
  body_height_km_162: '210 CM',
  body_height_km_163: '> 210 CM',
  body_height_km_91: '< 140 CM',
  body_height_km_92: '140 CM',
  body_height_km_93: '141 CM',
  body_height_km_94: '142 CM',
  body_height_km_95: '143 CM',
  body_height_km_96: '144 CM',
  body_height_km_97: '145 CM',
  body_height_km_98: '146 CM',
  body_height_km_99: '147 CM',
  body_height_mi_101: '4\' 11"',
  body_height_mi_104: '5\' 0"',
  body_height_mi_106: '5\' 1"',
  body_height_mi_109: '5\' 2"',
  body_height_mi_112: '5\' 3"',
  body_height_mi_114: '5\' 4"',
  body_height_mi_117: '5\' 5"',
  body_height_mi_119: '5\' 6"',
  body_height_mi_122: '5\' 7"',
  body_height_mi_124: '5\' 8"',
  body_height_mi_127: '5\' 9"',
  body_height_mi_129: '5\' 10"',
  body_height_mi_132: '5\' 11"',
  body_height_mi_134: '6\' 0"',
  body_height_mi_137: '6\' 1"',
  body_height_mi_139: '6\' 2"',
  body_height_mi_142: '6\' 3"',
  body_height_mi_145: '6\' 4"',
  body_height_mi_147: '6\' 5"',
  body_height_mi_150: '6\' 6"',
  body_height_mi_152: '6\' 7"',
  body_height_mi_155: '6\' 8"',
  body_height_mi_157: '6\' 9"',
  body_height_mi_160: '6\' 10"',
  body_height_mi_162: '6\' 11"',
  body_height_mi_163: '> 6\' 11"',
  body_height_mi_91: '< 4\' 8"',
  body_height_mi_94: '4\' 8"',
  body_height_mi_96: '4\' 9"',
  body_height_mi_99: '4\' 10"',
  body_type_cont_button: 'Weiter',
  body_type_header: 'Über Dich',
  body_type_header_info: 'Welcher Figurtyp bist Du?',
  char_body: 'Figur',
  char_children: 'Kinder',
  char_ethnicity: 'Ethnische Zugehörigkeit',
  char_eye_color: 'Augenfarbe',
  char_hair_color: 'Haarfarbe',
  char_header: 'Dein Steckbrief',
  char_height: 'Größe',
  char_my_height: 'Meine Größe',
  char_my_height_bigger: 'oder größer',
  char_my_height_smaller: 'oder kleiner',
  char_smoker: 'Raucher',
  children_cont_button: 'Weiter',
  children_header: 'Über dich',
  children_header_info: 'Wie viele Kinder hast Du?',
  contactwish_answer_more_q: 'Super, beantworte doch noch eine Frage',
  contactwish_answer_q_ph: 'Deine Antwort ...',
  contactwish_cwish: 'Datewunsch',
  contactwish_flirttext: 'Flirttext',
  contactwish_header: 'Dein Kontaktwunsch',
  contactwish_header_info:
    'Zeige Deine Persönlichkeit und schreibe einen individuellen Flirttext',
  contactwish_input_ph: 'Dein Flirttext ...',
  contactwish_more_about_you: 'Erzähle mehr über Dich',
  contactwish_questions: 'Fragen',
  contactwish_save_btn: 'Speichern',
  contactwish_selec_ph: 'Wähle eine Frage ...',
  contactwish_what_you_search: 'Was suchst Du bei uns?',
  cwish_cont_button: 'Weiter',
  cwish_header: 'Über Dich',
  cwish_header_info: 'Was suchst Du bei uns?',
  db_1: 'Keines',
  db_10: 'Konzerte',
  db_11: 'Theater',
  db_12: 'Shoppen',
  db_13: 'Musik',
  db_14: 'Kunst',
  db_15: 'Ausgehen',
  db_16: 'Zeichnen',
  db_164: 'sportlich',
  db_165: 'schlank',
  db_166: 'muskulös',
  db_167: 'ein paar Kilo zu viel',
  db_168: 'das sage ich später',
  db_169: 'Deutsch',
  db_17: 'Kino',
  db_170: 'Italienisch',
  db_171: 'Französisch',
  db_172: 'Spanisch',
  db_173: 'Portugiesisch',
  db_174: 'Englisch',
  db_175: 'Russisch',
  db_176: 'Tennis',
  db_177: 'Golf',
  db_178: 'Segeln',
  db_179: 'Fußball',
  db_18: 'Sport im TV',
  db_180: 'Rudern',
  db_181: 'Tanzen',
  db_182: 'Judo',
  db_183: 'Reiten',
  db_184: 'Badminton',
  db_185: 'Fitness',
  db_186: 'Tischtennis',
  db_187: 'Joggen',
  db_188: 'Skifahren',
  db_189: 'Handball',
  db_19: 'Essen gehen',
  db_190: 'Hockey',
  db_191: 'Radfahren',
  db_192: 'Karate',
  db_193: 'Schwimmen',
  db_194: 'Flirt',
  db_195: 'Erotischer Kontakt',
  db_198: 'Beziehung',
  db_2: '1',
  db_20: 'Sport',
  db_204: 'SB / SD Beziehung',
  db_206: 'Ausgehen / Shoppen',
  db_207: 'Asiatischer Abstammung',
  db_208: 'Schwarz / Afrikanischer Herkunft',
  db_209: 'Südländisch / Spanisch',
  db_21: 'Bars',
  db_210: 'Indisch',
  db_211: 'Naher Osten',
  db_212: 'Gemischt',
  db_213: 'Weiß / Europäisch',
  db_214: 'Andere',
  db_216: 'Polnisch',
  db_217: 'Jagen',
  db_218: 'Niederländisch',
  db_219: 'Türkisch',
  db_22: 'Kochen',
  db_220: 'Ukrainisch',
  db_221: 'Ungarisch',
  db_222: 'Griechisch',
  db_223: 'Tschechisch',
  db_224: 'Bulgarisch',
  db_225: 'Schwedisch',
  db_226: 'Dänisch',
  db_227: 'Norwegisch',
  db_228: 'Finnisch',
  db_229: 'Chinesisch',
  db_23: 'Wandern',
  db_230: 'Yoga',
  db_231: 'Pilates',
  db_232: 'Tai Chi',
  db_233: 'Surfen',
  db_234: 'Beach Volleyball',
  db_235: 'Klettern',
  db_236: 'Kitesurfen',
  db_237: 'Inline-Skaten',
  db_238: 'Mountainbiken',
  db_239: 'Snowboarden',
  db_24: 'Lesen',
  db_240: 'Basketball',
  db_241: 'Baseball',
  db_242: 'Football',
  db_243: 'Poledance',
  db_244: 'Zumba',
  db_245: 'Ballett',
  db_246: 'Berater (Consultant)',
  db_247: 'Manager',
  db_248: 'Leitender Angestellter',
  db_249: 'Wirtschaftsprüfer',
  db_25: 'Autos',
  db_250: 'Steuerberater',
  db_251: 'Informatiker',
  db_252: 'Ingenieur',
  db_253: 'Apotheker',
  db_254: 'Lehrer',
  db_255: 'Wissenschaftler',
  db_256: 'Lehrer',
  db_257: 'Wissenschaftler',
  db_258: 'Jurist',
  db_259: 'Arzt',
  db_26: 'Reisen',
  db_260: 'Sportler',
  db_261: 'Trainer',
  db_262: 'Sekretär',
  db_263: 'Erzieher',
  db_264: 'Kosmetiker',
  db_265: 'Friseur',
  db_266: 'Visagist',
  db_267: 'Physiotherapeut',
  db_268: 'Masseur',
  db_269: 'Model',
  db_27: 'Malen',
  db_270: 'Koch',
  db_271: 'Florist',
  db_272: 'Journalist',
  db_273: 'Schaupieler',
  db_274: 'Gartenabreit',
  db_275: 'Sauna',
  db_276: 'Fliegen',
  db_277: 'Motorrad',
  db_278: 'Fotografie',
  db_279: 'Ingenieur',
  db_28: 'weniger als {{amount}}',
  db_280: 'Arabisch',
  db_281: 'Andere',
  db_282: 'Andere',
  db_283: 'Andere',
  db_284: 'Andere',
  db_285: 'Andere',
  db_286: 'Racquetball',
  db_287: 'Andere',
  db_29: '{{amount_min}} - {{amount_max}}',
  db_3: '2',
  db_30: '{{amount_min}} - {{amount_max}}',
  db_31: '{{amount_min}} - {{amount_max}}',
  db_32: 'mehr als {{amount}}',
  db_33: 'Das behalte ich lieber für mich',
  db_34: 'genug',
  db_35: 'blau',
  db_36: 'grün',
  db_37: 'braun',
  db_38: 'grau',
  db_39: 'braun',
  db_4: '3',
  db_40: 'schwarz',
  db_41: 'rot',
  db_42: 'grau',
  db_43: 'weiß',
  db_44: 'hellblond',
  db_45: 'dunkelblond',
  db_5: '4',
  db_51: 'weniger als {{amount}}',
  db_52: '{{amount_min}} - {{amount_max}}',
  db_53: '{{amount_min}} - {{amount_max}}',
  db_54: '{{amount_min}} - {{amount_max}}',
  db_55: 'mehr als {{amount}}',
  db_56: 'Das behalte ich lieber für mich',
  db_57: 'genug',
  db_58: 'Produzent',
  db_59: 'Anwalt',
  db_6: '4+',
  db_60: 'Geschäftsmann',
  db_61: 'Architekt',
  db_62: 'TV-Star',
  db_63: 'Profisportler',
  db_64: 'Selbständig',
  db_65: 'Lebemann',
  db_66: 'Geniesser',
  db_67: 'Künstler',
  db_68: 'Banker',
  db_69: 'Arzt',
  db_7: 'Ja',
  db_70: 'Pilot',
  db_71: 'Finanzberater',
  db_72: 'Investmentbanker',
  db_73: 'Andere',
  db_74: 'Unternehmensberater',
  db_75: 'Vermögensverwalter',
  db_76: 'Krankenpfleger',
  db_77: 'Flugbegleiter',
  db_78: 'Bankangestellter',
  db_79: 'Apotheker',
  db_8: 'Nein',
  db_81: 'Künstler',
  db_83: 'Kaufmann',
  db_84: 'Schüler',
  db_85: 'Assistent',
  db_86: 'Arzthelfer',
  db_87: 'Student',
  db_89: 'Fotograf',
  db_9: 'Manchmal',
  db_90: 'Auszubildender',
  db_profile_question_female_2: 'Das ist die Zukunft, von der ich träume.',
  db_profile_question_female_3: 'Wie ich mir die perfekte Nacht vorstelle.',
  db_profile_question_female_4: 'Was wolltest du schon immer einmal machen?',
  db_profile_question_female_5: 'Wie kann Dich jemand faszinieren?',
  db_profile_question_female_6: 'Wie sehen Deine Träume aus?',
  db_profile_question_female_7: 'Was magst Du gerne?',
  db_profile_question_male_2: 'So stelle ich mir einen tollen Abend vor!',
  db_profile_question_male_3: 'Das solltest Du unbedingt über mich wissen!',
  db_profile_question_male_4: 'Damit kannst du mich überraschen!',
  db_profile_question_male_5: 'Das wollte ich schon immer einmal machen!',
  ethnic_group_cont_button: 'Weiter',
  ethnic_group_header: 'Über Dich',
  ethnic_group_header_info: 'Welche ethnische Zugehörigkeit hast Du?',
  eye_color_cont_button: 'Weiter',
  eye_color_header: 'Über Dich',
  eye_color_header_info: 'Welche Augenfarbe hast Du?',
  flirt_text_cont_button: 'Weiter',
  flirt_text_header: 'Über dich',
  flirt_text_header_info: 'Erzähle etwas über Dich',
  hair_color_cont_button: 'Weiter',
  hair_color_header: 'Über Dich',
  hair_color_header_info: 'Welche Haarfarbe hast Du?',
  into_community_btn: 'Jetzt direkt losflirten',
  job_cont_button: 'Weiter',
  job_header: 'Über Dich',
  job_header_info: 'Welchen Beruf übst Du aus?',
  languages_cont_button: 'Weiter',
  languages_header: 'Über Dich',
  languages_header_info: 'Welche Sprachen sprichst Du?',
  left_part_header: 'Lebe Deinen persönlichen<br>MyGaySugardaddy Lifestyle',
  lifestyle_header: 'Dein Lebensstil',
  lifestyle_nav_activity: 'Freizeit',
  lifestyle_nav_annual_income: 'Jährliches Einkommen',
  lifestyle_nav_job: 'Beruf',
  lifestyle_nav_lang: 'Sprachen',
  lifestyle_nav_mbudget: 'monatliches Budget',
  lifestyle_nav_pproperty: 'Privatvermögen',
  lifestyle_nav_sport: 'Sport',
  lifestyle1_cont_button: 'Weiter',
  lifestyle1_header: 'Über Dich',
  lifestyle1_header_info: 'Was machst Du in Deiner Freizeit?',
  login_check_stay_login: 'Eingeloggt bleiben',
  login_err_invalid_mail_or_pw:
    'Bitte überprüfen Sie Ihren Benutzernamen und Ihr Passwort',
  login_err_mail_unknow: 'Diese E-Mail ist unbekannt',
  login_err_no_mail: 'Eine E-Mail muss angegeben werden',
  login_err_no_pw: 'Ein Passwort muss angegeben werden',
  login_forgot_pw: 'Passwort vergessen?',
  login_header: 'Willkommen zurück',
  login_input_mail_ph: 'Benutzername / E-Mail',
  login_input_pw_ph: 'Passwort',
  login_login_btn: 'Einloggen',
  login_login_fb: 'Einloggen mit Facebook',
  login_logout_header: '... Du bist jetzt ausgeloggt.',
  login_no_acc: 'Du hast noch kein Konto?',
  login_project_header: 'In welche Community möchtest Du beitreten?',
  login_project_header_info:
    'Bitte nenn uns Dein Auswahl mit, damit wir Dich in der richtigen Community anzeigen.',
  login_project_id_0_desc: 'Sugar-Dating in seiner klassischen Art',
  login_project_id_0_title: 'MSD classic',
  login_project_id_1_desc: 'Sugar-Dating für die LGBTQ+ Community',
  login_project_id_1_title: 'MSD gay',
  login_project_id_2_desc: 'Sugar-Dating mit starken, unabhängigen Frauen',
  login_project_id_2_title: 'MSD mamas',
  login_project_validation_text: 'Bitte wähle aus wo Du dich einloggen willst',
  login_reg: 'Jetzt registrieren',
  login_use_email: 'Mit E-Mail einloggen',
  login_want_reg: 'Willst Du Dich registrieren?',
  maintenance_message:
    'Die Webseite wird derzeit gewartet. Wir entschuldigen uns für die Unannehmlichkeiten.',
  nav_back_button: 'Zurück',
  nav_next_button: 'Weiter',
  partner_role_sugarbabe: 'Sugarbabe',
  partner_role_sugarboy: 'Sugarboy',
  partner_role_sugardaddy: 'Sugardaddy',
  partner_role_sugarmom: 'Sugarmom',
  pimage_cont_button: 'Weiter',
  pimage_edit_image_btn: 'Bearbeiten',
  pimage_from: 'aus',
  pimage_header: 'Dein Profilbild',
  pimage_header_desc: 'Zeig Dich von Deiner besten Seite!',
  pimage_header_info:
    'Mit schönen Fotos steigen Deine Chancen auf tolle Kontakte und atemberaubende Dates!',
  pimage_modal_close_btn: 'Schließen',
  pimage_modal_header: 'Bild hochladen',
  pimage_modal_save_btn: 'Speichern',
  pimage_prev_cont_button: 'Weiter',
  pimage_prev_header: 'Über dich',
  pimage_prev_header_desc: 'Prima! Das ist Dein Profil!',
  pimage_prev_header_info:
    'Der einzigartige MSD Lifestyle ist nicht mehr weit!',
  pimage_prev_sms_cont_button: 'Jetzt direkt losflirten',
  pimage_prev_sms_edit_button: 'Oder Profil weiter ausfüllen',
  pimage_prev_sms_header: 'Sicherheit',
  pimage_prev_sms_header_desc: 'Prima! Dein Profil ist jetzt verifiziert.',
  pimage_up_image_btn: 'Jetzt Bild hochladen',
  pproperty_cont_button: 'Weiter',
  pproperty_header: 'Über Dich',
  pproperty_header_info: 'Wie viel Vermögen besitzt Du?',
  pw_reset_btn: 'Weiter',
  pw_reset_desc:
    'Gib hier die E-Mail-Adresse ein, die Du Deinem Profil zum Einloggen hinterlegt hast. Du bekommst dann umgehend eine E-Mail mit den weiteren Schritten.',
  pw_reset_err_no_mail: 'Eine E-Mail muss angegeben werden',
  pw_reset_header: 'Sicherheit',
  pw_reset_input_mail_ph: 'E-Mail',
  pw_reset_success_mail:
    'Eine E-Mail mit den weiteren Schritten wurde an Deine Adresse geschickt. Schaue auch bitte in Deinem Spamordner nach, falls Du die E-Mail nicht sofort erhalten hast.',
  pw_rt_btn: 'Passwort bestätigen',
  pw_rt_desc:
    'Bitte gib ein neues Passwort ein und bestätige dieses. Falls Du Probleme haben solltest wende Dich bitte an support@mygaysugardaddy.eu',
  pw_rt_header: 'Sicherheit',
  pw_rt_input_password_conf_ph: 'Passwort wiederholen',
  pw_rt_input_password_equal_err: 'Passwörter müssen übereinstimmen',
  pw_rt_input_password_len_err:
    'Ihr Passwort muss zwischen 6 und 20 Zeichen lang sein.',
  pw_rt_input_password_no_pw_err: 'Bitte gebe ein Passwort ein',
  pw_rt_input_password_ph: 'Passwort',
  questions_cont_button: 'Weiter',
  questions_header: 'Über dich',
  questions_header_info: 'Erzähle mehr über Dich',
  reg_already_member: 'Du bist bereits Mitglied?',
  reg_err_select_gender: 'Bitte wähle ein Geschlecht aus',
  reg_finished_1_success_msg: 'Sehr gut!',
  reg_finished_2_success_msg: 'Du hast Dein Profil vollständig ausgefüllt!',
  reg_finished_cont_button: 'Jetzt losflirten',
  reg_finished_header: 'Profil',
  reg_header: 'Jetzt kostenlos registrieren',
  reg_header_info: 'ich bin ein',
  reg_header_info_hide: 'Ich bin ...',
  reg_login: 'Login',
  reg_or: 'oder',
  reg_prob_modal_body:
    'Leider gibt es ein Problem mit Deinem Account, bitte melde Dich bei unserem Support-Team, um weitere Hilfe zu erhalten',
  reg_prob_modal_close: 'Schließen',
  reg_prob_modal_header: 'Problem mit Deinem Konto',
  reg_sugarbabe: 'Sugarboy',
  reg_sugarbabe_hide: 'Weiblich',
  reg_sugardaddy: 'Sugardaddy',
  reg_sugardaddy_hide: 'Männlich',
  reg_with_apple: 'Mit Apple registrieren',
  reg_with_email: 'Mit E-Mail registrieren',
  reg_with_facebook: 'Mit Facebook registrieren',
  reg_with_google: 'Mit Google registrieren',
  regd_18_error: 'Du bist unter 18',
  regd_accept_agb:
    "Mit Deiner Anmeldung erklärst Du Dich mit der <a href='https://www.mygaysugardaddy.com/privacy-policy' target='_blank'>Datenschutzbestimmung</a> und den <a href='https://www.mygaysugardaddy.com/terms-and-conditions' target='_blank'>Nutzungsbedingungen</a>  von MyGaySugardaddy einverstanden und bestätigst, dass Du mindestens 18 Jahre alt bist.",
  regd_city_ph: 'Ort',
  regd_email_ph: 'E-Mail',
  regd_err_accept_agb: 'Die AGB müssen akzeptiert werden',
  regd_err_add_loc: 'Ein Ort muss angegeben werden',
  regd_err_add_pw: 'Ihr Passwort muss zwischen 6 und 20 Zeichen lang sein.',
  regd_err_add_username: 'Ein Pseudonym muss angegeben werden',
  regd_err_mail_locked: 'Diese E-Mail ist vergeben',
  regd_err_no_lat_lng: 'Ort nicht gefunden, bitte wähle einen Ort aus',
  regd_err_no_mail: 'Eine E-Mail muss angegeben werden',
  regd_err_recaptcha: 'Das Captcha muss gelöst werden',
  regd_eventcode_ph: 'Promo-Code',
  regd_header_info: 'Lass Deine Träume wahr werden!',
  regd_label_birthday: 'Geburtstag',
  regd_label_eventcode: 'Hast Du einen Promo-Code?',
  regd_modal_btn_babe: 'Ich bin ein: Sugarboy',
  regd_modal_btn_daddy: 'Ich bin ein: Sugardaddy',
  regd_modal_conf_text:
    'Wenn das richtig ist, klicke auf "Jetzt kostenlos registrieren". <br> Hier kannst Du Deine Angaben ändern, wenn sie nicht korrekt sind',
  regd_modal_content:
    '<p>Du bist ein <b>{{iam}}</b> ?</p><p> Und suchst ein <b>{{isearch}}</b> ?</p>',
  regd_modal_header: 'Bitte kontrolliere noch einmal Deine Angaben',
  regd_modal_wrong_search: 'Ist die Aussage oben falsch?',
  regd_months_1: 'Januar',
  regd_months_10: 'Oktober',
  regd_months_11: 'November',
  regd_months_12: 'Dezember',
  regd_months_2: 'Februar',
  regd_months_3: 'März',
  regd_months_4: 'April',
  regd_months_5: 'Mai',
  regd_months_6: 'Juni',
  regd_months_7: 'Juli',
  regd_months_8: 'August',
  regd_months_9: 'September',
  regd_password_ph: 'Passwort',
  regd_reg_button: 'Jetzt kostenlos registrieren',
  regd_select_gender: 'Wähle Deinen Charakter ...',
  regd_select_gender_err: 'Wähle Deinen Charakter',
  regd_select_gender_f: 'Männlich (Sugarboy)',
  regd_select_gender_m: 'Männlich (Sugardaddy)',
  regd_username_ph: 'Pseudonym',
  regd_want_login: 'Willst Du Dich einloggen?',
  register_partner_role_list_default_text: 'Bitte auswählen',
  register_partner_role_list_label: 'Ich suche ...',
  register_partner_role_validation_text: 'Bitte gib an, wen du suchst.',
  smoker_cont_button: 'Weiter',
  smoker_header: 'Über dich',
  smoker_header_info: 'Bist Du Raucher?',
  sports_cont_button: 'Weiter',
  sports_header: 'Über Dich',
  sports_header_info: 'Welche Sportarten interessieren Dich?',
  summary_edit_mail_btn: 'E-Mail ändern',
  summary_edit_profile_btn: 'Oder Profil weiter ausfüllen',
  summary_go_to_community_btn: 'Jetzt direkt losflirten',
  summary_header: 'Sicherheit',
  summary_header_desc:
    'It’s time for a new time! Willkommen bei MyGaySugardaddy',
  summary_mail_info:
    '<p>Wir haben Dir an Deine E-Mail-Adresse</p><p><b>{{mail}}</b></p><p>einen Verifizierungslink gesendet.</p>',
  summary_mail_repeat:
    '<p>Bitte schaue auch in Deinem Spam-Ordner nach, hast Du trotzdem Probleme mit Deiner Anmeldung, hilft Dir gerne unser Support-Team weiter unter</p><p><span>support@mygaysugardaddy.eu</span></p>',
  summary_modal_body: 'Hier kannst Du Deine E-Mail ändern',
  summary_modal_close: 'Schließen',
  summary_modal_header: 'E-Mail ändern',
  summary_modal_save_send_btn: 'Speichern & Senden',
  summary_new_veri_mail: 'Neue Verifizierungs-Email erfolgreich versandt',
  summary_not_received_btn: 'Du hast die E-Mail nicht bekommen?',
  summary_resend_mail_btn: 'E-Mail erneut senden',
  summary_sms_btn: 'Jetzt über SMS verifizieren',
  summary_sms_info: 'Du kannst Dich auch mit Deiner Telefonnummer verifizieren',
  summary_sms_info_success: 'Zeige, dass man Dir vertrauen kann.',
  summary_success_header: 'Geschafft!',
  summary_success_msg: 'Prima!',
  summary_veri_mail_success:
    'Deine E-Mail-Adresse wurde erfolgreich verifiziert',
  summary_wrong_mail_info: 'Du hast eine falsche E-Mail-Adresse angegeben?',
  username_cont_button: 'Weiter',
  username_header: 'Über dich',
  username_header_info: 'Wie willst Du heißen?',
  username_suggestions: 'Hier ein paar Vorschläge',
  verif_prof_cont_button: 'Weiter',
  verif_prof_explain_info: 'Der High Class Lebensstil wartet auf Dich!',
  verif_prof_header: 'Sicherheit',
  verif_prof_header_desc: 'Verifiziere Dein Profil!',
  verif_prof_header_info:
    'Mit der SMS-Verifizierung zeigst Du, dass man Dir vertrauen kann.',
  verif_prof_verf_email: 'Mit E-Mail verifizieren',
  verif_prof_verf_sms: 'Jetzt mit SMS verifizieren',
  verify_phone_c_code_sent_info:
    'Wir haben Dir an Deine Telefonnummer <b>{{phonenumber}}</b> den Code gesendet. Dieser Vorgang kann bis zu 1 min dauern.',
  verify_phone_c_cont_button: 'Code bestätigen',
  verify_phone_c_error_empty: 'Bitte gib den 6-stelligen Code ein',
  verify_phone_c_error_resends_limit:
    'Etwas ist schief gelaufen, bitte kontaktiere den Support: support@mygaysugardaddy.eu',
  verify_phone_c_error_wrong: 'Der eingegebene Code ist nicht korrekt',
  verify_phone_c_header: 'Sicherheit',
  verify_phone_c_header_info: 'Verifiziere Dein Profil',
  verify_phone_c_intro: 'Gib jetzt Deinen Verifizierungscode ein',
  verify_phone_c_resend_success: 'Ein neuer Code wurde erfolgreich versendet',
  verify_phone_c_retry_btn: 'Code nochmal anfordern',
  verify_phone_codeform_error_empty: 'Bitte gib Deine Telefonnummer ein',
  verify_phone_codeform_error_invalid:
    'Bitte gib eine valide Telefonnummer ein',
  verify_phone_codeform_nocorrectphonenumbers_err:
    'Deine Telefonnummer ist nicht korrekt',
  verify_phone_cont_button: 'Code anfordern',
  verify_phone_header: 'Sicherheit',
  verify_phone_header_info: 'Verifiziere Dein Profil',
  verify_phone_input_txt:
    'Wir benötigen Deine Nummer ausschließlich für die Verifizierung. Du bekommst niemals eine Nachricht von uns.',
  verify_phone_intro: 'Zeige an, dass Du ein verifiziertes Mitglied bist!',
};
