import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { filter, first } from 'rxjs';

import {
  DataStorageService,
  LangService,
  LoaderService,
  TrackingService,
} from './core/services';
import { COMPLETE_REGISTER_STEPS } from './data/constants';
import { RegisterStep, Subscriptions } from './data/interfaces';
import { unsubscribe } from './data/helpers';
import { RegisterNavigationService } from './modules/registration/services';

interface QueryParams {
  lang: string;
  loginsite: string;
  adwords: string;
  refid: string;
  gender: string;
  partnerRole: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly routesWithHiddenHeader = [...COMPLETE_REGISTER_STEPS];

  private subscriptions$: Subscriptions = [];

  isShowHeader = false;

  constructor(
    private dataStorageService: DataStorageService,
    private langService: LangService,
    private router: Router,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private trackingService: TrackingService,
    private registerNavigationService: RegisterNavigationService,
  ) {}

  ngOnInit() {
    this.trackingService.setData({ referrer: document.referrer });
    this.registerNavigationService.initStepOrder();
    this.dataStorageService.setRegisterMode();
    this.handleQueryParams();
    this.navigationInterceptor();
  }

  ngOnDestroy() {
    unsubscribe(this.subscriptions$);
  }

  shouldShowHeader(url: string) {
    if (url.includes(RegisterStep.registrationFinished)) {
      this.isShowHeader = true;
      return;
    }

    this.isShowHeader = !this.routesWithHiddenHeader.some((registerStep) =>
      url.includes(registerStep),
    );
  }

  private handleQueryParams() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        first(),
      )
      .subscribe(() => {
        const { lang, loginsite, adwords, refid, gender, partnerRole } = this
          .route.snapshot.queryParams as Partial<QueryParams>;

        this.langService.setDefaultLangData(lang);
        this.dataStorageService.initStorage({
          loginsite: loginsite || '',
          gender: gender || null,
          partnerRole: partnerRole || null,
        });
        this.trackingService.setData({
          adwords: adwords || '',
          refid: refid || '',
        });

        this.updateTrackingData();
      });
  }

  private navigationInterceptor() {
    const sub$ = this.router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationStart) {
          this.loaderService.showLoader();
        }
        if (event instanceof NavigationEnd) {
          this.loaderService.hideLoader();
          this.shouldShowHeader(event.url);
        }

        if (event instanceof NavigationCancel) {
          this.loaderService.hideLoader();
        }

        if (event instanceof NavigationError) {
          this.loaderService.hideLoader();
        }
      },
    });

    this.subscriptions$.push(sub$);
  }

  private updateTrackingData() {
    const hasTrackingData = this.trackingService.hasTrackingData();

    if (!hasTrackingData) {
      return;
    }

    const { oldRefId } = this.trackingService.getData();
    const data = oldRefId || '';

    this.trackingService
      .sendTrackingData(data)
      .pipe(first())
      .subscribe({
        next: () => {
          this.trackingService.deleteOldRefId();
        },
      });
  }
}
