import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { first, map } from 'rxjs';

import { RegisterVerifyProfileService } from '../../modules/registration/services';

export const verificationMethodsResolver: ResolveFn<boolean> = () => {
  const registerVerifyProfileService = inject(RegisterVerifyProfileService);
  const verifyMethod = registerVerifyProfileService.getVerificationMethod();

  if (verifyMethod !== null) {
    return true;
  }

  return registerVerifyProfileService.getAvailableVerificationMethods().pipe(
    first(),
    map(() => {
      return true;
    }),
  );
};
