import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';

import { AuthService } from '../services';
import { SYSTEM_ROUTES } from '../../data/constants';
import { AuthStorage } from '../../data/interfaces';

export const logoutGuard: CanActivateFn = () => {
  const router = inject(Router);
  const authService = inject(AuthService);

  const isLoggedIn = localStorage.getItem(AuthStorage.loggedIn);

  if (!isLoggedIn) {
    router.navigate([`./${SYSTEM_ROUTES.login}`]);

    return false;
  }

  localStorage.removeItem(AuthStorage.loggedIn);
  authService.resetLoginAuth();
  authService.resetRegAuth();

  return true;
};
