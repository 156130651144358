import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TrackingDataKeys, TrackingState } from '../../data/interfaces';
import { environment } from '../../../environments/environment';
import { generateId } from '../../data/helpers';

enum TrackingDataStorage {
  trackingData = 'trackingData',
}

interface TrackingData {
  adwords: string;
  referrer: string;
  refid: string;
  oldRefId: string;
}

interface RequestPayload {
  ad: string;
  token: string;
  state: TrackingState;
  old_token?: string;
  regauth?: string;
  project?: number;
}

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  private trackingData: TrackingData = this.getDataFromStorage();

  constructor(private http: HttpClient) {}

  setData(data: Partial<TrackingData>) {
    for (const [key, trackingDataValue] of Object.entries(data)) {
      if (!trackingDataValue) {
        continue;
      }

      const trackingDataKey = key as keyof typeof this.trackingData;
      const currentRefId = this.trackingData.refid;

      if (trackingDataKey === TrackingDataKeys.REF_ID && !currentRefId) {
        this.trackingData[trackingDataKey] = trackingDataValue;
        continue;
      }

      if (
        trackingDataKey === TrackingDataKeys.REF_ID &&
        currentRefId &&
        trackingDataValue !== currentRefId
      ) {
        this.trackingData.oldRefId = currentRefId;
        this.trackingData.refid = trackingDataValue;
        continue;
      }

      if (
        trackingDataKey === TrackingDataKeys.ADWORDS &&
        trackingDataValue &&
        !currentRefId
      ) {
        this.trackingData.adwords = trackingDataValue;
        this.trackingData.refid = generateId(35);
        continue;
      }

      this.trackingData[trackingDataKey] = trackingDataValue;
    }

    localStorage.setItem(
      TrackingDataStorage.trackingData,
      JSON.stringify(this.trackingData),
    );
  }

  getData() {
    return this.trackingData;
  }

  sendAuthTrackingData(regAuthToken: string, msdProjectId: number) {
    const { adwords, refid } = this.trackingData;
    const requestPayload: RequestPayload = {
      ad: adwords,
      token: refid,
      state: TrackingState.converted,
      regauth: regAuthToken,
      project: msdProjectId,
    };

    return this.http.post(
      `${environment.REG_API_URL}/addReferral`,
      requestPayload,
    );
  }

  sendTrackingData(oldRefId: string) {
    const { adwords, refid } = this.trackingData;
    const requestPayload: RequestPayload = {
      ad: adwords,
      token: refid,
      state: TrackingState.register,
      old_token: oldRefId,
    };

    return this.http.post(
      `${environment.HOME_API_URL}/addReferral`,
      requestPayload,
    );
  }

  hasTrackingData() {
    const { adwords, refid } = this.trackingData;

    return !!adwords && !!refid;
  }

  deleteOldRefId() {
    this.trackingData.oldRefId = '';

    const parsedData = JSON.stringify(this.trackingData);

    localStorage.setItem(TrackingDataStorage.trackingData, parsedData);
  }

  private getDataFromStorage(): TrackingData {
    const data = localStorage.getItem(TrackingDataStorage.trackingData);

    if (data === null) {
      return {
        adwords: '',
        refid: '',
        oldRefId: '',
        referrer: '',
      };
    }

    return JSON.parse(data);
  }
}
