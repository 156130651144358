export default {
  aincome_cont_button: 'Mai departe',
  aincome_header: 'Despre dvs.',
  aincome_header_info: 'Care este venitul dvs. anual?',
  app_steps: 'Pasul {{from}} de la {{to}}',
  body_height_cont_button: 'Mai departe',
  body_height_header: 'Despre dvs.',
  body_height_header_info: 'Cât de înalt ești?',
  body_height_km_100: '148 cm',
  body_height_km_101: '149 cm',
  body_height_km_102: '150 cm',
  body_height_km_103: '151 cm',
  body_height_km_104: '152 cm',
  body_height_km_105: '153 cm',
  body_height_km_106: '154 cm',
  body_height_km_107: '155 cm',
  body_height_km_108: '156 cm',
  body_height_km_109: '157 cm',
  body_height_km_110: '158 cm',
  body_height_km_111: '159 cm',
  body_height_km_112: '160 cm',
  body_height_km_113: '161 cm',
  body_height_km_114: '162 cm',
  body_height_km_115: '163 cm',
  body_height_km_116: '164 cm',
  body_height_km_117: '165 cm',
  body_height_km_118: '166 cm',
  body_height_km_119: '167 cm',
  body_height_km_120: '168 cm',
  body_height_km_121: '169 cm',
  body_height_km_122: '170 cm',
  body_height_km_123: '171 cm',
  body_height_km_124: '172 cm',
  body_height_km_125: '173 cm',
  body_height_km_126: '174 cm',
  body_height_km_127: '175 cm',
  body_height_km_128: '176 cm',
  body_height_km_129: '177 cm',
  body_height_km_130: '178 cm',
  body_height_km_131: '179 cm',
  body_height_km_132: '180 cm',
  body_height_km_133: '181 cm',
  body_height_km_134: '182 cm',
  body_height_km_135: '183 cm',
  body_height_km_136: '184 cm',
  body_height_km_137: '185 cm',
  body_height_km_138: '186 cm',
  body_height_km_139: '187 cm',
  body_height_km_140: '188 cm',
  body_height_km_141: '189 cm',
  body_height_km_142: '190 cm',
  body_height_km_143: '191 cm',
  body_height_km_144: '192 cm',
  body_height_km_145: '193 cm',
  body_height_km_146: '194 cm',
  body_height_km_147: '195 cm',
  body_height_km_148: '196 cm',
  body_height_km_149: '197 cm',
  body_height_km_150: '198 cm',
  body_height_km_151: '199 cm',
  body_height_km_152: '200 cm',
  body_height_km_153: '201 cm',
  body_height_km_154: '202 cm',
  body_height_km_155: '203 cm',
  body_height_km_156: '204 cm',
  body_height_km_157: '205 cm',
  body_height_km_158: '206 cm',
  body_height_km_159: '207 cm',
  body_height_km_160: '208 cm',
  body_height_km_161: '209 cm',
  body_height_km_162: '210 cm',
  body_height_km_163: '> 210 cm',
  body_height_km_91: '< 140  cm',
  body_height_km_92: '140 cm',
  body_height_km_93: '141 cm',
  body_height_km_94: '142 cm',
  body_height_km_95: '143  cm',
  body_height_km_96: '144  cm',
  body_height_km_97: '145 cm',
  body_height_km_98: '146 cm',
  body_height_km_99: '147 cm',
  body_height_mi_101: '4\' 11"',
  body_height_mi_104: '5\' 0"',
  body_height_mi_106: '5\' 1"',
  body_height_mi_109: '5\' 2"',
  body_height_mi_112: '5\' 3"',
  body_height_mi_114: '5\' 4"',
  body_height_mi_117: '5\' 5"',
  body_height_mi_119: '5\' 6"',
  body_height_mi_122: '5\' 7"',
  body_height_mi_124: '5\' 8"',
  body_height_mi_127: '5\' 9"',
  body_height_mi_129: '5\' 10"',
  body_height_mi_132: '5\' 11"',
  body_height_mi_134: '6\' 0"',
  body_height_mi_137: '6\' 1"',
  body_height_mi_139: '6\' 2"',
  body_height_mi_142: '6\' 3"',
  body_height_mi_145: '6\' 4"',
  body_height_mi_147: '6\' 5"',
  body_height_mi_150: '6\' 6"',
  body_height_mi_152: '6\' 7"',
  body_height_mi_155: '6\' 8"',
  body_height_mi_157: '6\' 9"',
  body_height_mi_160: '6\' 10"',
  body_height_mi_162: '6\' 11"',
  body_height_mi_163: '> 6\' 11"',
  body_height_mi_91: '< 4\' 8"',
  body_height_mi_94: '4\' 8"',
  body_height_mi_96: '4\' 9"',
  body_height_mi_99: '4\' 10"',
  body_type_cont_button: 'Mai departe',
  body_type_header: 'Despre dvs.',
  body_type_header_info: 'Care este statura dvs. corporală?',
  char_body: 'Statură',
  char_children: 'Copii',
  char_ethnicity: 'Apartenență etnică',
  char_eye_color: 'Culoarea ochilor',
  char_hair_color: 'Culoarea părului',
  char_header: 'Profilul tău',
  char_height: 'Înălțime',
  char_my_height: 'Înălțime',
  char_my_height_bigger: 'sau mai înalt',
  char_my_height_smaller: 'sau mai mic',
  char_smoker: 'Fumător',
  children_cont_button: 'Mai departe',
  children_header: 'Despre dvs.',
  children_header_info: 'Aveți copii?',
  contactwish_answer_more_q: 'Super! Vă rog să răspundeți la încă o întrebare',
  contactwish_answer_q_ph: 'Răspunsul tău....',
  contactwish_cwish: 'Preferințe ale intalnililor',
  contactwish_flirttext: 'Mesaj de flirt',
  contactwish_header: 'Contacte preferate',
  contactwish_header_info:
    'Prezentați-vă personalitatea printr-un mesaj de flirt unic',
  contactwish_input_ph: 'Mesajul tău de flirt ...',
  contactwish_more_about_you: 'Spune mai multe despre tine',
  contactwish_questions: 'Întrebări',
  contactwish_save_btn: 'Salvați',
  contactwish_selec_ph: 'Selectați o întrebare',
  contactwish_what_you_search: 'Ce  cauți aici?',
  cwish_cont_button: 'Mai departe',
  cwish_header: 'Despre dvs.',
  cwish_header_info: 'Ce căutați aici?',
  db_1: 'Niciunul',
  db_10: 'Concerte',
  db_11: 'Teatru',
  db_12: 'Cumpărături',
  db_13: 'Muzică',
  db_14: 'Artă',
  db_15: 'Ieșiri in oraș',
  db_16: 'Desen',
  db_164: 'atletic',
  db_165: 'suplu',
  db_166: 'musculos',
  db_167: 'câteva kilograme în plus',
  db_168: 'Asta voi spune mai târziu.',
  db_169: 'Germană',
  db_17: 'Cinema',
  db_170: 'Italian',
  db_171: 'Franceză',
  db_172: 'Spaniolă',
  db_173: 'Portugheză',
  db_174: 'Engleză',
  db_175: 'Rusă',
  db_176: 'Tenis',
  db_177: 'Golf',
  db_178: 'Navigație',
  db_179: 'Fotbal',
  db_18: 'Sport la TV',
  db_180: 'Canotaj',
  db_181: 'Dans',
  db_182: 'Judo',
  db_183: 'Echitație',
  db_184: 'Badminton',
  db_185: 'Fitness',
  db_186: 'Tenis de masă',
  db_187: 'Jogging',
  db_188: 'Schi',
  db_189: 'Handbal',
  db_19: 'Iesiri la masă',
  db_190: 'Hochei',
  db_191: 'Ciclism',
  db_192: 'Karate',
  db_193: 'Înot',
  db_194: 'Flirt',
  db_195: 'Contact erotic',
  db_198: 'Relație',
  db_2: '1',
  db_20: 'Sport',
  db_204: 'Relație SB / SD',
  db_206: 'Ieșiri la masă/Cumpărături',
  db_207: 'Asiatic',
  db_208: 'Negru / afro-american',
  db_209: 'Latin / Hispanic',
  db_21: 'Baruri',
  db_210: 'Indian',
  db_211: 'din Orientul Mijlociu',
  db_212: 'Mixt',
  db_213: 'Alb/European',
  db_214: 'Altele',
  db_216: 'Poloneză',
  db_217: 'Vânătoare',
  db_218: 'Olandeză',
  db_219: 'Turcă',
  db_22: 'Gătit',
  db_220: 'Ucraineană',
  db_221: 'Maghiară',
  db_222: 'Greacă',
  db_223: 'Cehă',
  db_224: 'Bulgară',
  db_225: 'Suedeză',
  db_226: 'Daneză',
  db_227: 'Norvegiană',
  db_228: 'Finlandeză',
  db_229: 'Chineză',
  db_23: 'Drumeție',
  db_230: 'Yoga',
  db_231: 'Pilates',
  db_232: 'Tai Chi',
  db_233: 'Surfing',
  db_234: 'Volei pe plajă',
  db_235: 'Cățărări',
  db_236: 'Kitesurfing',
  db_237: 'Patinaj pe role',
  db_238: 'Mountainbiking',
  db_239: 'Snowboarding',
  db_24: 'Citit',
  db_240: 'Baschet',
  db_241: 'Baseball',
  db_242: 'Fotbal',
  db_243: 'Pole Dance',
  db_244: 'Zumba',
  db_245: 'Balet',
  db_246: 'Consilier',
  db_247: 'Manager',
  db_248: 'Angajat in funcție de conducere',
  db_249: 'Auditor',
  db_25: 'Mașini',
  db_250: 'Consultant fiscal',
  db_251: 'Informatician',
  db_252: 'Inginer',
  db_253: 'Farmacist',
  db_254: 'Profesor',
  db_255: 'Cercetător științific',
  db_256: 'Profesoară',
  db_257: 'Cercetătoare',
  db_258: 'Avocată',
  db_259: 'Doctoriță',
  db_26: 'Călătorii',
  db_260: 'Sportivă',
  db_261: 'Antrenoare',
  db_262: 'Secretară',
  db_263: 'Educatoare',
  db_264: 'Cosmeticiană',
  db_265: 'Frizeriță',
  db_266: 'Make-up Artist',
  db_267: 'Fizioterapeută',
  db_268: 'Maseuză',
  db_269: 'Model',
  db_27: 'Pictură',
  db_270: 'Bucătareasă',
  db_271: 'Florăreasă',
  db_272: 'Jurnalistă',
  db_273: 'Actriță',
  db_274: 'Grădinărit',
  db_275: 'Sauna',
  db_276: 'Zbor',
  db_277: 'Motocicleta',
  db_278: 'Fotografie',
  db_279: 'Inginer',
  db_28: 'mai puțin de {{amount}}',
  db_280: 'Arabă',
  db_281: 'alta',
  db_282: 'alta',
  db_283: 'alte',
  db_284: 'alte',
  db_285: 'altele',
  db_286: 'Racheta de tenis',
  db_287: 'alte',
  db_29: '{{amount_min}} - {{amount_max}}',
  db_3: '2',
  db_30: '{{amount_min}} - {{amount_max}}',
  db_31: '{{amount_min}} - {{amount_max}}',
  db_32: 'mai mult de {{amount}}',
  db_33: 'Prefer să păstrez asta pentru mine.',
  db_34: 'suficient',
  db_35: 'albastru',
  db_36: 'verde',
  db_37: 'maro',
  db_38: 'gri',
  db_39: 'maro',
  db_4: '3',
  db_40: 'negru',
  db_41: 'roșu',
  db_42: 'argintiu',
  db_43: 'alb',
  db_44: 'blond deschis',
  db_45: 'blond închis',
  db_5: '4',
  db_51: 'mai puțin de {{amount}}',
  db_52: '{{amount_min}} - {{amount_max}}',
  db_53: '{{amount_min}} - {{amount_max}}',
  db_54: '{{amount_min}} - {{amount_max}}',
  db_55: 'mai mult de {{amount}}',
  db_56: 'Prefer să păstrez asta pentru mine.',
  db_57: 'suficient',
  db_58: 'Producător',
  db_59: 'Avocat',
  db_6: '4+',
  db_60: 'Om de afaceri',
  db_61: 'Arhitect',
  db_62: 'Star de televiziune',
  db_63: 'Sportiv profesionist',
  db_64: 'Liber profesionist',
  db_65: 'Bon Vivant',
  db_66: 'Gurmand',
  db_67: 'Artist',
  db_68: 'Bancher',
  db_69: 'Doctor',
  db_7: 'Da',
  db_70: 'Pilot',
  db_71: 'Consultant financiar',
  db_72: 'Broker',
  db_73: 'Altele',
  db_74: 'Consultant management',
  db_75: 'Administrator active',
  db_76: 'Asistentă medicală',
  db_77: 'Stewardesă',
  db_78: 'Funcționar bancar',
  db_79: 'Farmacistă',
  db_8: 'Nu',
  db_81: 'Artistă',
  db_83: 'Manager',
  db_84: 'Practicantă',
  db_85: 'Asistentă',
  db_86: 'Asistentă medicală',
  db_87: 'Studentă',
  db_89: 'Fotograf',
  db_9: 'Uneori',
  db_90: 'Stagiară',
  db_profile_question_female_2: 'Acesta este viitorul la care visez.',
  db_profile_question_female_3: 'Cum îmi imaginez o noapte perfectă.',
  db_profile_question_female_4: 'Ce ți-ai dorit întotdeauna să faci?',
  db_profile_question_female_5: 'Cum te poate cineva fascina?',
  db_profile_question_female_6: 'Care sunt visurile tale?',
  db_profile_question_female_7: 'Ce vă place in mod deosebit ?',
  db_profile_question_male_2:
    'Așa îmi imaginez eu că ar trebui să fie o noapte minunată.',
  db_profile_question_male_3:
    'Mai este ceva ce ar trebui să știți despre mine!',
  db_profile_question_male_4: 'Iată cum aș putea fi surprins!',
  db_profile_question_male_5: 'Întotdeauna mi-am dorit să fac asta!',
  ethnic_group_cont_button: 'Mai departe',
  ethnic_group_header: 'Despre dvs.',
  ethnic_group_header_info: 'Care este etnia dvs.?',
  eye_color_cont_button: 'Mai departe',
  eye_color_header: 'Despre dvs.',
  eye_color_header_info: 'Ce culoare au ochii dvs.?',
  flirt_text_cont_button: 'Mai departe',
  flirt_text_header: 'Despre dvs.',
  flirt_text_header_info: 'Povestiți despre dvs.',
  hair_color_cont_button: 'Mai departe',
  hair_color_header: 'Despre dvs.',
  hair_color_header_info: 'Care este culoarea părului dvs.?',
  into_community_btn: 'Alăturați-vă comunității noastre',
  job_cont_button: 'Mai departe',
  job_header: 'Despre dvs.',
  job_header_info: 'Care este profesia dumneavoastră?',
  languages_cont_button: 'Mai departe',
  languages_header: 'Despre dvs.',
  languages_header_info: 'Ce limbi vorbiți?',
  left_part_header: 'Trăiește-ți propriul stil <br>My Sugar Daddy Lifestyle',
  lifestyle_header: 'Stilul tău de viață',
  lifestyle_nav_activity: 'Timp liber',
  lifestyle_nav_annual_income: 'Venit anual',
  lifestyle_nav_job: 'Profesia',
  lifestyle_nav_lang: 'Limbi straine',
  lifestyle_nav_mbudget: 'Buget lunar',
  lifestyle_nav_pproperty: 'Avere personală',
  lifestyle_nav_sport: 'Sport',
  lifestyle1_cont_button: 'Mai departe',
  lifestyle1_header: 'Despre dvs.',
  lifestyle1_header_info: 'Ce faceți în timpul liber?',
  login_check_stay_login: 'Rămâneți conectat',
  login_err_invalid_mail_or_pw:
    'Vă rugăm să vă verificați numele de utilizator și parola',
  login_err_mail_unknow: 'E-mail necunoscut',
  login_err_no_mail: 'Trebuie  introdusă o adresă de E-mail',
  login_err_no_pw: 'Trebuie să furnizați o parolă',
  login_forgot_pw: 'Ați uitat parola?',
  login_header: 'Bine ați revenit',
  login_input_mail_ph: 'Nume de utilizator/E-mail',
  login_input_pw_ph: 'Parola',
  login_login_btn: 'Autentificare',
  login_login_fb: 'Conectați-vă cu contul de Facebook',
  login_logout_header: '... Sunteți deconectat.',
  login_no_acc: 'Nu aveți încă un cont?',
  login_project_header: 'În ce comunitate ați dori să vă alăturați?',
  login_project_header_info:
    'Vă rugăm să ne spuneți ce ați ales, astfel încât să vă putem prezenta în comunitatea potrivită.',
  login_project_id_0_desc: 'Sugar dating în forma sa clasică',
  login_project_id_0_title: 'MSD classic',
  login_project_id_1_desc: 'Sugar dating pentru comunitatea LGBTQ+',
  login_project_id_1_title: 'MSD gay',
  login_project_id_2_desc: 'Sugar dating cu femei puternice, independente',
  login_project_id_2_title: 'MSD mamas',
  login_project_validation_text:
    'Vă rugăm să selectați unde doriți să vă conectați',
  login_reg: 'Înregistrați-vă acum!',
  login_use_email: 'Autentificare cu E-mail',
  login_want_reg: 'Doriți să vă înregistrați?',
  maintenance_message:
    'Site-ul este în prezent în curs de procesare. Ne cerem scuze pentru neplăcerile create.',
  nav_back_button: 'Înapoi',
  nav_next_button: 'Înainte',
  partner_role_sugarbabe: 'sugarbabe',
  partner_role_sugarboy: 'Sugarboy',
  partner_role_sugardaddy: 'Sugardaddy',
  partner_role_sugarmom: 'Sugarmom',
  pimage_cont_button: 'Mai departe',
  pimage_edit_image_btn: 'Editați',
  pimage_from: 'din',
  pimage_header: 'Fotofrafia ta de profil',
  pimage_header_desc: 'Prezintă varianta ta cea mai buna!',
  pimage_header_info:
    'Extindeți-vă profilul cu cea mai bună  fotografie ,,Selfie,,',
  pimage_modal_close_btn: 'Închideți',
  pimage_modal_header: 'Încărcați fotografia',
  pimage_modal_save_btn: 'Salvați',
  pimage_prev_cont_button: 'Mai departe',
  pimage_prev_header: 'Despre tine',
  pimage_prev_header_desc: 'Minunat! Acesta este profilul tău!',
  pimage_prev_header_info: 'Stilul de viață de înaltă clasă vă așteaptă!',
  pimage_prev_sms_cont_button: 'Alăturați-vă comunității, succes la flirtat!',
  pimage_prev_sms_edit_button: 'Sau completați-vă profilul',
  pimage_prev_sms_header: 'Securitate',
  pimage_prev_sms_header_desc: 'Minunat! Profilul tău este acum verificat!',
  pimage_up_image_btn: 'Încărcați o fotografie acum',
  pproperty_cont_button: 'Mai departe',
  pproperty_header: 'Despre dvs.',
  pproperty_header_info: 'Ce avere dețineți?',
  pw_reset_btn: 'Mai departe',
  pw_reset_desc:
    'Introduceți adresa de E-mail pe care ați folosit-o pentru a vă  înregistra.  Veți primi imediat un e-mail cu pașii următori.',
  pw_reset_err_no_mail: 'Vă rugăm să specificați adresa de E-mail',
  pw_reset_header: 'Securitate',
  pw_reset_input_mail_ph: 'E-mail',
  pw_reset_success_mail:
    'A fost trimis un E- mail cu pașii următori. Vă rugăm să verificați și în spam, dacă nu ați primit E-mailul imediat.',
  pw_rt_btn: 'Confirmați parola',
  pw_rt_desc:
    'Vă rugăm să introduceți o nouă parolă și apoi confirmați parola. Dacă aveți probleme, vă rugăm să contactați support@mysugardaddy.eu',
  pw_rt_header: 'Securitate',
  pw_rt_input_password_conf_ph: 'Repetați parola',
  pw_rt_input_password_equal_err: 'Parolele trebuie să se potrivească',
  pw_rt_input_password_len_err:
    'Parola trebuie să aibă o lungime cuprinsă între 6 și 20 de caractere.',
  pw_rt_input_password_no_pw_err: 'Vă rugăm să introduceți o parolă',
  pw_rt_input_password_ph: 'Parola',
  questions_cont_button: 'Mai departe',
  questions_header: 'Despre dvs.',
  questions_header_info: 'Câteva întrebări la care puteți raspunde',
  reg_already_member: 'Sunteți deja membru?',
  reg_err_select_gender: 'Vă rugăm să indicați sexul dvs.',
  reg_finished_1_success_msg: 'Foarte bine!',
  reg_finished_2_success_msg: 'Ați finalizat configurarea profilului dvs.',
  reg_finished_cont_button: 'Succes la flirtat!',
  reg_finished_header: 'Profil',
  reg_header: 'Înregistrați-vă acum gratuit',
  reg_header_info: 'Eu sunt un',
  reg_header_info_hide: 'Sunt un(o)…',
  reg_login: 'Autentificare',
  reg_or: 'sau',
  reg_prob_modal_body:
    'Din păcate, există o problemă cu contul dvs. Vă rugăm să contactați echipa noastră de asistență, pentru a vă putea ajuta',
  reg_prob_modal_close: 'Închideți',
  reg_prob_modal_header: 'Probleme cu contul  dvs.',
  reg_sugarbabe: 'Sugar Babe',
  reg_sugarbabe_hide: 'Femeie',
  reg_sugardaddy: 'Sugar Daddy',
  reg_sugardaddy_hide: 'Masculin',
  reg_with_apple: 'Înregistrați-vă cu un cont de Apple',
  reg_with_email: 'Înregistați-vă cu E-mailul',
  reg_with_facebook: 'Înregistrați-vă cu contul de Facebook',
  reg_with_google: 'Înregistrați-vă  cu un cont Google',
  regd_18_error: 'Aveți sub 18 ani',
  regd_accept_agb:
    "Prin înregistrare, vă exprimați acordul cu <a href='https://www.mysugardaddy.com/privacy-policy' target='_blank'>Politica de confidențialitate</a> și <a href='https://www.mysugardaddy.com/terms-and-conditions' target='_blank'>Condițiile de utilizare</a> ale MySugardaddy și confirmați ca  aveți peste 18 ani.",
  regd_city_ph: 'Orașul',
  regd_email_ph: 'E-mail',
  regd_err_accept_agb:
    'Trebuie să acceptați ,,Termenii și Condițiile,, noastre',
  regd_err_add_loc: 'Trebuie să furnizați o locație',
  regd_err_add_pw:
    'Parola trebuie să aibă o lungime cuprinsă între 6 și 20 de caractere.',
  regd_err_add_username: 'Trebuie să vă alegeți un pseudonim',
  regd_err_mail_locked: 'Acest e-mail este deja în uz',
  regd_err_no_lat_lng:
    'Locația nu a fost găsită. Vă rugăm să selectați o altă locație',
  regd_err_no_mail: 'Trebuie să furnizați o adresă de E-mail',
  regd_err_recaptcha: 'Captcha trebuie sters',
  regd_eventcode_ph: 'Cod promoțional',
  regd_header_info: 'Fă ca visele sa ți se împlinească!',
  regd_label_birthday: 'Data nașterii',
  regd_label_eventcode: 'Aveți un cod promoțional?',
  regd_modal_btn_babe: 'Eu sunt o Sugar Baby',
  regd_modal_btn_daddy: 'Eu sunt un:  Sugar Daddy',
  regd_modal_conf_text:
    'Dacă este corect,  dați clic pe "Înregistrați-vă gratuit acum". <br> Vă puteți modifica informațiile aici, dacă acestea sunt greșite',
  regd_modal_content:
    '<p>Ești un <b>{{iam}}</b> ?</p><p> Și cauți un <b>{{isearch}}</b> ?</p>',
  regd_modal_header: 'Vă rugăm să verificați din nou informațiile declarate',
  regd_modal_wrong_search: 'Este greșită afirmația de mai sus?',
  regd_months_1: 'Ianuarie',
  regd_months_10: 'Octombrie',
  regd_months_11: 'Noiembrie',
  regd_months_12: 'Decembrie',
  regd_months_2: 'Februarie',
  regd_months_3: 'Martie',
  regd_months_4: 'Aprilie',
  regd_months_5: 'Mai',
  regd_months_6: 'Iunie',
  regd_months_7: 'Iulie',
  regd_months_8: 'August',
  regd_months_9: 'Septembrie',
  regd_password_ph: 'Parola',
  regd_reg_button: 'Înregistrați-vă acum gratuit',
  regd_select_gender: 'Indicați sexul dumneavoastră ...',
  regd_select_gender_err: 'Indicați sexul dvs.',
  regd_select_gender_f: 'Feminin',
  regd_select_gender_m: 'Masculin',
  regd_username_ph: 'Pseudonim',
  regd_want_login: 'Doriți să vă conectați?',
  register_partner_role_list_default_text: 'Vă rugăm să selectați',
  register_partner_role_list_label: 'Caut ...',
  register_partner_role_validation_text:
    'Vă rugăm să specificați pe cine căutați',
  smoker_cont_button: 'Mai departe',
  smoker_header: 'Despre dvs.',
  smoker_header_info: 'Sunteți fumător?',
  sports_cont_button: 'Mai departe',
  sports_header: 'Despre tine',
  sports_header_info: 'Ce sporturi vă interesează?',
  summary_edit_mail_btn: 'Modificați adresa de E-mail',
  summary_edit_profile_btn: 'Sau continuați să vă completați profilul',
  summary_go_to_community_btn: 'Începeți sa flirtați acum!',
  summary_header: 'Securitate',
  summary_header_desc:
    'Este timpul pentru o nou stil de viața! Bine ați venit la MySugardaddy!',
  summary_mail_info:
    '<p>Am trimis la adresa dvs. de e-mail</p><p><b>{{mail}}</b></p><p> un link de verificare.</p>',
  summary_mail_repeat:
    '<p>Vă rugăm să verificați și în spam. Dacă aveți în continuare probleme cu înregistrarea, echipa noastră de asistență va fi bucuroasă să vă ajute la</p><p><span>support@mysugardaddy.eu</span></span></p>',
  summary_modal_body: 'Vă puteți schimba adresa de E-mail aici',
  summary_modal_close: 'Închideți',
  summary_modal_header: 'Schimbați E-mailul',
  summary_modal_save_send_btn: 'Salvați și trimiteți',
  summary_new_veri_mail: 'Noul E-mail de verificare a fost trimis cu succes',
  summary_not_received_btn: 'Nu ați primit încă E-mailul?',
  summary_resend_mail_btn: 'Trimite un nou E-mail',
  summary_sms_btn: 'Verifică acum prin SMS',
  summary_sms_info: 'De asemenea, vă puteți verifica cu numărul de telefon',
  summary_sms_info_success: 'Arătați că meritați încredere.',
  summary_success_header: 'Ați reușit!',
  summary_success_msg: 'Super!',
  summary_veri_mail_success:
    'Adresa dvs. de e-mail a fost verificată cu succes',
  summary_wrong_mail_info: 'Ați introdus o adresă de E-mail greșită?',
  username_cont_button: 'Mai departe',
  username_header: 'Despre tine',
  username_header_info: 'Cum doriți să fiți numit?',
  username_suggestions: 'Iată câteva sugestii',
  verif_prof_cont_button: 'Mai departe',
  verif_prof_explain_info: 'Stilul de viață de înaltă clasă vă așteaptă!',
  verif_prof_header: 'Securitate',
  verif_prof_header_desc: 'Verifică-ți profilul!',
  verif_prof_header_info: 'Cu verificarea prin SMS, vă consolidați profilul',
  verif_prof_verf_email: 'Verificați cu e-mail',
  verif_prof_verf_sms: 'Verifică acum prin SMS',
  verify_phone_c_code_sent_info:
    'Am trimis codul pe numărul dvs. de telefon <b>{{phonenumber}}</b>. Acest proces poate dura până la un minut.',
  verify_phone_c_cont_button: 'Confirmați codul',
  verify_phone_c_error_empty:
    'Vă rugăm să introduceți codul format din 6 cifre',
  verify_phone_c_error_resends_limit:
    'Ceva nu a mers bine, vă rugăm să contactați asistența: support@mysugardaddy.eu',
  verify_phone_c_error_wrong: 'Codul introdus nu este corect',
  verify_phone_c_header: 'Securitate',
  verify_phone_c_header_info: 'Verifică-ți profilul',
  verify_phone_c_intro: 'Vă rugăm să introduceți codul de verificare',
  verify_phone_c_resend_success: 'Un nou cod a fost trimis cu succes',
  verify_phone_c_retry_btn: 'Solicitați încă o dată codul.',
  verify_phone_codeform_error_empty:
    'Vă rugăm să introduceți numărul dvs. de telefon',
  verify_phone_codeform_error_invalid:
    'Vă rugăm să introduceți un număr de telefon valid',
  verify_phone_codeform_nocorrectphonenumbers_err:
    'Numărul dvs. de telefon nu este corect',
  verify_phone_cont_button: 'Verifică-ți profilul',
  verify_phone_header: 'Securitate',
  verify_phone_header_info: 'Verificați-vă profilul',
  verify_phone_input_txt:
    'Veți primi doar un SMS de verificare, noi nu trimitem E-mailuri pentru asta.',
  verify_phone_intro: 'Arată că sunteți un membru verificat!',
};
