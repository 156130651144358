import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SYSTEM_ROUTES } from './data/constants';
import {
  maintenanceGuard,
  rememberedLoginGuard,
  logoutGuard,
  emailVerificationLinkGuard,
  checkMaintenanceStatusGuard,
} from './core/guards';
import { MaintenancePageComponent } from './pages';
import { defaultLangDataResolver, locationResolver } from './core/resolvers';

const { index, registration, login, logout, passwordReset, maintenance } =
  SYSTEM_ROUTES;

const routes: Routes = [
  {
    path: index,
    canActivate: [checkMaintenanceStatusGuard, emailVerificationLinkGuard],
    resolve: {
      location: locationResolver,
    },
    children: [
      {
        path: maintenance,
        component: MaintenancePageComponent,
        canActivate: [maintenanceGuard],
        resolve: {
          lang: defaultLangDataResolver,
        },
      },
      {
        path: registration,
        loadChildren: () =>
          import('./modules/registration/registration.module').then(
            (mod) => mod.RegistrationModule,
          ),
      },
      {
        path: passwordReset,
        loadChildren: () =>
          import('./modules/password-reset/password-reset.module').then(
            (mod) => mod.PasswordResetModule,
          ),
        resolve: {
          lang: defaultLangDataResolver,
        },
      },
      {
        path: login,
        loadChildren: () =>
          import('./modules/login/login.module').then((mod) => mod.LoginModule),
        canActivate: [rememberedLoginGuard],
        resolve: {
          lang: defaultLangDataResolver,
        },
      },
      {
        path: logout,
        loadChildren: () =>
          import('./modules/login/login.module').then((mod) => mod.LoginModule),
        canActivate: [logoutGuard],
        resolve: {
          lang: defaultLangDataResolver,
        },
      },

      {
        path: '**',
        redirectTo: registration,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
