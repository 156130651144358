import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs';
import DeviceFingerprint from 'node_js_ipqs_device_tracker';

import { IpQualityScoreResponse } from '../../data/interfaces';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IpQualityScoreService {
  private readonly secretKey =
    '7zJjfMxGLm8yQ2yuIvcLuunv5WDycvIdAl5h5aE6JQgCmYqSnm3EzXstdF6GqkHnXYh1hPKY04HciErJKYHkHQJrYWLzZvHsxHQtgoZ9AHk71mKhRwhdwrHZyLijomuV7wOx0r9qOBz3oi76gJelQim9wgtjoZdzzWO0f4gEGyEIlO7I5MZkKWQmo4XCEJhjvUSB457Gu7soCYcOzM3ZBLRxd7g5yFVKkttAXXXUOXlre7dTkVoRH8iZxfGZfHAW';

  private readonly serviceUrl = `https://fpdevice.sdc-ventures.com/api/*/${this.secretKey}/learn.js`;

  constructor(private http: HttpClient) {}

  async sendDeviceData() {
    try {
      const script = document.createElement('script');

      script.type = 'text/javascript';
      script.src = this.serviceUrl;
      script.crossOrigin = 'anonymous';
      script.onload = () => {
        DeviceFingerprint.AfterResult(this.sendDataToApi.bind(this));
        DeviceFingerprint.Init();
      };

      document.body.insertAdjacentElement('beforeend', script);
    } catch (error) {
      /* empty */
    }
  }

  private sendDataToApi(data?: IpQualityScoreResponse) {
    if (data === undefined) {
      return;
    }

    this.http
      .post(`${environment.REG_API_URL}/addFPDevice`, data)
      .pipe(first())
      .subscribe();
  }
}
