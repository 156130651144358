import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { first, map } from 'rxjs';

import { AuthService, LangService } from '../services';
import { SYSTEM_ROUTES } from '../../data/constants';
import {
  RegisterDataService,
  RegisterNavigationService,
} from '../../modules/registration/services';

export const authGuard: CanActivateFn = (route) => {
  const queryParams = route.queryParams;

  const router = inject(Router);
  const authService = inject(AuthService);
  const registerService = inject(RegisterDataService);
  const registerNavigationService = inject(RegisterNavigationService);
  const registerDataService = inject(RegisterDataService);
  const langService = inject(LangService);

  const { register } = authService.getToken();
  const isAuth = authService.isRegAuthenticated();

  if (isAuth) {
    return true;
  }

  if (!register) {
    router.navigate([SYSTEM_ROUTES.login]);

    return false;
  }

  return registerService.getUserProfile().pipe(
    first(),
    map((response) => {
      const result = registerService.parseCheckCurrentUserResponse(response);

      if (!result) {
        authService.resetRegAuth();
        router.navigate([SYSTEM_ROUTES.login]);

        return false;
      }

      authService.setRegisterAuth(true);
      registerDataService.setUserProfile(result);
      langService.updateTranslationData();
      registerNavigationService.goToStep(result.regstep, false, queryParams);

      return true;
    }),
  );
};
