import { Component } from '@angular/core';

import { LangService } from '../../core/services';

@Component({
  selector: 'app-maintenance-page',
  templateUrl: './maintenance-page.component.html',
  styleUrls: ['./maintenance-page.component.scss'],
})
export class MaintenancePageComponent {
  constructor(public langService: LangService) {}
}
