<header class="header" data-cy="headerComponent">
  <a class="header__link-box" [routerLink]="systemRoutes.index">
    <img
      *ngIf="!dataStorageService.isHiddenMode()"
      class="header__logo"
      [src]="dataStorageService.getLogo()"
      width="300"
      height="21"
      alt="MSD Logo"
      data-cy="notHiddenLogo"
    />
    <img
      class="header__logo-short"
      *ngIf="dataStorageService.isHiddenMode()"
      [src]="shortLogo"
      width="106"
      alt="MSD Logo"
      data-cy="hiddenLogo"
    />
  </a>
</header>
