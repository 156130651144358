export enum GenderEnum {
  male = 'male',
  female = 'female',
}

export type Gender = null | GenderEnum;

export enum DataStorageProperties {
  PARTNER_ROLE = 'partnerRole',
  MSD_PROJECT_ID = 'msdProjectId',
  GENDER = 'gender',
  LOGINSITE = 'loginsite',
  IS_HIDDEN_MODE = 'isHiddenMode',
}

export enum PartnerRoles {
  sugarBabe = 'sugarbabe',
  sugarMom = 'sugarmom',
  sugarDaddy = 'sugardaddy',
  sugarBoy = 'sugarboy',
}

export enum AvailableLoginSites {
  cl = 'www.mysugardaddy.cl',
  br = 'www.mysugardaddy.com.br',
  ar = 'www.mysugardaddy.com.ar',
  es = 'www.mysugardaddy.es',
  mx = 'www.mysugardaddy.mx',
  co = 'www.mysugardaddy.co',
  com = 'www.mysugardaddy.com',
  at = 'www.mysugardaddy.at',
  de = 'www.mysugardaddy.de',
  ch = 'www.mysugardaddy.ch',
  eu = 'www.mysugardaddy.eu',
  fr = 'www.mysugardaddy.fr',
  be = 'www.mysugardaddy.be',
  it = 'www.mysugardaddy.it',
  uk = 'www.my-sugar-daddy.co.uk',
  pl = 'www.mysugardaddy.pl',
  pt = 'www.mysugardaddy.pt',
  nl = 'www.mysugardaddy.nl',
  se = 'www.mysugardaddy.se',
  ro = 'www.mysugardaddy.ro',
  tr = 'www.mysugardaddy.com.tr',
  jp = 'www.mysugardaddy.jp',
  gayEu = 'www.mygaysugardaddy.eu',
  gayCom = 'www.mygaysugardaddy.com',
  datesDe = 'www.msd-dates.de',
  datesCom = 'www.msd-dates.com',
  sdFr = 'www.sugardaddy.fr',
}
