import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs';

import { environment } from '../../../environments/environment';
import { BaseResponse } from '../../data/interfaces';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceService {
  private isMaintenance: boolean | null = null;

  constructor(private http: HttpClient) {}

  checkMaintenanceStatus() {
    return this.http
      .post<BaseResponse<boolean>>(
        `${environment.HOME_API_URL}/isMaintenance`,
        {},
      )
      .pipe(
        tap(({ data }) => {
          this.isMaintenance = data;
        }),
      );
  }

  getMaintenanceStatus() {
    return this.isMaintenance;
  }
}
