import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';

import { DataStorageService } from '../services';
import { SYSTEM_ROUTES } from '../../data/constants';

export const registerDetailsGuard: CanActivateFn = () => {
  const router = inject(Router);
  const dataStorageService = inject(DataStorageService);

  const { gender, partnerRole, msdProjectId } =
    dataStorageService.getDataStorage();

  if (gender && partnerRole && msdProjectId !== null) {
    return true;
  }

  router.navigate([SYSTEM_ROUTES.registration]);

  return false;
};
