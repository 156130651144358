import { Injectable, signal } from '@angular/core';

import {
  AvailableLoginSites,
  DataStorageProperties,
  Gender,
  GenderEnum,
  PartnerRoles,
} from '../../data/interfaces';
import { DEFAULT_LOGIN_SITE } from '../../data/constants';
import { HIDDEN_MODE_LOGIN_SITES } from '../../data/constants/loginSites';
import { msdProjectHelper } from '../../data/helpers';
import { DEFAULT_MSD_PROJECT, MSD_PROJECT } from '../../data/config';

interface QueryParams {
  gender: string | null;
  loginsite: string;
  partnerRole: string | null;
}

interface DataStorage {
  msdProjectId: number | null;
  gender: Gender;
  loginsite: string;
  isHiddenMode: boolean;
  partnerRole: PartnerRoles | null;
}

@Injectable({
  providedIn: 'root',
})
export class DataStorageService {
  private readonly availableLoginSites = Object.values(AvailableLoginSites);

  private dataStorage: DataStorage = {
    partnerRole: null,
    msdProjectId: null,
    isHiddenMode: false,
    gender: null,
    loginsite:
      localStorage.getItem(DataStorageProperties.LOGINSITE) ||
      DEFAULT_LOGIN_SITE,
  };

  private logo = signal('');

  constructor() {
    this.setDefaultLogo();
  }

  initStorage({ gender, loginsite, partnerRole }: QueryParams) {
    this.setGender(gender as Gender | null);
    this.setLoginSite(loginsite);

    if (
      this.dataStorage.partnerRole ||
      this.dataStorage.msdProjectId !== null
    ) {
      return;
    }

    this.dataStorage.msdProjectId = msdProjectHelper.getMsdProjectId(
      gender,
      partnerRole,
    );

    this.setLogo();

    this.dataStorage.partnerRole =
      msdProjectHelper.getCorrectPartnerRole(partnerRole);
  }

  setRegisterMode(loginSite = DEFAULT_LOGIN_SITE) {
    const hostname = window.location.hostname as AvailableLoginSites;

    this.dataStorage.isHiddenMode =
      !!HIDDEN_MODE_LOGIN_SITES.find((als) => als.includes(hostname)) ||
      HIDDEN_MODE_LOGIN_SITES.includes(loginSite);
  }

  getDataStorage(): DataStorage {
    return this.dataStorage;
  }

  isHiddenMode() {
    return this.dataStorage.isHiddenMode;
  }

  updateDataStorage(newData: Partial<DataStorage>) {
    this.dataStorage = {
      ...this.dataStorage,
      ...newData,
    };

    if ('msdProjectId' in newData) {
      this.setLogo();
    }
  }

  getLogo() {
    return this.logo();
  }

  setLogo() {
    const projectId = this.dataStorage.msdProjectId;

    if (projectId === null) {
      return;
    }

    const { logo } = msdProjectHelper.getById(projectId);

    if (!logo) {
      this.logo.set(DEFAULT_MSD_PROJECT.logo);

      return;
    }

    this.logo.set(logo);
  }

  private setGender(gender: Gender | null) {
    const isSupported = Object.values(GenderEnum).includes(
      gender?.toLowerCase() as GenderEnum,
    );

    if (!isSupported) {
      return;
    }

    this.dataStorage.gender =
      gender === GenderEnum.male ? GenderEnum.male : GenderEnum.female;
  }

  private setLoginSite(loginsite: string) {
    const ls = loginsite.toLowerCase() as AvailableLoginSites;

    if (!ls) {
      return;
    }

    if (this.availableLoginSites.includes(ls)) {
      this.dataStorage.loginsite = ls;
      localStorage.setItem(DataStorageProperties.LOGINSITE, ls);
      this.setRegisterMode(ls);
    }
  }

  private setDefaultLogo() {
    const url = window.location.hostname;

    if (MSD_PROJECT.gay.domains.includes(url)) {
      this.logo.set(MSD_PROJECT.gay.logo);

      return;
    }

    this.logo.set(DEFAULT_MSD_PROJECT.logo);
  }
}
