import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

@Injectable()
export class FormDataInterceptor implements HttpInterceptor {
  private readonly jsonEndpoints = [
    environment.NEW_HOME_API_URL_V1,
    environment.NEW_REG_API_URL_V1,
  ];

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const isJsonEndpoint = this.jsonEndpoints.some((endpoint) =>
      request.url.includes(endpoint),
    );

    if (isJsonEndpoint || request.method !== 'POST' || !request.body) {
      return next.handle(request);
    }

    const formData = new FormData();
    const body = request.body as Record<string, unknown>;

    for (const [key, value] of Object.entries(body)) {
      const isArray = Array.isArray(value);

      if (!isArray) {
        formData.append(key, value as never);

        continue;
      }

      value.forEach((item) => {
        const arr = `${key}[]`;

        formData.append(arr, item);
      });
    }

    const req = request.clone({
      body: formData,
    });

    return next.handle(req);
  }
}
