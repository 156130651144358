import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FingerPrintService {
  private readonly key = 'Psow6u8apg2e48qSi2sx';

  private readonly fpUrl = 'https://fpregister.mysugardaddy.com';

  constructor(private http: HttpClient) {}

  async sendUserId(userToken: string, msdProjectId: number) {
    try {
      const fp = await FingerprintJS.load({
        apiKey: this.key,
        endpoint: this.fpUrl,
      });

      const result = await fp.get();
      const data = {
        regauth: userToken,
        fp: result.visitorId,
        project: msdProjectId,
      };

      this.http
        .post(`${environment.REG_API_URL}/addFP`, data)
        .pipe(first())
        .subscribe();
    } catch (error) {
      /* empty */
    }
  }
}
