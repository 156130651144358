import { Pipe, PipeTransform } from '@angular/core';

import { Langs, TranslationKeys } from '../../data/interfaces';
import { DEFAULT_LANG } from '../../data/constants';
import { LangService } from '../../core/services';

type Params = Record<string, string> | null;

@Pipe({
  name: 'translate',
})
export class TranslatePipe implements PipeTransform {
  private readonly lang;

  private readonly keys;

  constructor(private langService: LangService) {
    this.lang = langService.getCurrentLang();
    this.keys = langService.getCurrentTranslationKeys();
  }

  transform(value: string, lang: Langs, params: Params = null) {
    return this.translate(value, lang, params);
  }

  translate(key: string, lang: Langs, params: Params) {
    const currentLang = lang as keyof TranslationKeys;
    const messages: Record<string, string> =
      this.keys[currentLang] || this.keys[DEFAULT_LANG];

    if (params === null) {
      return messages[key];
    }

    let message = messages[key];

    for (const [param, value] of Object.entries(params)) {
      if (!param || !value) {
        continue;
      }

      const regex = new RegExp(`{{\\s*(${param})\\s*}}`, 'g');

      message = message.replace(regex, value);
    }

    return message;
  }
}
