import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';

import { ApiModules } from '../../../data/interfaces';
import { AuthService } from '../../services';
import { SYSTEM_ROUTES } from '../../../data/constants';

interface ApiResponse {
  error?: boolean | number;
  module?: ApiModules | string;
}

@Injectable()
export class GlobalApiErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap({
        next: (data) => {
          const hasBody = 'body' in data;

          if (!hasBody || data.body === null) {
            return;
          }

          const { error, module } = data.body as ApiResponse;

          if (error && module === ApiModules.session) {
            this.leaveRegister();
          }
        },
        error: ({ status }: HttpErrorResponse) => {
          switch (status) {
            case 401:
              this.leaveRegister();
          }
        },
      }),
    );
  }

  private leaveRegister() {
    this.authService.resetRegAuth();
    this.authService.resetLoginAuth();
    this.router.navigate([SYSTEM_ROUTES.index]);
  }
}
