import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { first, map } from 'rxjs';

import { RegisterStep } from '../../data/interfaces';
import { AuthService } from '../services';
import {
  RegisterNavigationService,
  RegisterVerifyProfileService,
} from '../../modules/registration/services';

export const emailVerificationLinkGuard: CanActivateFn = (route) => {
  const router = inject(Router);
  const authService = inject(AuthService);
  const registerNavigationService = inject(RegisterNavigationService);
  const registerVerifyProfileService = inject(RegisterVerifyProfileService);

  const registerToken = route.queryParams['token'] || '';
  const mailToken = route.queryParams['mailtoken'] || '';

  if (!registerToken || !mailToken) {
    return true;
  }

  return registerVerifyProfileService
    .verifyEmail(mailToken, registerToken)
    .pipe(
      first(),
      map(({ data }) => {
        router.navigate([], {
          queryParams: {
            token: undefined,
            mailtoken: undefined,
          },
          queryParamsHandling: 'merge',
        });

        if (data.checked) {
          authService.setRegisterToken(registerToken);
          registerNavigationService.updateApiRegisterStep(
            RegisterStep.verifyProfileEmail,
          );
          registerNavigationService.goToStep(
            RegisterStep.verifyProfileEmail,
            false,
          );
        }

        return true;
      }),
    );
};
