import { Langs } from './langs';
import { UserProfile } from './userProfile';

export interface RegisterDetailsFormData {
  email: string;
  password: string;
  dateOfBirth: string;
  promoCode: string;
  conditions: boolean;
  city: string;
  longitude: string;
  latitude: string;
  country: string;
  searchUserCityGoogleReg: string;
  recaptcha: string | null;
}

export interface RegisterDetailsData {
  gui_lang: Langs;
  zipcode: string;
  legaltxt: string;
  search_user_city_google_reg: string;
  birthday: string;
  pseudonym: string;
  sponsor: '1' | '2';
  tsep: string;
  dsep: string;
  regstep: string;
  regsubstep: number;
  eventcode: string;
  captcha: string;
  project: number;
  domain: string;
  latitude: string;
  longitude: string;
  country: string;
  city: string;
  email: string;
  adwords: string;
  referrer: string;
  password?: string;
  accessToken?: string;
}

export enum RegisterStep {
  job = 'job',
  sports = 'sports',
  children = 'children',
  bodyType = 'body-type',
  languages = 'languages',
  activities = 'activities',
  registrationFinished = 'registration-finished',
  profileimage = 'profileimage',
  ethnicGroup = 'ethnic-group',
  verifyProfilePhoneCode = 'verify-profile-phone-code',
  verifyProfile = 'verify-profile',
  username = 'username',
  questions = 'questions',
  smoker = 'smoker',
  verifyProfilePhone = 'verify-profile-phone',
  contactWish = 'contact-wish',
  verifyProfileEmail = 'verify-profile-email',
  profileImagePreview = 'profileimage-preview',
  profilePreviewSms = 'profile-preview-sms',
  eyeColor = 'eye-color',
  hairColor = 'hair-color',
  bodyHeight = 'body-height',
  flirtText = 'flirt-text',
  privateProperty = 'private-property',
  annualIncome = 'annual-income',
}

export interface ParsedCheckUsernameResponse {
  isValid: boolean;
  errorMessage: string;
  suggestions: string[];
}

export enum VerificationMethod {
  all = 'all',
  mail = 'mail',
  sms = 'sms',
}

export interface VerificationMethodData {
  hasSms: boolean;
  hasMail: boolean;
}

export type VerificationMethodDataType = VerificationMethodData | null;

export interface ChangePhoneNumberResult {
  isValid: boolean;
  phoneNumber: string;
}

export enum RequestIdentifiers {
  contactWish = 'contactwish',
  recreation = 'recreation',
  sport = 'sport',
  languages = 'spoken_languages',
  profession = 'profession',
  privateProperty = 'private_property',
  annualIncome = 'annual_income',
  ethnicGroup = 'ethnic_group',
  hairColor = 'hair_color',
  eyeColor = 'eye_color',
  figure = 'figure',
  children = 'children',
  smoking = 'smoking',
  bodyHeight = 'body_height',
}

export type UserProfileFieldToUpdate = keyof UserProfile | null;
export enum RegisterStepListItemsStyle {
  single = 'single',
  double = 'double',
}

export interface ProgressBarData {
  currentFillLevel: number;
  totalLevels: number;
}
