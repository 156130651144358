import { RegisterStep } from '../interfaces';

export const DEFAULT_STEP_ORDER: readonly RegisterStep[] = [
  RegisterStep.username,
  RegisterStep.profileimage,
  RegisterStep.profileImagePreview,
  RegisterStep.verifyProfile,
];

export const SMS_SUB_STEPS: readonly RegisterStep[] = [
  RegisterStep.verifyProfilePhone,
  RegisterStep.verifyProfilePhoneCode,
  RegisterStep.profilePreviewSms,
];

export const MAIL_SUB_STEPS: readonly RegisterStep[] = [
  RegisterStep.verifyProfileEmail,
];

export const COMPLETE_REGISTER_STEPS: readonly RegisterStep[] = [
  RegisterStep.contactWish,
  RegisterStep.activities,
  RegisterStep.sports,
  RegisterStep.languages,
  RegisterStep.job,
  RegisterStep.privateProperty,
  RegisterStep.annualIncome,
  RegisterStep.ethnicGroup,
  RegisterStep.hairColor,
  RegisterStep.eyeColor,
  RegisterStep.bodyType,
  RegisterStep.children,
  RegisterStep.smoker,
  RegisterStep.bodyHeight,
  RegisterStep.flirtText,
  RegisterStep.questions,
  RegisterStep.registrationFinished,
];

export const COMPLETE_REGISTER_STEPS_ONLY_MALE: readonly RegisterStep[] = [
  RegisterStep.privateProperty,
  RegisterStep.annualIncome,
];
