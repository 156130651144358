export default {
  aincome_cont_button: 'Avanti',
  aincome_header: 'Su di te',
  aincome_header_info: 'Qual è il tuo reddito annuo?',
  app_steps: 'Step {{from}} di {{to}}',
  body_height_cont_button: 'Avanti',
  body_height_header: 'Su di te',
  body_height_header_info: 'Quanto sei alto?',
  body_height_km_100: '148 CM',
  body_height_km_101: '149 CM',
  body_height_km_102: '150 CM',
  body_height_km_103: '151 CM',
  body_height_km_104: '152 CM',
  body_height_km_105: '153 CM',
  body_height_km_106: '154 CM',
  body_height_km_107: '155 CM',
  body_height_km_108: '156 CM',
  body_height_km_109: '157 CM',
  body_height_km_110: '158 CM',
  body_height_km_111: '159 CM',
  body_height_km_112: '160 CM',
  body_height_km_113: '161 CM',
  body_height_km_114: '162 CM',
  body_height_km_115: '163 CM',
  body_height_km_116: '164 CM',
  body_height_km_117: '165 CM',
  body_height_km_118: '166 CM',
  body_height_km_119: '167 CM',
  body_height_km_120: '168 CM',
  body_height_km_121: '169 CM',
  body_height_km_122: '170 CM',
  body_height_km_123: '171 CM',
  body_height_km_124: '172 CM',
  body_height_km_125: '173 CM',
  body_height_km_126: '174CM',
  body_height_km_127: '175 CM',
  body_height_km_128: '176 CM',
  body_height_km_129: '177 CM',
  body_height_km_130: '178 CM',
  body_height_km_131: '179 CM',
  body_height_km_132: '180 CM',
  body_height_km_133: '181 CM',
  body_height_km_134: '182 CM',
  body_height_km_135: '183 CM',
  body_height_km_136: '184 CM',
  body_height_km_137: '185 CM',
  body_height_km_138: '186 CM',
  body_height_km_139: '187 CM',
  body_height_km_140: '188 CM',
  body_height_km_141: '189 CM',
  body_height_km_142: '190 CM',
  body_height_km_143: '191 CM',
  body_height_km_144: '192 CM',
  body_height_km_145: '193 CM',
  body_height_km_146: '194 CM',
  body_height_km_147: '195 CM',
  body_height_km_148: '196 CM',
  body_height_km_149: '197 CM',
  body_height_km_150: '198 CM',
  body_height_km_151: '199 CM',
  body_height_km_152: '200 CM',
  body_height_km_153: '201 CM',
  body_height_km_154: '202 CM',
  body_height_km_155: '203 CM',
  body_height_km_156: '204 CM',
  body_height_km_157: '205 CM',
  body_height_km_158: '206 CM',
  body_height_km_159: '207 CM',
  body_height_km_160: '208 CM',
  body_height_km_161: '209 CM',
  body_height_km_162: '210 CM',
  body_height_km_163: '> 210 CM',
  body_height_km_91: '< 140 CM',
  body_height_km_92: '140 CM',
  body_height_km_93: '141 CM',
  body_height_km_94: '142 CM',
  body_height_km_95: '143 CM',
  body_height_km_96: '144 CM',
  body_height_km_97: '145 CM',
  body_height_km_98: '146 CM',
  body_height_km_99: '147 CM',
  body_height_mi_101: '4\' 11"',
  body_height_mi_104: '5\' 0"',
  body_height_mi_106: '5\' 1"',
  body_height_mi_109: '5\' 2"',
  body_height_mi_112: '5\' 3"',
  body_height_mi_114: '5\' 4"',
  body_height_mi_117: '5\' 5"',
  body_height_mi_119: '5\' 6"',
  body_height_mi_122: '5\' 7"',
  body_height_mi_124: '5\' 8"',
  body_height_mi_127: '5\' 9"',
  body_height_mi_129: '5\' 10"',
  body_height_mi_132: '5\' 11"',
  body_height_mi_134: '6\' 0"',
  body_height_mi_137: '6\' 1"',
  body_height_mi_139: '6\' 2"',
  body_height_mi_142: '6\' 3"',
  body_height_mi_145: '6\' 4"',
  body_height_mi_147: '6\' 5"',
  body_height_mi_150: '6\' 6"',
  body_height_mi_152: '6\' 7"',
  body_height_mi_155: '6\' 8"',
  body_height_mi_157: '6\' 9"',
  body_height_mi_160: '6\' 10"',
  body_height_mi_162: '6\' 11"',
  body_height_mi_163: '> 6\' 11"',
  body_height_mi_91: '< 4\' 8"',
  body_height_mi_94: '4\' 8"',
  body_height_mi_96: '4\' 9"',
  body_height_mi_99: '4\' 10"',
  body_type_cont_button: 'Avanti',
  body_type_header: 'Su di te',
  body_type_header_info: 'Che corporatura hai?',
  char_body: 'Corporatura',
  char_children: 'Figli',
  char_ethnicity: 'Appartenenza etnica',
  char_eye_color: 'Colore degli occhi',
  char_hair_color: 'Colore dei capelli',
  char_header: 'Scheda segnaletica',
  char_height: 'Altezza',
  char_my_height: 'La mia altezza',
  char_my_height_bigger: 'o più grande',
  char_my_height_smaller: 'o più piccola',
  char_smoker: 'Fumatore',
  children_cont_button: 'Avanti',
  children_header: 'Su di te',
  children_header_info: 'Hai figli?',
  contactwish_answer_more_q: "Fantastico! Rispondi ad un'altra domanda",
  contactwish_answer_q_ph: 'Seleziona una domanda...',
  contactwish_cwish: 'Tipo di relazione desiderata',
  contactwish_flirttext: 'Messaggio di corteggiamento',
  contactwish_header: 'Tipo di relazione desiderata',
  contactwish_header_info:
    'Mostra la tua personalità scrivendo un messaggio di corteggiamento',
  contactwish_input_ph: 'Il tuo messaggio di corteggiamento...',
  contactwish_more_about_you: 'Dicci di più su di te',
  contactwish_questions: 'Domande',
  contactwish_save_btn: 'Salva',
  contactwish_selec_ph: 'Seleziona la tua domanda',
  contactwish_what_you_search: 'Cosa desideri trovare qui?',
  cwish_cont_button: 'Avanti',
  cwish_header: 'Su di te',
  cwish_header_info: 'Cosa cerchi?',
  db_1: 'Nessuno',
  db_10: 'Concerti',
  db_11: 'Teatro',
  db_12: 'Shopping',
  db_13: 'Musica',
  db_14: 'Arte',
  db_15: 'Uscire',
  db_16: 'Disegnare',
  db_164: 'Atleta',
  db_165: 'magro',
  db_166: 'muscoloso',
  db_167: 'con qualche chilo in più',
  db_168: 'Te lo dico dopo',
  db_169: 'Tedesco',
  db_17: 'Cinema',
  db_170: 'Italiano',
  db_171: 'Francese',
  db_172: 'Spagnolo',
  db_173: 'Portoghese',
  db_174: 'Inglese',
  db_175: 'Russo',
  db_176: 'Tennis',
  db_177: 'Golf',
  db_178: 'Vela',
  db_179: 'Calcio',
  db_18: 'Sport in TV',
  db_180: 'Canottaggio',
  db_181: 'Ballare',
  db_182: 'judo',
  db_183: 'Equitazione',
  db_184: 'Badminton',
  db_185: 'Fitness',
  db_186: 'Ping pong',
  db_187: 'Jogging',
  db_188: 'Sciare',
  db_189: 'Pallamano',
  db_19: 'Uscire a cena',
  db_190: 'Hockey',
  db_191: 'Ciclismo',
  db_192: 'Karate',
  db_193: 'Nuoto',
  db_194: 'Flirtare',
  db_195: 'Contatto erotico',
  db_198: 'Relazione',
  db_2: '1',
  db_20: 'Sport',
  db_204: 'Relazione SD/SB',
  db_206: 'Mangiare fuori/ fare shopping',
  db_207: 'Asiatica',
  db_208: 'Nera / afroamericana',
  db_209: 'Latina/ispanica',
  db_21: 'Bar',
  db_210: 'Indiana',
  db_211: 'Mediorientale',
  db_212: 'Mista',
  db_213: 'Bianca / europea',
  db_214: 'Altro',
  db_216: 'Polacco',
  db_217: 'Caccia',
  db_218: 'Olandese',
  db_219: 'Turco',
  db_22: 'Cucinare',
  db_220: 'Ucraino',
  db_221: 'Ungherese',
  db_222: 'Greco',
  db_223: 'Ceco',
  db_224: 'Bulgaro',
  db_225: 'Svedese',
  db_226: 'Danese',
  db_227: 'Norvegese',
  db_228: 'Finlandese',
  db_229: 'Cinese',
  db_23: 'Escursioni',
  db_230: 'Yoga',
  db_231: 'Pilates',
  db_232: 'Tai Chi',
  db_233: 'Surf',
  db_234: 'Beach Volleyball',
  db_235: 'Arrampicata',
  db_236: 'Kitesurfing',
  db_237: 'Pattini a linea',
  db_238: 'Mountainbiking',
  db_239: 'Snowboarding',
  db_24: 'Leggere',
  db_240: 'Basketball',
  db_241: 'Baseball',
  db_242: 'Football',
  db_243: 'Pole Dance',
  db_244: 'Zumba',
  db_245: 'Danza classica',
  db_246: 'Consulente',
  db_247: 'Manager',
  db_248: 'Direttore',
  db_249: 'Auditor',
  db_25: 'Auto',
  db_250: 'Consulente finanziario',
  db_251: 'Informatico',
  db_252: 'Ingegnere',
  db_253: 'Farmacista',
  db_254: 'Insegnante',
  db_255: 'Ricercatore',
  db_256: 'Insegnante',
  db_257: 'Ricercatore',
  db_258: 'Avvocato',
  db_259: 'Medico',
  db_26: 'Viaggiare',
  db_260: 'Atleta',
  db_261: 'Apprendista',
  db_262: 'Segretaria',
  db_263: 'Educatrice',
  db_264: 'Estetista',
  db_265: 'Parrucchiera',
  db_266: 'Make-up Artist',
  db_267: 'Psicoterapista',
  db_268: 'Massaggiatrice',
  db_269: 'Modella',
  db_27: 'Dipingere',
  db_270: 'Cuoca',
  db_271: 'Fioraia',
  db_272: 'Giornalista',
  db_273: 'Attrice',
  db_274: 'Giardinaggio',
  db_275: 'Sauna',
  db_276: 'Viaggiare in aereo',
  db_277: 'Andare in moto',
  db_278: 'Fotografia',
  db_279: 'Ingegnere',
  db_28: 'meno di {{amount}}',
  db_280: 'Arabo',
  db_281: 'Altro',
  db_282: 'Altro',
  db_283: 'Altro',
  db_284: 'Altro',
  db_285: 'Altro',
  db_286: 'Racquetball',
  db_287: 'Altro',
  db_29: '{{amount_min}} - {{amount_max}}',
  db_3: '2',
  db_30: '{{amount_min}} - {{amount_max}}',
  db_31: '{{amount_min}} - {{amount_max}}',
  db_32: 'più di {{amount}}',
  db_33: 'preferisco tenermelo per me',
  db_34: 'abbastanza',
  db_35: 'blu',
  db_36: 'verde',
  db_37: 'marrone',
  db_38: 'grigio',
  db_39: 'marrone',
  db_4: '3',
  db_40: 'nero',
  db_41: 'rosso',
  db_42: 'argentato',
  db_43: 'bianco',
  db_44: 'biondo chiaro',
  db_45: 'biondo scuro',
  db_5: '4',
  db_51: 'meno di {{amount}}',
  db_52: '{{amount_min}} - {{amount_max}}',
  db_53: '{{amount_min}} - {{amount_max}}',
  db_54: '{{amount_min}} - {{amount_max}}',
  db_55: 'più di {{amount}}',
  db_56: 'preferisco tenermelo per me',
  db_57: 'abbastanza',
  db_58: 'Produttore',
  db_59: 'Avvocato',
  db_6: '4+',
  db_60: 'Imprenditore',
  db_61: 'Architetto',
  db_62: 'Star televisiva',
  db_63: 'Atleta professionista',
  db_64: 'Lavoratore indipendente',
  db_65: 'Bon Vivant',
  db_66: 'Gourmet',
  db_67: 'Artista',
  db_68: 'Banchere',
  db_69: 'Medico',
  db_7: 'Sì',
  db_70: 'Pilota',
  db_71: 'Consulente',
  db_72: 'Investitore',
  db_73: 'Altro',
  db_74: 'Consulente gestionale',
  db_75: 'Asset Manager',
  db_76: 'Infermiere/a',
  db_77: 'Hostess',
  db_78: 'Impiegato bancario',
  db_79: 'Farmacista',
  db_8: 'No',
  db_81: 'Artista',
  db_83: 'Manager',
  db_84: 'Alumna',
  db_85: 'Assistente',
  db_86: 'Assistente medico',
  db_87: 'Studente',
  db_89: 'Fotografo',
  db_9: 'Ogni tanto',
  db_90: 'Apprendista',
  db_profile_question_female_2: 'Questo è il futuro che sogno',
  db_profile_question_female_3: 'Come mi immagino la serata perfetta',
  db_profile_question_female_4: 'Cosa hai sempre desiderato di fare?',
  db_profile_question_female_5: 'Cosa dovrei fare per conquistarti?',
  db_profile_question_female_6: 'Quali sono i tuoi sogni?',
  db_profile_question_female_7: 'Cosa ti piace?',
  db_profile_question_male_2:
    'Questo è come una bellissima serata dovrebbe essere',
  db_profile_question_male_3: "Ecco un'altra cosa che dovresti sapere di me",
  db_profile_question_male_4: 'Ecco come puoi sorprendermi',
  db_profile_question_male_5: 'Ho sempre sognato di farlo un giorno',
  ethnic_group_cont_button: 'Avanti',
  ethnic_group_header: 'Su di te',
  ethnic_group_header_info: 'A quale gruppo etnico appartieni?',
  eye_color_cont_button: 'Avanti',
  eye_color_header: 'Su di te',
  eye_color_header_info: 'Qual è il colore dei tuoi occhi?',
  flirt_text_cont_button: 'Avanti',
  flirt_text_header: 'Su di te',
  flirt_text_header_info: 'Dicci di più su di te',
  hair_color_cont_button: 'Avanti',
  hair_color_header: 'Su di te',
  hair_color_header_info: 'Qual è il colore dei tuoi capelli?',
  into_community_btn: 'Comincia a flirtare',
  job_cont_button: 'Avanti',
  job_header: 'Su di te',
  job_header_info: 'Che lavoro fai?',
  languages_cont_button: 'Avanti',
  languages_header: 'Su di te',
  languages_header_info: 'Quali lingue parli?',
  left_part_header: 'Vivi il tuo personale<br> stile di vita MySugardaddy',
  lifestyle_header: 'Il tuo stile di vita',
  lifestyle_nav_activity: 'Tempo libero',
  lifestyle_nav_annual_income: 'Reddito annuale',
  lifestyle_nav_job: 'Mestiere',
  lifestyle_nav_lang: 'Lingue',
  lifestyle_nav_mbudget: 'Valore mensile desiderato',
  lifestyle_nav_pproperty: 'Patrimonio privato',
  lifestyle_nav_sport: 'Sport',
  lifestyle1_cont_button: 'Avanti',
  lifestyle1_header: 'Su di te',
  lifestyle1_header_info: 'Cosa fai nel tempo libero?',
  login_check_stay_login: "Mantieni l'accesso",
  login_err_invalid_mail_or_pw: 'Controllare il nome utente e la password',
  login_err_mail_unknow: 'Questa e-mail è sconosciuta',
  login_err_no_mail: 'È necessario inserire un indirizzo e-mail',
  login_err_no_pw: 'È necessario inserire la password',
  login_forgot_pw: 'Password dimenticata?',
  login_header: 'Bentornato',
  login_input_mail_ph: 'Nome utente/e-mail',
  login_input_pw_ph: 'Password',
  login_login_btn: 'Accedi',
  login_login_fb: 'Accedi con Facebook',
  login_logout_header: '... Sei ora disconnesso.',
  login_no_acc: 'Non hai ancora nessun account?',
  login_project_header: 'A quale comunità vorreste unirvi?',
  login_project_header_info:
    'Comunicateci la vostra scelta in modo da potervi indicare la comunità giusta.',
  login_project_id_0_desc: 'Incontri con lo zucchero nella sua forma classica',
  login_project_id_0_title: 'MSD classic',
  login_project_id_1_desc: 'Incontri con lo zucchero per la comunità LGBTQ+',
  login_project_id_1_title: 'MSD gay',
  login_project_id_2_desc: 'Incontri con donne forti e indipendenti',
  login_project_id_2_title: 'MSD mamas',
  login_project_validation_text:
    'Selezionare il luogo in cui si desidera effettuare il login',
  login_reg: 'Registrati ora',
  login_use_email: 'Accedi con e-mail',
  login_want_reg: 'Desideri registrarti?',
  maintenance_message:
    "Il sito web è attualmente in manutenzione. Ci scusiamo per l'inconveniente.",
  nav_back_button: 'Indietro',
  nav_next_button: 'Avanti',
  partner_role_sugarbabe: 'sugarbabe',
  partner_role_sugarboy: 'Sugarboy',
  partner_role_sugardaddy: 'Sugardaddy',
  partner_role_sugarmom: 'Sugarmom',
  pimage_cont_button: 'Avanti',
  pimage_edit_image_btn: 'Modifica',
  pimage_from: 'da',
  pimage_header: 'Nessuna foto profilo',
  pimage_header_desc: 'Mostra il lato migliore di te!',
  pimage_header_info: 'Arricchisci il tuo profilo e mostra il meglio di te!',
  pimage_modal_close_btn: 'Chiudi',
  pimage_modal_header: 'Carica foto',
  pimage_modal_save_btn: 'Salva',
  pimage_prev_cont_button: 'Avanti',
  pimage_prev_header: 'Su di te',
  pimage_prev_header_desc: 'Fantastico!',
  pimage_prev_header_info: 'La vita di alta classe ti sta aspettando!',
  pimage_prev_sms_cont_button: 'Comincia subito a flirtare',
  pimage_prev_sms_edit_button: 'O completa il tuo profilo',
  pimage_prev_sms_header: 'Sicurezza',
  pimage_prev_sms_header_desc:
    'Bene! Il tuo profilo non è ancora stato verificato',
  pimage_up_image_btn: 'Inserisci adesso una foto',
  pproperty_cont_button: 'Avanti',
  pproperty_header: 'Su di te',
  pproperty_header_info: 'Quanto guadagni?',
  pw_reset_btn: 'Avanti',
  pw_reset_desc:
    "Inserisci l'indirizzo email utilizzato durante la registrazione. Riceverai immediatamente una mail con le istruzioni per i passi successivi.",
  pw_reset_err_no_mail: 'Ti preghiamo di inserire la tua email',
  pw_reset_header: 'Sicurezza',
  pw_reset_input_mail_ph: 'E-mail',
  pw_reset_success_mail:
    "Ti abbiamo inviato una email con i prossimi passi da fare. Ti preghiamo di controllare nella cartella spam se ancora non l'avessi ricevuta.",
  pw_rt_btn: 'Conferma password',
  pw_rt_desc:
    'Ti preghiamo di inserire una nuova password. In caso di problemi, ti preghiamo di contattarci a support@mysugardaddy.eu',
  pw_rt_header: 'Sicurezza',
  pw_rt_input_password_conf_ph: 'Ripeti password',
  pw_rt_input_password_equal_err: 'La password deve corrispondere',
  pw_rt_input_password_len_err:
    'La password deve avere una lunghezza compresa tra 6 e 20 caratteri.',
  pw_rt_input_password_no_pw_err: 'Inserisci password',
  pw_rt_input_password_ph: 'Password',
  questions_cont_button: 'Avanti',
  questions_header: 'Su di te',
  questions_header_info: 'Dicci di più su di te',
  reg_already_member: 'Sei già registrato?',
  reg_err_select_gender: 'Seleziona il tuo genere sessuale',
  reg_finished_1_success_msg: 'Ben fatto!',
  reg_finished_2_success_msg: 'Hai completato il tuo profilo',
  reg_finished_cont_button: 'Comincia subito a flirtare!',
  reg_finished_header: 'Profilo',
  reg_header: 'Registrati adesso gratuitamente',
  reg_header_info: 'Io sono un/a',
  reg_header_info_hide: 'Io sono ...',
  reg_login: 'Login',
  reg_or: 'o',
  reg_prob_modal_body:
    'Purtroppo abbiamo riscontrato un problema con il tuo account. Ti preghiamo di segnalarlo al nostro team di supporto per risolverlo',
  reg_prob_modal_close: 'Chiudi',
  reg_prob_modal_header: 'Problema con il tuo account',
  reg_sugarbabe: 'Sugar Baby',
  reg_sugarbabe_hide: 'Donna',
  reg_sugardaddy: 'Sugar Daddy',
  reg_sugardaddy_hide: 'Uomo',
  reg_with_apple: 'Registrati con Apple',
  reg_with_email: 'Registrati con E-mail',
  reg_with_facebook: 'Registrati con Facebook',
  reg_with_google: 'Registrati con Google',
  regd_18_error: 'Sei minorenne',
  regd_accept_agb:
    "Registrandoti accetti la <a href='https://www.mysugardaddy.com/privacy-policy' target='_blank'>Politica sulla Privacy</a> e i <a href='https://www.mysugardaddy.com/terms-and-conditions' target='_blank'>Termini e Condizioni d'uso</a>  di MySugardaddy e dichiari di aver già compiuto 18 anni.",
  regd_city_ph: 'Città',
  regd_email_ph: 'E-mail',
  regd_err_accept_agb: "È necessario accettare i Termini e Condizioni d'uso",
  regd_err_add_loc: 'È necessario inserire un luogo',
  regd_err_add_pw:
    'La password deve avere una lunghezza compresa tra 6 e 20 caratteri.',
  regd_err_add_username: 'È necessario inserire un nome utente',
  regd_err_mail_locked: 'Questa e-mail è già in uso',
  regd_err_no_lat_lng:
    "Posizione non trovata. Ti preghiamo di selezionarne un'altra",
  regd_err_no_mail: 'È necessario inserire un indirizzo e-mail',
  regd_err_recaptcha: 'Il captcha deve essere risolto',
  regd_eventcode_ph: 'Codice promozionale',
  regd_header_info: 'Registrati con E-mail',
  regd_label_birthday: 'Data di nascita',
  regd_label_eventcode: 'Avete un codice promozionale?',
  regd_modal_btn_babe: 'Io sono una: Sugar Baby',
  regd_modal_btn_daddy: 'Io sono un: Sugar Daddy',
  regd_modal_conf_text:
    'Se è tutto corretto, clicca su "registrati ora gratuitamente" <br> Qua puoi modificare i tuoi dati, se non sono corretti',
  regd_modal_content:
    '<p>Tu sei un/a <b>{{iam}}</b> ?</p><p> e cerchi un/a <b>{{isearch}}</b> ?</p>',
  regd_modal_header:
    'Ti preghiamo di controllare nuovamente le informazioni inserite',
  regd_modal_wrong_search: 'Le informazioni non sono corrette?',
  regd_months_1: 'Gennaio',
  regd_months_10: 'Ottobre',
  regd_months_11: 'Novembre',
  regd_months_12: 'Dicembre',
  regd_months_2: 'Febbraio',
  regd_months_3: 'Marzo',
  regd_months_4: 'Aprile',
  regd_months_5: 'Maggio',
  regd_months_6: 'Giugno',
  regd_months_7: 'Luglio',
  regd_months_8: 'Agosto',
  regd_months_9: 'Settembre',
  regd_password_ph: 'Password',
  regd_reg_button: 'Registrati ora gratuitamente',
  regd_select_gender: 'Seleziona il tuo sesso ...',
  regd_select_gender_err: 'Seleziona il tuo sesso',
  regd_select_gender_f: 'Femmina',
  regd_select_gender_m: 'Maschio',
  regd_username_ph: 'Nome utente',
  regd_want_login: 'Vuoi accedere?',
  register_partner_role_list_default_text: 'Selezionare',
  register_partner_role_list_label: 'Cerco ...',
  register_partner_role_validation_text:
    'Si prega di specificare chi si sta cercando',
  smoker_cont_button: 'Avanti',
  smoker_header: 'Su di te',
  smoker_header_info: 'Fumi?',
  sports_cont_button: 'Avanti',
  sports_header: 'Su di te',
  sports_header_info: 'Quali sport pratichi?',
  summary_edit_mail_btn: 'Modifica e-mail',
  summary_edit_profile_btn: 'O completa il tuo profilo',
  summary_go_to_community_btn: 'Comincia subito a flirtare',
  summary_header: 'Sicurezza',
  summary_header_desc: 'Benvenuto/a su MySugardaddy',
  summary_mail_info:
    '<p>Ti abbiamo appena inviato al tuo indirizzo e-mail </p><p><b>{{mail}}</b></p><p>un link di conferma</p>',
  summary_mail_repeat:
    '<p>Non hai ancora ricevuto nessuna e-mail? Ti preghiamo di guardare</p><p>anche nella cartella Spam. Se hai ancora</p><p>problemi nel registrarti,</p><p>scrivi alla nostra assistenza clienti</p><p><span>support@mysugardaddy.eu</span></p>',
  summary_modal_body: 'Qui puoi modificare la tua e-mail',
  summary_modal_close: 'Chiudi',
  summary_modal_header: 'Modifica e-mail',
  summary_modal_save_send_btn: 'Salva e invia',
  summary_new_veri_mail: 'Nuova e-mail di verifica inviata',
  summary_not_received_btn: 'Non hai ancora ricevuto nessuna email?',
  summary_resend_mail_btn: 'Invia una nuova e-mail',
  summary_sms_btn: 'Verisica ora tramite SMS',
  summary_sms_info: 'Puoi anche verificare il tuo profilo tramite cellulare',
  summary_sms_info_success: 'Mostra di essere affidabile',
  summary_success_header: "Ce l'hai fatta!",
  summary_success_msg: 'Fantastico!',
  summary_veri_mail_success: 'La tua e-mail è stata verificata con successo',
  summary_wrong_mail_info: 'Haiinserito un indirizzo e-mail sbagliato?',
  username_cont_button: 'Avanti',
  username_header: 'Su di te',
  username_header_info: 'Come vuoi chiamarti?',
  username_suggestions: 'Ecco alcuni suggerimenti',
  verif_prof_cont_button: 'Avanti',
  verif_prof_explain_info: "La vita d'alta classe ti sta aspettando",
  verif_prof_header: 'Sicurezza',
  verif_prof_header_desc: 'Verifica il tuo profilo',
  verif_prof_header_info: 'Verifica tramite SMS e mostra di essere affidabile',
  verif_prof_verf_email: 'Verifica tramite email',
  verif_prof_verf_sms: 'Verifica tramite SMS ora',
  verify_phone_c_code_sent_info:
    'Abbiamo inviato il codice al tuo numero di telefono <b>{{phonenumber}}</b>. Questo processo può durare fino a un minuto.',
  verify_phone_c_cont_button: 'Conferma codice',
  verify_phone_c_error_empty: 'Inserisci il codice di 6 cifre',
  verify_phone_c_error_resends_limit:
    'Qualcosa è andato storto. Ti preghiamo di contattarci a support@mysugardaddy.eu',
  verify_phone_c_error_wrong: 'Il codice inserito non è corretto',
  verify_phone_c_header: 'Sicurezza',
  verify_phone_c_header_info: 'Verifica il tuo profilo',
  verify_phone_c_intro: 'Ti preghiamo di inserire il codice di conferma',
  verify_phone_c_resend_success: 'Ti è stato inviato un nuovo codice',
  verify_phone_c_retry_btn: 'Reinvia codice',
  verify_phone_codeform_error_empty: 'Inserisci il tuo numero di telefono',
  verify_phone_codeform_error_invalid:
    'Per favore, inserisci un numero di telefono valido',
  verify_phone_codeform_nocorrectphonenumbers_err:
    'Il numero di telefono non è corretto',
  verify_phone_cont_button: 'Verifica il tuo profilo',
  verify_phone_header: 'Sicurezza',
  verify_phone_header_info: 'Verifica il tuo profilo',
  verify_phone_input_txt:
    "Riceverai solamente l'SMS di verifica e nessuno spam",
  verify_phone_intro: 'Mostra di essere un utente verificato!',
};
