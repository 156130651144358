import { MSD_PROJECT } from '../config';
import { GenderEnum, MsdProjectTypes, PartnerRoles } from '../interfaces';
import { genderAndRoleToProjectId } from '../config/msdProject';

type GetMsdProjectID = (
  userGender: string | null,
  partnerRole: string | null,
) => number | null;

export const getCorrectGender = (gender: string | null) => {
  switch (gender) {
    case 'male':
      return GenderEnum.male;
    case 'female':
      return GenderEnum.female;
    default:
      return null;
  }
};

export const getCorrectPartnerRole = (partnerRole: string | null) => {
  const role = partnerRole?.toLowerCase();

  switch (role) {
    case PartnerRoles.sugarBabe:
      return PartnerRoles.sugarBabe;
    case PartnerRoles.sugarMom:
      return PartnerRoles.sugarMom;
    case PartnerRoles.sugarDaddy:
      return PartnerRoles.sugarDaddy;
    case PartnerRoles.sugarBoy:
      return PartnerRoles.sugarBoy;
    default:
      return null;
  }
};

export const getById = (projectId: number) => {
  return Object.values(MSD_PROJECT).find(
    (item: MsdProjectTypes) => item.id === projectId,
  ) as MsdProjectTypes;
};

export const getMsdProjectId: GetMsdProjectID = (userGender, partnerRole) => {
  const gender = getCorrectGender(userGender);
  const role = getCorrectPartnerRole(partnerRole);

  if (!gender || !role) {
    return null;
  }

  const key = `${gender}_${role}`;

  return genderAndRoleToProjectId[key];
};
