import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { map } from 'rxjs';

import { AuthService } from '../services';

export const rememberedLoginGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const isRememberedLogin = authService.isRememberedLogin();

  if (isRememberedLogin) {
    return authService.checkLoggedInUser().pipe(
      map(({ data }) => {
        if (Array.isArray(data)) {
          authService.resetLoginAuth();

          return true;
        }

        authService.redirectToCommunity(data.link);

        return false;
      }),
    );
  }

  return true;
};
