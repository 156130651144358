import { Langs } from '../../../interfaces';
import de from './de';
import en from './en';
import es from './es';
import fr from './fr';
import it from './it';
import pt from './pt';

export default {
  [Langs.en]: en,
  [Langs.de]: de,
  [Langs.es]: es,
  [Langs.fr]: fr,
  [Langs.it]: it,
  [Langs.pt]: pt,
};
